import React from "react";
import { useTranslation } from "react-i18next";

import s from "./ReplyAuthor.module.less";
import { PostTypes } from "@store/replies/types";
// eslint-disable-next-line max-len
import { IPrivateAttachment } from "@components/_common/CommentCard/cardBody/attachment/Attachment.type";

interface IReplyAuthorProps {
  author: string;
  postType?: PostTypes;
  isOwned?: boolean;
  username: string;
  hasAttachments: boolean;
  attachments: IPrivateAttachment[];
}

const PostShare = ({ isOwned, username }: Pick<IReplyAuthorProps, "isOwned" | "username">) => {
  const { t } = useTranslation();

  return (
    <>
      {isOwned ? (
        <span>
          {t("components:reply:postShare_owned")} {username}
        </span>
      ) : (
        <span>{t("components:reply:postShare_not_owned")}</span>
      )}
    </>
  );
};

const StoryMention = ({ isOwned }: Pick<IReplyAuthorProps, "isOwned">) => {
  const { t } = useTranslation();

  return (
    <>
      <span>{t(`components:reply:mention_${isOwned ? "owned" : "not_owned"}`)}</span>
      <span className={s.bbPrivateMessageReplyStory}>{t("generic:story")}</span>
    </>
  );
};

const StoryReply = ({ isOwned }: Pick<IReplyAuthorProps, "isOwned">) => {
  const { t } = useTranslation();

  return (
    <span className="font-bold">
      {t(`components:reply:storyReply_${isOwned ? "owned" : "not_owned"}`)}
    </span>
  );
};

export const ReplyAuthor = ({
  author,
  postType,
  isOwned,
  username,
  hasAttachments,
  attachments,
}: IReplyAuthorProps) => (
  <>
    <span className="font-bold">{author}</span>
    {postType === "post_share" ? (
      <PostShare isOwned={isOwned} username={username} />
    ) : (
      <>
        {hasAttachments && attachments[0].description === "story_mention" && (
          <StoryMention isOwned={isOwned} />
        )}
        {hasAttachments && attachments[0].description === "story_reply" && (
          <StoryReply isOwned={isOwned} />
        )}
      </>
    )}
  </>
);

import { useSelector } from "react-redux";
import React, { FC, useState, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import { DemoMode } from "@containers/DemoMode/DemoMode";
import Overview from "@components/insights/overview/Overview";
import AppLayout from "@components/_common/AppLayout/AppLayout";
import NotFoundView from "@containers/NotFoundView/NotFoundView";
import MissingAssetsDisclaimer from "@components/_common/Disclaimers/MissingAssetsDisclaimer";
import ReconnectAssetsDisclaimer from "@components/_common/Disclaimers/ReconnectAssetsDisclaimer";
import CommentLimitDisclaimer from "@components/_common/Disclaimers/CommentLimitDisclaimer";
import PaymentFailedDisclaimer from "@components/_common/Disclaimers/PaymentFailedDisclaimer";
import PendingAssetsDisclaimer from "@components/_common/Disclaimers/PendingAssetsDisclaimer";

import { insightsAppFeature, meSelector } from "@store/me/selectors";

import s from "./Routes.module.less";

import { IRoutesProps } from "./Routes.type";
import { isGracePeriod } from "@utils/grace";
import { useRoutes } from "@utils/useRoutes";
import { InsightsSubFeatureComponents } from "./helpers";
import { IMenuItem } from "@components/_common/AppHeader/AppHeader.type";

const InsightsRoutes: FC<IRoutesProps> = ({ menu, path, hasMenu, pendingAssets }) => {
  const { redirectCallback, extraMenuItems } = useRoutes(path);

  const [showPendingAssetsAlert, setShowPendingAssetsAlert] = useState(true);
  const [showPaymentDisclaimer, setShowPaymentDisclaimer] = useState(true);

  const me = useSelector(meSelector);
  const feature = useSelector(insightsAppFeature);
  const subfeatures = useMemo(() => feature?.subfeatures || [], [feature]);

  return (
    <AppLayout
      basename="insights"
      avatarUrl={me?.client?.logo}
      defaultSelectedKey="overview"
      menu={menu as unknown as IMenuItem[]}
      extra={extraMenuItems}
      selectedKey={(path && path.replace(/\//g, "")) || "overview"}
    >
      {{
        beforeContent: (
          <>
            {path !== "/unauthorized" ? (
              <div>
                {!hasMenu ? <MissingAssetsDisclaimer /> : null}
                {me && isGracePeriod(me) && showPaymentDisclaimer ? (
                  <PaymentFailedDisclaimer
                    onCloseDisclaimer={() => setShowPaymentDisclaimer(false)}
                  />
                ) : null}
                {me?.asset_alert?.message ? <ReconnectAssetsDisclaimer /> : null}
                {pendingAssets.length && showPendingAssetsAlert ? (
                  <PendingAssetsDisclaimer
                    onCloseDisclaimer={() => setShowPendingAssetsAlert(false)}
                  />
                ) : null}
                <CommentLimitDisclaimer />
                <DemoMode />
              </div>
            ) : null}
          </>
        ),
        content: (
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  replace
                  to={{
                    pathname: subfeatures.length ? subfeatures[0].id : "overview",
                    search: window.location.search,
                  }}
                />
              }
            />
            {subfeatures.length ? (
              subfeatures?.map(({ id, is_disabled }) => {
                const RouteComponent =
                  InsightsSubFeatureComponents[id as keyof typeof InsightsSubFeatureComponents] ||
                  null;

                if (RouteComponent) {
                  return (
                    <Route
                      key={id}
                      path={id}
                      element={
                        <PrivateRoute isDisabled={is_disabled}>
                          <div className={s.bbInsightsFeatures}>
                            <RouteComponent />
                          </div>
                        </PrivateRoute>
                      }
                    />
                  );
                } else return null;
              })
            ) : (
              <Route
                key="overview"
                path="overview"
                element={
                  <PrivateRoute isDisabled={false}>
                    <Overview />
                  </PrivateRoute>
                }
              />
            )}
            <Route
              path="unauthorized"
              element={<NotFoundView redirectCallback={redirectCallback} type="403" />}
            />
            <Route path="*" element={<NotFoundView redirectCallback={redirectCallback} />} />
          </Routes>
        ),
      }}
    </AppLayout>
  );
};

export default InsightsRoutes;

import React, {
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from "react";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { Form } from "antd";
import { DynamicFilterComponent } from "./DynamicFilterComponent";

import { meSelector } from "@store/me/selectors";
import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import { getPlatforms, getFetchValuesFieldName, normalizeDateRangeForAPI } from "@utils/filters";
import {
  AllowedAutocompleteFieldType,
  IAutocompleteOption,
  IGetAutocompleteOptionsPayload,
} from "@store/autocomplete/types";
import { getDefaultFiltersConfig } from "./config";
import { IFormFilterRequest } from "./AppFilters.type";
import { addToast, cleanObject } from "@bbdevcrew/bb_ui_kit_fe";
import { IFiltersProps, IFormHandler, IDropdownOptions, IDynamicFilter } from "./Filters.type";

import s from "./Filters.module.less";

/**
 * `Filters` is a Forward Render Function, It can use Ref and be able to call
 * methods which are runned with the hook `useImperativeHandle`
 */
const Filters: ForwardRefRenderFunction<IFormHandler, IFiltersProps> = (
  {
    children,
    onFilter,
    customFilters,
    filterPlacement,
    initialFilterData,
    clientPlatformTypes,
    onChangeCallback,
    className,
  },
  ref,
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm<IFormFilterRequest>();

  const [fieldName, setFieldName] = useState<string>();
  const [isMultiPaste, setIsMultiPaste] = useState(false);
  const [filters, setFilters] = useState<IDynamicFilter<any>[]>(); // eslint-disable-line
  const [dropdownOptions, setDropdownOptions] = useState<IDropdownOptions>({});

  const autocompleteOptions = useSelector(autocompleteOptionsSelector);
  const me = useSelector(meSelector);

  const getAutocompleteOptions = useCallback(
    (payload: IGetAutocompleteOptionsPayload) => dispatch(getAutocompleteOptionsAction(payload)),
    [dispatch],
  );

  const autoSelectPostIds = (items: IAutocompleteOption[]) => {
    if (items?.length) {
      form.setFieldsValue({
        post_ids: items.map(({ id }) => id || ""),
      });
    } else {
      addToast({
        type: "warning",
        title: t("generic:warning"),
        message: t("components:filters:multiPasteWarningText"),
      });
    }
  };

  useEffect(() => {
    setFilters(customFilters || getDefaultFiltersConfig(t, me));
  }, [customFilters, t, me]);

  useEffect(() => {
    if (fieldName) {
      const options = autocompleteOptions[fieldName as keyof typeof autocompleteOptions];

      setDropdownOptions({
        ...dropdownOptions,
        [fieldName]: options.map(item => ({ ...item, value: item.id })),
      });

      if (fieldName === "post_ids" && isMultiPaste) autoSelectPostIds(options);
    }
    // eslint-disable-next-line
  }, [autocompleteOptions.post]);

  const fetchValues = (
    _fieldName: string,
    _sanitizedFieldName: string,
    _term: string,
    _isMultiPaste?: boolean,
  ) => {
    setFieldName(_fieldName);
    setIsMultiPaste(!!_isMultiPaste);
    getAutocompleteOptions({
      field: _sanitizedFieldName as AllowedAutocompleteFieldType,
      query: _term,
    });
  };

  const initializeFormValues = (_initialFilterData: IFormFilterRequest) => {
    const { start_time, end_time, data_range_option } = _initialFilterData;

    if (start_time && end_time && (data_range_option === "custom" || !data_range_option)) {
      const dates = [moment(start_time), moment(end_time)] as [Moment, Moment];

      form?.setFieldsValue({
        dates,
        data_range_option: "custom",
      });
    } else if (!start_time && !end_time && data_range_option === "custom") {
      form?.resetFields(["dates"]);
      form?.setFieldsValue({ data_range_option: "custom" });
    } else {
      form?.setFieldsValue({
        data_range_option: data_range_option || "",
        dates: undefined,
      });
    }

    form.setFieldsValue(_initialFilterData);
  };

  const onFinish = () => {
    // Normalize date for API
    const normalizedData = cleanObject(normalizeDateRangeForAPI(form));

    onFilter(normalizedData, "id");
  };

  // Monitor value changes
  const onValuesChange = (changedValues: IFormFilterRequest) => {
    if ("platform_types" in changedValues) {
      // Generate post_types/platform_types because post_types depend on platform selection
      const platforms = getPlatforms(clientPlatformTypes);

      setDropdownOptions({
        ...dropdownOptions,
        platform_types: platforms,
      });
    }
    if (onChangeCallback) {
      const normalizedData = cleanObject(normalizeDateRangeForAPI(form));

      onChangeCallback(normalizedData);
    }
  };

  // Event handlers
  const onMultiPaste = (
    _fieldName: string,
    mergedValues: string[],
    isNoResult: boolean,
    originalSearchString: string,
  ) => {
    // Prevent non-existent values for all filters except `post_ids`
    if (_fieldName !== "post_ids") {
      if (isNoResult) {
        addToast({
          type: "warning",
          title: t("generic:warning"),
          message: t("components:filters:multiPasteWarningText"),
        });
      }

      form.setFieldsValue({
        [_fieldName]: mergedValues,
      });
    } else {
      const sanitizedFieldName = getFetchValuesFieldName(_fieldName);
      fetchValues(_fieldName, sanitizedFieldName, originalSearchString, true);
    }
  };

  const onSearch = (value: string, _fieldName: string) => {
    const sanitizedFieldName = getFetchValuesFieldName(_fieldName);
    fetchValues(_fieldName, sanitizedFieldName, value);
  };

  const onFocus = (_fieldName: string) => {
    const sanitizedFieldName = getFetchValuesFieldName(_fieldName);

    const searchTerm = form.getFieldValue(_fieldName)
      ? form.getFieldValue(_fieldName).join(",")
      : "";
    fetchValues(_fieldName, sanitizedFieldName, searchTerm);
  };

  /**
   * Methods can be exported here
   */
  useImperativeHandle(
    ref,
    () => ({
      submit() {
        return form.submit();
      },
      resetFields() {
        return form.resetFields();
      },
      initializeFormValues(data: IFormFilterRequest) {
        return initializeFormValues(data);
      },
      getFormValues() {
        return form.getFieldsValue();
      },
    }),
    // eslint-disable-next-line
    [form],
  );

  useEffect(() => {
    const platforms = getPlatforms(clientPlatformTypes);

    setDropdownOptions({
      ...dropdownOptions,
      platform_types: platforms,
    });
    // eslint-disable-next-line
  }, []);

  // Set Form fields
  useEffect(() => {
    if (initialFilterData) {
      form.resetFields();
      initializeFormValues(initialFilterData);
    }
    // eslint-disable-next-line
  }, [initialFilterData]);

  return (
    <Form
      form={form}
      size="small"
      colon={false}
      onFinish={onFinish}
      id="bbFilterContainer"
      className={classNames(s.bbFiltersForm, className)}
      data-cy="app-filters-form"
      onValuesChange={onValuesChange}
      data-stonly-target="app-global__filters"
      layout="vertical"
    >
      <div id="bbFilterFieldsContent" data-cy="filter-fields-container">
        <div className={s.bbFiltersRow}>
          {children?.prefix}

          {filters
            ?.filter(item => !item.isHidden)
            ?.map(filter => {
              if (filter.name === "assetsInfix") {
                return children?.assetsInfix || null;
              }

              return (
                <div key={`filter-col-${filter.name}`} className={s.bbFilterItemWrapper}>
                  {!!filter.groupLabel && (
                    <p className={s.bbFilterGroupLabel}>{filter.groupLabel}</p>
                  )}
                  <DynamicFilterComponent
                    filter={filter}
                    onFocus={onFocus}
                    onSearch={onSearch}
                    onMultiPaste={onMultiPaste}
                    dropdownOptions={dropdownOptions}
                    dropdownPlacement={filterPlacement}
                    maxTagCount={filter.props.maxTagCount}
                  />
                </div>
              );
            })}
        </div>
      </div>
      {children?.suffix}
    </Form>
  );
};

export default forwardRef(Filters);

import * as actions from "./actions";
import { ActionType } from "typesafe-actions";

import {
  IPost,
  PostMentionType,
  PostPlacementEnum,
  RecommendationType,
} from "@components/_common/Social/Post/Post.type";
import { IFilters } from "../filters/types";
import { IDemoStoryData } from "../me/types";
import { IInboxStats } from "../inboxStats/types";
import { IPlatform, IPlatformType } from "../platform/types";
import { ICustomTag } from "@components/_common/TagsDropdown/TagsDropdown.type";
import { LinkedInReactionType } from "@components/_common/LinkedInReactionButton/types";
import { IAttachment } from "@components/_common/CommentCard/cardBody/attachment/Attachment.type";
import { IDirectMessage } from "@components/_common/CommentCard/cardBody/ThreadBody/ThreadBody.type"; // eslint-disable-line

export type CommentActionTypes = ActionType<typeof actions>;

export type IGenericAction = {
  type: string;
  payload: any; // eslint-disable-line
};

export interface IImage {
  height: number;
  src: string;
  width: number;
}

export interface IMedia {
  image: IImage;
}

export type AiTagType = "ai_response_needed" | "ai_potentially_harmful";

export interface IAiTag extends ITag {
  id: AiTagType;
  tooltip?: string;
  count?: number;
}

export interface ITag {
  id: string;
  label: string;
}

export interface ICommentTag extends ITag {
  category?: string;
}

export interface IMessageTag extends ITag {
  count: number;
}

export interface IMessageTags {
  managed_tags: IMessageTag[];
  custom_tags: IMessageTag[];
  ai_tags: IMessageTag[];
  count: number;
}

export interface IComments {
  comments: IComment[];
  cursor?: string;
  navigation_id?: string;
  info?: string;
  fetchingComments: boolean;
  fetchedComments: boolean;
  fetchedCommentsFail: boolean;
}

export const DEFAULT_COMMENT_LIST_KEY = "legacy_all_comments";

export type CommentListType = keyof ICommentLists;

export interface ICommentLists {
  legacy_all_comments: IComments;
  "listen-conversation_list": IComments;
  "managed-hidden": IComments;
}

export const commentsListsArray: (keyof ICommentLists)[] = [
  "legacy_all_comments",
  "listen-conversation_list",
];

export interface ICommentsState extends ICommentLists {
  comment?: ICommentWithPostProps;
  replyType?: keyof IInboxStats;
  actionsProcessing: AllowedCommentActionsType[];

  triggerNextBatch?: string;
  performingBatchActions: boolean;
  performedBatchActions: boolean;
  performedBatchActionsFailed: boolean;

  fetchingComment: boolean;
  fetchedComment: boolean;
  fetchedCommentFail: boolean;

  fetchingPostUnhideComments: boolean;
  fetchedPostUnhideComments: boolean;
  fetchedPostUnhideCommentsFail: boolean;

  commentsCSV?: Blob;
  fetchingCommentsCSV: boolean;
  fetchedCommentsCSV: boolean;
  fetchedCommentsCSVFail: boolean;

  previouslyAssignedUsers: string[];

  previousReactions: { [key: string]: ICommentReaction | undefined };
}

export interface IPostCommentBody {
  filters?: IFilters;
  cursor?: string;
  navigation_id?: string;
  sort?: string;
  sort_field?: string;
  reply_platform_type?: ReplyPlatformType;
  replacePrevPage?: boolean;
  limit?: number;
  isReplyModal?: boolean;
}

export interface IPostCommentsSearchBody extends IPostCommentBody {
  conversation_list_id?: keyof ICommentLists;
}

export type AssignedUserFilterType = "assigned" | "unassigned" | "assigned_to_me";

export interface IGetInboxRepliesPayload extends IPostCommentBody {
  list_type?: keyof IInboxStats;
  sort_field?: string;
  assigned_options?: AssignedUserFilterType;
}

export type AllowedCommentActionsType =
  | "ignore"
  | "flag"
  | "like"
  | "hide"
  | "delete"
  | "star"
  | "review"
  | "dismiss_errors";

export interface ICommentActionPayload {
  id: string;
  value: boolean;
  filters?: IFilters;
  action: AllowedCommentActionsType;
}

export interface ICommentsResponse {
  items: IComment[];
  cursor?: string;
  info?: string;
  navigation_id?: string;
}

export type ReplyPlatformType = "all" | "brandbastion" | "platform";

export interface ICommentsCSVPayload {
  filters?: IFilters;
  sort?: "asc" | "desc";
  sort_field?: string;
  cursor?: string;
  limit?: number;
}

export interface IBulkCommentActionPayload {
  actions?: AllowedCommentActionsType[];
  comment_ids: string[];
  custom_tags_to_add?: string[];
}

export type PrivateReplyCapability = "not_applicable" | "disabled" | "enabled";

export type SentimentChangeCapability = "not_applicable" | "disabled" | "enabled";

export interface ICommentWithPostProps extends IComment {
  post?: IPost;
}

export interface ICommentReaction {
  type: LinkedInReactionType;
  is_sent: boolean;
}

export interface IProcessingFlags {
  actionsProcessing?: AllowedCommentActionsType[];
}

interface ICommentActions {
  can_reply: boolean;
  reply_tooltip?: string;
  can_hide_unhide: boolean;
  hide_unhide_tooltip?: string;
  like_unlike_capability: string;
  private_reply_capability: {
    capability: PrivateReplyCapability;
    tooltip?: string;
    thread_id?: string;
  };
  can_react?: boolean;
  sentiment_change_capability?: {
    capability: SentimentChangeCapability;
    tooltip?: string;
  };
}

export interface ICommentTooltips {
  username: string;
  premium?: string;
  hidden?: string;
  reply_star?: string;
  reply_ignore?: string;
  moderation_flag?: string;
  moderation_review?: string;
  edit?: string;
}

export interface PlatformUser {
  avatar: string;
  created_at: string;
  yt_user_id: string;
  tw_ad_user_id: string;
  client_short_name: string;
  email: string;
  is_demo_mode_active: boolean;
  asset_added: boolean;
  tw_user_id: string;
  checklist: string;
  roles: string[];
  last_name: string;
  yt_ad_user_id: string;
  demo_story: IDemoStoryData;
  first_name: string;
  is_disabled: boolean;
  timezone: string;
  id: string;
  initial_login_time: string;
  title: string;
}

export interface IReplyPhrase {
  phrase: string;
  timestamp?: string;
  platform_user?: PlatformUser;
}

export type CommentActionErrorType =
  | "hiding"
  | "unhiding"
  | "issuing a response to"
  | "hiding and issuing a response to"
  | "deleting the response to";

export interface ICommentActionError {
  types: CommentActionErrorType[];
  user_message?: string;
  details?: string[];
  platform_message?: string;
}

export interface ICommentReply {
  is_starred: boolean;
  is_ignored: boolean;
  is_sent: boolean;
  message: string;
  raw_message?: string;
  sent_at: string;
  sent_by: string;
  sent_id: string;
  replied_as?: {
    logo: string;
    name: string;
  };
}

export interface IModerationProps {
  is_flagged: boolean;
  is_reviewed: boolean;
}

export interface IDirectMessageStats {
  grand_total_count: number;
  private_message_count: number;
  private_message_owned_count: number;
  private_message_total_count: number;
  story_mention_count: number;
  story_mention_owned_count: number;
  story_mention_total_count: number;
  story_reply_count: number;
  story_reply_owned_count: number;
  story_reply_total_count: number;
}

interface ITrustpilotData {
  experienced_at: string;
  stars: number;
}

export interface IComment extends IProcessingFlags {
  id: string;
  post_id: string;
  title?: string;
  message?: string;
  username: string;
  dm_messages?: IDirectMessage[];
  dm_stats?: IDirectMessageStats;
  actions: ICommentActions;
  attachments: IAttachment[];
  assigned_users: string[];
  created_time: number;
  created_time_pretty: string;
  is_liked: boolean;
  is_hidden: boolean;
  is_deleted: boolean;
  is_ai_potentially_harmful: boolean;
  is_ai_response_needed: boolean;
  parent?: IComment;
  platform_type: IPlatformType;
  message_translated?: string;
  is_translated?: boolean;
  object_id: string;
  platform: IPlatform;
  has_ads: boolean;
  author_id?: string;
  permalink: string;
  sentiment?: string;
  reply: ICommentReply;
  comment_phrase?: string;
  reply_phrase?: IReplyPhrase;
  tooltips: ICommentTooltips;
  moderation: IModerationProps;
  post_placement?: PostPlacementEnum;
  recommendation_type?: RecommendationType;
  thread_count?: number;
  action_error?: ICommentActionError;
  post_type?: PostMentionType;
  post?: IPost;
  replying_comment_id: string;
  reaction?: ICommentReaction;
  tp_data?: ITrustpilotData;
  // tags
  ai_tags?: IAiTag[];
  tags?: ICommentTag[];
  message_tags?: IMessageTags;
  custom_tags_by_user?: ICustomTag[];
  custom_tags_by_automation?: ICustomTag[];
}

import loadable from "@loadable/component";

const Posts = loadable(() => import("@components/insights/posts/Posts"));
const Search = loadable(() => import("@components/insights/search/Search"));
const Compare = loadable(() => import("@components/insights/compare/Compare"));
const Overview = loadable(() => import("@components/insights/overview/Overview"));
const Notifications = loadable(() => import("@components/insights/notifications/Notifications"));

const Inbox = loadable(() => import("@components/care/inbox/Inbox"));
const Workflows = loadable(() => import("@components/care/workflows/Workflows"));
const SmartInbox = loadable(() => import("@components/care/smartInbox/SmartInbox"));

const Alerts = loadable(() => import("@components/safety/alerts/Alerts"));
const Guidelines = loadable(() => import("@components/safety/guidelines/Guidelines"));
const ModerateCPS = loadable(() => import("@components/safety/moderateCPS/ModerateCPS"));
const Dashboard = loadable(() => import("@components/safety/dashboard/Dashboard"));

const Calendar = loadable(() => import("@components/publish/calendar/CalendarPage"));

const TiktokTrendingHashtags = loadable(() => import("@components/listen/trendingHashtags"));
const Listen = loadable(() => import("@components/listen/listening"));
const Sources = loadable(() => import("@components/listen/sources"));

const Transparency = loadable(() => import("@components/managed/Transparency"));

export const InsightsSubFeatureComponents = {
  posts: Posts,
  search: Search,
  compare: Compare,
  overview: Overview,
  notifications: Notifications,
  "trending-hashtags": TiktokTrendingHashtags,
};

export const CareSubFeatureComponents = {
  inbox: Inbox,
  workflows: Workflows,
  "smart-inbox": SmartInbox,
};

export const ModerationSubFeatureComponents = {
  dashboard: Dashboard,
  alerts: Alerts,
  "moderation-guidelines": Guidelines,
  "moderate-cps": ModerateCPS,
};

export const PublishSubFeatureComponents = {
  calendar: Calendar,
};

export const ListenSubFeatureComponents = {
  listening: Listen,
  sources: Sources,
};

export const ManagedSubFeatureComponents = {
  transparency: Transparency,
};

export const CleanPathAndNavigate = () => {
  // Remove trailing slashes, if they exist
  if (window.location.pathname.match("/.*/$")) {
    const newPathname = window.location.pathname.replace(/\/+$/, "");
    const newUrl =
      window.location.origin + newPathname + window.location.search + window.location.hash;
    window.location.assign(newUrl);
  } else {
    window.location.href = `${process.env.BASE_URL}/insights/overview`;
  }

  return null;
};

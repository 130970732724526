import mime from "mime";

import { mapIgTagsToFrontend } from ".";
import { isYT } from "./utilsValidations";

import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { IPublishingPost } from "@store/publishings/types";
import { PROPERTIES_TO_IGNORE_ON_SYNC } from "./constants";
import { IPostItem, IPreviewImageFile, IPreviewPDFFile, IPreviewVideoFile } from "./types";
import { IAutocompleteAsset } from "../../../_common/AppFilter/filters/Assets/Assets.type";
import { AnyTagType } from "../TagsModal/TagsModal.types";

export const getInitialPostScheduleTab = (post: IPublishingPost | null) => {
  return post?.publishing_error?.message
    ? "postNow"
    : post?.publishing_status === "draft"
      ? "saveAsDraft"
      : "schedule";
};

export const generateEmptyPostItem = (asset: IAutocompleteAsset) => {
  return {
    asset: asset,
    message: "",
    uploads: [],
    uploadedFiles: [],
    previewImageFiles: [],
    previewVideoFiles: [],
    previewPDFFiles: [],
    publishing_type: "POST",
  } as IPostItem;
};

export const fetchUpload = (url: string, file: File) => {
  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": file.type,
    },
    body: file,
    redirect: "follow",
  });
};

// Compare the old and new posts arrays to know if any property has been changed or not
// (any property except the propertiesToIgnore) - these don't affect changes
export const tabsHaveBeenChanged = (arr1: IPostItem[], arr2: IPostItem[]) => {
  const arr1WithoutProperties = arr1.map(obj => {
    const newObj = { ...obj };
    for (const property of PROPERTIES_TO_IGNORE_ON_SYNC) {
      delete newObj[property];
    }
    return newObj;
  });

  const arr2WithoutProperties = arr2.map(obj => {
    const newObj = { ...obj };
    for (const property of PROPERTIES_TO_IGNORE_ON_SYNC) {
      delete newObj[property];
    }
    return newObj;
  });

  const stringifiedArr1 = JSON.stringify(arr1WithoutProperties);
  const stringifiedArr2 = JSON.stringify(arr2WithoutProperties);

  return stringifiedArr1 !== stringifiedArr2;
};

export const getFileNameFromUrl = (url: string) => {
  if (!url) return "";

  return url.substring(url.lastIndexOf("/") + 1);
};

export const getRelativePath = (url: string) => {
  try {
    const urlObject = new URL(url);
    return urlObject.pathname.substring(1);
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
};

export const generateExistingPostItem = (
  existingPost: IPublishingPost,
  asset: IAutocompleteAsset,
): IPostItem => {
  const uploadedFiles: UploadFile[] = [];
  const previewPDFFiles: IPreviewPDFFile[] = [];
  const previewImageFiles: IPreviewImageFile[] = [];
  const previewVideoFiles: IPreviewVideoFile[] = [];
  const orderedFileIds: string[] = [];
  const mediaTags: { [fileId: string]: AnyTagType[] } = {};
  const altText: { [fileId: string]: string } = {};

  for (const filteredAttachment of existingPost.attachments.filter(
    attachment => !attachment.url.endsWith("/null"),
  )) {
    const { id, media, url, type, media_tags, alt_text } = filteredAttachment;
    const attachmentExt = url.split(/[#?]/)[0]?.split(".")?.pop()?.trim();

    if (!!attachmentExt) {
      const uploadedFile = {
        uid: id,
        url,
        type: mime.getType(attachmentExt),
        status: "done",
        name: getFileNameFromUrl(url),
      } as UploadFile;

      if (type === "video") {
        previewVideoFiles.push({
          uid: id,
          object_src: url,
          poster: media?.image.src,
          name: getFileNameFromUrl(url),
        } as IPreviewVideoFile);
        if (media_tags) {
          mediaTags[id] = mapIgTagsToFrontend(media_tags);
        }
      } else if (type === "photo") {
        previewImageFiles.push({
          src: url,
          uid: id,
          name: getFileNameFromUrl(url),
        } as IPreviewImageFile);
        if (media_tags) {
          mediaTags[id] = mapIgTagsToFrontend(media_tags);
        }
        if (alt_text) {
          altText[id] = alt_text;
        }
      } else if (type === "pdf") {
        previewPDFFiles.push({
          src: url,
          uid: id,
          name: getFileNameFromUrl(url),
        } as IPreviewImageFile);
      }

      uploadedFiles.push(uploadedFile);
      orderedFileIds.push(id);
    }
  }

  const coverImageProps = existingPost.publishing_thumbnail
    ? {
        coverImage: {
          uid: "",
          src: existingPost.publishing_thumbnail,
          name: getFileNameFromUrl(existingPost.publishing_thumbnail),
        } as IPreviewImageFile,
        coverNameLink: existingPost.publishing_thumbnail,
      }
    : {};

  const locationProps = {
    id: existingPost.location?.id,
    value: existingPost.location?.value || "",
    description: existingPost.location?.label,
    label: existingPost.location?.label || "",
  };

  return {
    asset,
    message: existingPost.message,
    uploadedFiles,
    previewImageFiles,
    previewVideoFiles,
    previewPDFFiles,
    orderedFileIds,
    location: locationProps,
    publishing_type: existingPost.publishing_type || "POST",
    tiktokOptions: existingPost.publishing_tiktok_options,
    postTags: existingPost.post_tags,
    mediaTags,
    altText,
    youtubeOptions: existingPost.publishing_youtube_options,
    ...coverImageProps,
  };
};

export const extractDomain = (link: string) => {
  const domainPattern = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/im;
  const match = link.match(domainPattern);

  if (match && match[1]) {
    return match[1];
  }

  return "";
};

export function copyObjectProperties<T>(source: T, target: T, properties: (keyof T)[]): T {
  properties.forEach(property => {
    target[property] = source[property];
  });

  return target;
}

export function loadImage(file: RcFile, onLoad: (image: HTMLImageElement) => void) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      const image = new Image();
      image.src = e.target?.result as string;
      image.onload = () => resolve(onLoad(image));
    };
  });
}

export const isYouTubeShort = (post: IPostItem) =>
  isYT(post) &&
  post.previewVideoFiles.length === 1 &&
  !!post.previewVideoFiles[0].metadata &&
  post.previewVideoFiles[0].metadata.height > post.previewVideoFiles[0].metadata.width &&
  post.previewVideoFiles[0].metadata.duration < 60;

export const isYouTubeLong = (post: IPostItem) =>
  isYT(post) && post.previewVideoFiles.length === 1 && !isYouTubeShort(post);

import { FormProps } from "rc-field-form";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { FC, useMemo } from "react";

import { FormInstance } from "antd";
import ClearableTrigger from "@components/_common/SelectTrigger/ClearableTrigger";
import { Icon, Input, Tooltip, IObjectOption, SelectStyledMulti } from "@bbdevcrew/bb_ui_kit_fe";

import { meSelector } from "@store/me/selectors";

import {
  HelpIcon,
  DropdownMenuPlacementType,
  handleMultiselectState,
} from "@bbdevcrew/bb_ui_kit_fe";
import { sanitizeUnderScoreAndUppercase } from "../helper";
import { IMultiSelectFilterOption } from "@store/me/types";
import { IClearableTriggerProps } from "@components/_common/SelectTrigger/ClearableTrigger.types";

interface IPostTypeFiltersProps extends Omit<FormProps, "onChange"> {
  placeholder?: string;
  InputVariation?: typeof Input;
  dropdownPlacement?: DropdownMenuPlacementType;
  dropdownMatchSelectWidth?: boolean;
  disabled?: boolean;
  customprops?: { form?: FormInstance };
  value?: string[];
  onChange?: (values?: string[]) => void;
}

const PostTypeFilters: FC<IPostTypeFiltersProps> = ({
  placeholder,
  customprops,
  dropdownPlacement,
  dropdownMatchSelectWidth,
  InputVariation = Input,
  disabled,
  value = [],
  onChange,
  ...props
}) => {
  const { t } = useTranslation();

  const me = useSelector(meSelector);

  const optionsFormatted = useMemo(() => {
    const options = me?.post_types?.items || [];

    const postTypeToOption = ({
      id,
      label,
      items,
      description,
    }: IMultiSelectFilterOption): IObjectOption => ({
      id: id,
      label: label,
      options: items?.map(postTypeToOption),
      iconPosition: "right",
      icon: description && (
        <>
          <Tooltip overlayStyle={{ zIndex: 100001 }} title={description}>
            <Icon icon={HelpIcon} _size="sm" />
          </Tooltip>
        </>
      ),
    });

    return options?.map(postTypeToOption) || [];
  }, [me?.post_types?.items]);

  const onInternalChange = (postTypeValue: string) => {
    const newState = handleMultiselectState(optionsFormatted, value, postTypeValue);

    if (customprops?.form) {
      onChange?.(newState || undefined);
    }
  };

  const _onClear = () => {
    onChange?.(undefined);
  };

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    if (value?.length === 0) {
      return ClearableTrigger(tooltipProps);
    }

    const tooltipElement = value.map(val => (
      <span key={val} style={{ display: "block" }}>
        {sanitizeUnderScoreAndUppercase(val)}
      </span>
    ));

    return ClearableTrigger({
      tooltip: tooltipElement,
      onClear: _onClear,
      ...tooltipProps,
    });
  };

  return (
    <SelectStyledMulti
      _size="sm"
      id={props.id}
      disabled={disabled}
      value={value}
      options={optionsFormatted}
      onChange={onInternalChange}
      InputVariation={InputVariation}
      dropdownPlacement={dropdownPlacement}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      TriggerVariation={TriggerWithTooltip}
      data-cy={props["data-cy" as keyof typeof props]}
      excludePlaceholder={t("generic:exclude")}
      excludedPlaceholder={t("generic:excluded")}
      placeholder={placeholder || t("components:post_types:placeholder")}
    />
  );
};

export default PostTypeFilters;

import {
  FB_VALID_FILE_TYPES,
  IG_VALID_FILE_TYPES,
  X_VALID_FILE_TYPES,
  TT_VALID_FILE_TYPES,
  LI_VALID_FILE_TYPES,
  MAX_UPLOAD_IMAGE_COUNT_FB,
  MAX_UPLOAD_IMAGE_COUNT_IG,
  MAX_UPLOAD_IMAGE_COUNT_X,
  MAX_UPLOAD_IMAGE_COUNT_LI,
  MAX_UPLOAD_VIDEO_COUNT_FB,
  MAX_UPLOAD_VIDEO_COUNT_IG,
  MAX_UPLOAD_VIDEO_COUNT_X,
  MAX_UPLOAD_VIDEO_COUNT_TT,
  MAX_UPLOAD_VIDEO_COUNT_LI,
  MAX_UPLOAD_DOCUMENT_COUNT_LI,
  MAX_UPLOAD_IMAGE_SIZE_FB,
  MAX_UPLOAD_IMAGE_SIZE_X,
  MAX_UPLOAD_IMAGE_SIZE_LI,
  MAX_UPLOAD_GIF_SIZE_X,
  MAX_UPLOAD_VIDEO_SIZE_FB,
  MAX_UPLOAD_VIDEO_SIZE_X,
  MAX_UPLOAD_VIDEO_SIZE_TT,
  MAX_UPLOAD_VIDEO_SIZE_LI,
  MAX_UPLOAD_DOCUMENT_SIZE_LI,
  MIN_UPLOAD_VIDEO_DURATION_TT,
  MIN_UPLOAD_VIDEO_DURATION_LI,
  INSTAGRAM_MAX_HASHTAG_COUNT,
  TEXTAREA_MAX_CHAR_LENGTH_DEFAULT,
  TEXTAREA_MAX_CHAR_LENGTH_X,
  TEXTAREA_MAX_CHAR_LENGTH_FACEBOOK,
  TEXTAREA_MAX_CHAR_LENGTH_INSTAGRAM,
  TEXTAREA_MAX_CHAR_LENGTH_TIKTOK,
  TEXTAREA_MAX_CHAR_LENGTH_LINKEDIN,
  MAX_UPLOAD_COVER_SIZE_IG,
  MAX_UPLOAD_ATTACHMENT_COUNT_X,
  MAX_UPLOAD_VIDEO_COUNT_YT,
  TEXTAREA_MAX_CHAR_LENGTH_YOUTUBE,
} from "./constants";
import { fetchVideoMetadata } from "@utils/fetchVideoMetadata";
import { IFileUid, IPostItem } from "./types";
import { UploadFile } from "antd/lib/upload/interface";
import { isGIF, isImage, isVideo, isDocument } from "@bbdevcrew/bb_ui_kit_fe";

// Common helpers
export const isFB = (post?: IPostItem) => post?.asset.platform === "facebook";
export const isIG = (post?: IPostItem) => post?.asset.platform === "instagram";
export const isX = (post?: IPostItem) => post?.asset.platform === "twitter";
export const isTT = (post?: IPostItem) => post?.asset.platform === "tiktok";
export const isLI = (post?: IPostItem) => post?.asset.platform === "linkedin";
export const isYT = (post?: IPostItem) => post?.asset.platform === "youtube";

export const isIGPost = (post: IPostItem) => isIG(post) && post.publishing_type === "POST";
export const isIGReel = (post: IPostItem) =>
  isIGPost(post) &&
  post.uploadedFiles.length == 1 &&
  !!post.uploadedFiles[0].type &&
  isVideo(post.uploadedFiles[0].type);

export const isGreaterThanXMB = (size: number, x: number) => size > x * 1000 * 1000;
export const isGreaterThanXGB = (size: number, x: number) => size > x * 1000 * 1000 * 1000;

// Getting different values according to the platform
export const getValidTypesPerPlatform = (platform: string) => {
  switch (platform) {
    case "facebook":
      return FB_VALID_FILE_TYPES;
    case "instagram":
      return IG_VALID_FILE_TYPES;
    case "twitter":
      return X_VALID_FILE_TYPES;
    case "tiktok":
      return TT_VALID_FILE_TYPES;
    case "linkedin":
      return LI_VALID_FILE_TYPES;
    default:
      return [];
  }
};

export const getMaxUploadFileCount = (platform: string) => {
  switch (platform) {
    case "facebook":
      return MAX_UPLOAD_IMAGE_COUNT_FB;
    case "instagram":
      return MAX_UPLOAD_IMAGE_COUNT_IG;
    case "twitter":
      return MAX_UPLOAD_IMAGE_COUNT_X;
    case "tiktok":
      return MAX_UPLOAD_VIDEO_COUNT_TT;
    case "linkedin":
      return MAX_UPLOAD_IMAGE_COUNT_LI;
    case "youtube":
      return MAX_UPLOAD_VIDEO_COUNT_YT;
    default:
      return 0;
  }
};

export const getMaxUploadImageCount = (platform: string) => {
  switch (platform) {
    case "facebook":
      return MAX_UPLOAD_IMAGE_COUNT_FB;
    case "instagram":
      return MAX_UPLOAD_IMAGE_COUNT_IG;
    case "twitter":
      return MAX_UPLOAD_IMAGE_COUNT_X;
    case "linkedin":
      return MAX_UPLOAD_IMAGE_COUNT_LI;
    default:
      return 0;
  }
};

export const getMaxUploadVideoCount = (platform: string) => {
  switch (platform) {
    case "facebook":
      return MAX_UPLOAD_VIDEO_COUNT_FB;
    case "instagram":
      return MAX_UPLOAD_VIDEO_COUNT_IG;
    case "twitter":
      return MAX_UPLOAD_VIDEO_COUNT_X;
    case "tiktok":
      return MAX_UPLOAD_VIDEO_COUNT_TT;
    case "linkedin":
      return MAX_UPLOAD_VIDEO_COUNT_LI;
    default:
      return 1;
  }
};

export const getMaxUploadDocCount = (platform: string) => {
  switch (platform) {
    case "linkedin":
      return MAX_UPLOAD_DOCUMENT_COUNT_LI;
    default:
      return 0;
  }
};

export const getMessageMaxLength = (platform?: string) => {
  switch (platform) {
    case "facebook":
      return TEXTAREA_MAX_CHAR_LENGTH_FACEBOOK;
    case "instagram":
      return TEXTAREA_MAX_CHAR_LENGTH_INSTAGRAM;
    case "twitter":
      return TEXTAREA_MAX_CHAR_LENGTH_X;
    case "tiktok":
      return TEXTAREA_MAX_CHAR_LENGTH_TIKTOK;
    case "linkedin":
      return TEXTAREA_MAX_CHAR_LENGTH_LINKEDIN;
    case "youtube":
      return TEXTAREA_MAX_CHAR_LENGTH_YOUTUBE;
    default:
      return TEXTAREA_MAX_CHAR_LENGTH_DEFAULT;
  }
};

export const getMinUploadVideoDuration = (platform: string) => {
  switch (platform) {
    case "tiktok":
      return MIN_UPLOAD_VIDEO_DURATION_TT;
    case "linkedin":
      return MIN_UPLOAD_VIDEO_DURATION_LI;
    default:
      return 0;
  }
};

// Functions that are checking for errors
export const hasTTRestrictedError = (posts: IPostItem[]) =>
  posts.some(post => post.error === "postingRestricted");

export const hasCaptionErrors = (posts: IPostItem[]) =>
  posts.some(post => post.error === "captionLength" || post.error === "hashtagCount");

export const hasMediaCombinationError = (files: UploadFile[], platform: string) => {
  const allFilesAreImages = files.every(file => file.type && isImage(file.type));
  const allFilesAreVideos = files.every(file => file.type && isVideo(file.type));
  const allFilesAreDocuments = files.every(file => file.type && isDocument(file.type));
  const filesAreAllVideosOrImages = allFilesAreImages || allFilesAreVideos;
  const imageNumberLimitReached =
    allFilesAreImages && files.length > getMaxUploadImageCount(platform);
  const videoNumberLimitReached =
    allFilesAreVideos && files.length > getMaxUploadVideoCount(platform);
  const docNumberLimitReached =
    allFilesAreDocuments && files.length > getMaxUploadDocCount(platform);
  const twitterAttachmentLimitReached = files.length > MAX_UPLOAD_ATTACHMENT_COUNT_X;

  switch (platform) {
    case "instagram":
    case "facebook":
      return !filesAreAllVideosOrImages || imageNumberLimitReached || videoNumberLimitReached;
    case "twitter":
      return twitterAttachmentLimitReached;
    case "tiktok":
      return !allFilesAreVideos || videoNumberLimitReached;
    case "linkedin":
      return (
        docNumberLimitReached ||
        videoNumberLimitReached ||
        (!allFilesAreDocuments && !filesAreAllVideosOrImages)
      );
  }
};

export const hasFileTypeError = (uploadedFiles: UploadFile[], platform: string) => {
  switch (platform) {
    case "facebook":
      return uploadedFiles.some(file => file.type && !FB_VALID_FILE_TYPES.includes(file.type));
    case "instagram":
      return uploadedFiles.some(file => file.type && !IG_VALID_FILE_TYPES.includes(file.type));
    case "tiktok":
      return uploadedFiles.some(file => file.type && !TT_VALID_FILE_TYPES.includes(file.type));
    case "twitter":
      return uploadedFiles.some(file => file.type && !X_VALID_FILE_TYPES.includes(file.type));
    case "linkedin":
      return uploadedFiles.some(file => file.type && !LI_VALID_FILE_TYPES.includes(file.type));
    default:
      return false;
  }
};

export const hasImageSizeError = (uploadedFiles: UploadFile[], platform: string) => {
  switch (platform) {
    case "facebook":
    case "instagram":
      return uploadedFiles.some(
        file =>
          file.type &&
          file.size &&
          isImage(file.type) &&
          isGreaterThanXMB(file.size, MAX_UPLOAD_IMAGE_SIZE_FB),
      );
    case "twitter":
      return uploadedFiles.some(
        file =>
          file.type &&
          file.size &&
          isImage(file.type) &&
          isGreaterThanXMB(file.size, MAX_UPLOAD_IMAGE_SIZE_X),
      );
    case "linkedin":
      return uploadedFiles.some(
        file =>
          file.type &&
          file.size &&
          isImage(file.type) &&
          isGreaterThanXMB(file.size, MAX_UPLOAD_IMAGE_SIZE_LI),
      );
    default:
      return false;
  }
};

export const hasGIFSizeError = (uploadedFiles: UploadFile[], platform: string) => {
  switch (platform) {
    case "twitter":
      return uploadedFiles.some(
        file =>
          file.type &&
          file.size &&
          isGIF(file.type) &&
          isGreaterThanXMB(file.size, MAX_UPLOAD_GIF_SIZE_X),
      );
    default:
      return false;
  }
};

export const hasVideoSizeError = (uploadedFiles: UploadFile[], platform: string) => {
  switch (platform) {
    case "facebook":
    case "instagram":
      return uploadedFiles.some(
        file =>
          file.type &&
          file.size &&
          isVideo(file.type) &&
          isGreaterThanXGB(file.size, MAX_UPLOAD_VIDEO_SIZE_FB),
      );
    case "twitter":
      return uploadedFiles.some(
        file =>
          file.type &&
          file.size &&
          isVideo(file.type) &&
          isGreaterThanXMB(file.size, MAX_UPLOAD_VIDEO_SIZE_X),
      );
    case "tiktok":
      return uploadedFiles.some(
        file =>
          file.type &&
          file.size &&
          isVideo(file.type) &&
          isGreaterThanXGB(file.size, MAX_UPLOAD_VIDEO_SIZE_TT),
      );
    case "linkedin":
      return uploadedFiles.some(
        file =>
          file.type &&
          file.size &&
          isVideo(file.type) &&
          isGreaterThanXGB(file.size, MAX_UPLOAD_VIDEO_SIZE_LI),
      );
    default:
      return false;
  }
};

export const hasDocumentSizeError = (uploadedFiles: UploadFile[], platform: string) => {
  switch (platform) {
    case "linkedin":
      return uploadedFiles.some(
        file =>
          file.type &&
          file.size &&
          isDocument(file.type) &&
          isGreaterThanXMB(file.size, MAX_UPLOAD_DOCUMENT_SIZE_LI),
      );
    default:
      return false;
  }
};

export const hasIGFileRatioError = (post: IPostItem) => {
  let hasError = false;

  if (isIG(post) && post.publishing_type !== "STORY") {
    post.previewImageFiles.forEach(image => {
      const w = image.width;
      const h = image.height;

      // IG image aspect ratios shouldn't be outside the range 4:5 to 1.91:1
      if (w / h < 0.8 || w / h > 1.91) hasError = true;
    });
  }

  return hasError;
};

export const hasFbRequiredFieldsError = (post: IPostItem, message: string) => {
  return isFB(post) && !post.uploadedFiles.length && !message.length && !post.asset.preview_link;
};

export const hasXRequiredFieldsError = (post: IPostItem, message: string) => {
  return isX(post) && !post.uploadedFiles.length && !message.length && !post.asset.preview_link;
};

// Functions that are validating data
export const validateRequiredFields = (
  posts: IPostItem[],
  setPosts: (posts: IPostItem[]) => void,
) => {
  let isValid = true;
  const postsCopy = [...posts];

  postsCopy.forEach(post => {
    if (isFB(post)) {
      if (hasFbRequiredFieldsError(post, post.message)) {
        isValid = false;

        if (post.error !== "requiredFields") post.error = "requiredFields";
      } else if (post.error === "requiredFields") post.error = undefined;
    }

    if (isIG(post)) {
      if (!post.uploadedFiles.length) {
        isValid = false;

        if (post.error !== "requiredFields") post.error = "requiredFields";
      } else if (post.error === "requiredFields") post.error = undefined;
    }

    if (isX(post)) {
      if (hasXRequiredFieldsError(post, post.message)) {
        isValid = false;

        if (post.error !== "requiredFields") post.error = "requiredFields";
      } else if (post.error === "requiredFields") post.error = undefined;
    }

    if (isTT(post)) {
      if (!post.uploadedFiles.length) {
        isValid = false;

        if (post.error !== "requiredFields") post.error = "requiredFields";
      } else if (post.error === "requiredFields") post.error = undefined;
    }
  });

  if (!isValid) setPosts(postsCopy);

  return isValid;
};

export const validateMediaCombination = (
  posts: IPostItem[],
  setPosts: (posts: IPostItem[]) => void,
) => {
  let isValid = true;
  const postsCopy = [...posts];

  postsCopy.forEach(post => {
    if (hasMediaCombinationError(post.uploadedFiles, post.asset.platform)) {
      isValid = false;
      post.error = "mediaCombination";
    }
  });

  if (!isValid) setPosts(postsCopy);

  return isValid;
};

export const validateTTDiscloseContentOptions = (
  posts: IPostItem[],
  setPosts: (posts: IPostItem[]) => void,
) => {
  let isValid = true;
  const postsCopy = [...posts];

  postsCopy.forEach(post => {
    const isOptionSelected =
      post.tiktokOptions?.promote_own_content || post.tiktokOptions?.promote_others_content;

    if (post.tiktokOptions?.disclose_content && !isOptionSelected) {
      isValid = false;
      post.error = "discloseContentRequired";
    }
  });

  if (!isValid) setPosts(postsCopy);

  return isValid;
};

export type UpdatePostType = (
  updatedPost: IPostItem,
  updatedPropertyName?: keyof IPostItem,
) => void;

export const validateFileTypes = (posts: IPostItem[], updatePost: UpdatePostType) => {
  let isValid = true;

  posts.forEach(post => {
    if (hasFileTypeError(post.uploadedFiles, post.asset.platform)) {
      isValid = false;

      updatePost(
        {
          ...post,
          error: "fileType",
        },
        "error",
      );
    }
  });

  return isValid;
};

export const validateFileSizes = (posts: IPostItem[], updatePost: UpdatePostType) => {
  let isValid = true;

  posts.forEach(post => {
    if (hasGIFSizeError(post.uploadedFiles, post.asset.platform)) {
      isValid = false;
      updatePost(
        {
          ...post,
          error: "gifSize",
        },
        "error",
      );
    }

    if (hasImageSizeError(post.uploadedFiles, post.asset.platform)) {
      isValid = false;
      updatePost(
        {
          ...post,
          error: "imageSize",
        },
        "error",
      );
    }

    if (post.coverImage && isGreaterThanXMB(post.coverImage.size, MAX_UPLOAD_COVER_SIZE_IG)) {
      isValid = false;
      updatePost(
        {
          ...post,
          error: "coverSize",
        },
        "error",
      );
    }

    if (hasVideoSizeError(post.uploadedFiles, post.asset.platform)) {
      isValid = false;
      updatePost(
        {
          ...post,
          error: "videoSize",
        },
        "error",
      );
    }

    if (hasDocumentSizeError(post.uploadedFiles, post.asset.platform)) {
      isValid = false;
      updatePost(
        {
          ...post,
          error: "documentSize",
        },
        "error",
      );
    }
  });

  return isValid;
};

export const validateVideoDuration = async (
  file: IFileUid,
  platform: string,
  maxDuration: number,
  setHasVideoDurationError: (hasError: boolean) => void,
) => {
  const source = URL.createObjectURL(file);
  const { duration } = await fetchVideoMetadata(source);
  window.URL.revokeObjectURL(source);
  if (duration > maxDuration || duration < getMinUploadVideoDuration(platform)) {
    setHasVideoDurationError(true);
  }
};

export const validateCaptionLength = (post: IPostItem) => {
  const _post = { ...post };
  const maxCaptionLength = getMessageMaxLength(post.asset.platform);

  if (post.message.length > maxCaptionLength) {
    _post.error = "captionLength";
  } else if (_post.error === "captionLength") _post.error = undefined;

  return _post;
};

export const validateIGHashtagCount = (post: IPostItem) => {
  const _post = { ...post };

  const hashtagAmount = _post.message?.match(/#/g)?.length || 0;
  _post.hashtagCount = hashtagAmount;

  if (hashtagAmount > INSTAGRAM_MAX_HASHTAG_COUNT) {
    _post.error = "hashtagCount";
  } else if (_post.error === "hashtagCount") {
    _post.error = undefined;
  }

  return _post;
};

export const validatedMessageForPost = (_post: IPostItem) => {
  let post = { ..._post };
  const message = _post.message;

  post = validateCaptionLength(post);

  if (hasFbRequiredFieldsError(post, message) && post.error === undefined) {
    post.error = "requiredFields";
  } else if (hasXRequiredFieldsError(post, message) && post.error === undefined) {
    post.error = "requiredFields";
  } else if (isIG(post)) {
    post = validateIGHashtagCount(post);
  }

  if (message.length && post.error === "requiredFields") post.error = undefined;

  return post;
};

export const validatePostVideoFilesCount = (post: IPostItem) => {
  const videoCount = post.previewVideoFiles.length;
  let isValid = true;

  if (videoCount) {
    if (isFB(post)) isValid = videoCount <= MAX_UPLOAD_VIDEO_COUNT_FB;
    if (isIG(post)) isValid = videoCount <= MAX_UPLOAD_VIDEO_COUNT_IG;
    if (isX(post)) isValid = videoCount <= MAX_UPLOAD_VIDEO_COUNT_X;
    if (isTT(post)) isValid = videoCount === MAX_UPLOAD_VIDEO_COUNT_TT;
    if (isLI(post)) isValid = videoCount === MAX_UPLOAD_VIDEO_COUNT_LI;
    if (isYT(post)) isValid = videoCount === MAX_UPLOAD_VIDEO_COUNT_YT;
  } else {
    if (isTT(post)) isValid = false;
  }

  return isValid;
};

export const validateVideoDurationBeforePublish = (posts: IPostItem[]) => {
  let isValid = true;

  posts.forEach(post => {
    if (post.error === "videoDuration") isValid = false;
  });

  return isValid;
};

export const validatePostAttachmentFilesCount = (post: IPostItem) => {
  const attachmentCount = post.previewVideoFiles.length + post.previewImageFiles.length;
  const tooManyAttachments =
    attachmentCount && isX(post) && attachmentCount > MAX_UPLOAD_ATTACHMENT_COUNT_X;
  return !tooManyAttachments;
};

export const clearErrorsAfterFileRemove = (fileList: UploadFile[], post: IPostItem) => {
  const postCopy = { ...post };

  if (!hasMediaCombinationError(fileList, post.asset.platform) && post.error === "mediaCombination")
    postCopy.error = undefined;

  if (!hasIGFileRatioError(postCopy) && post.error === "fileRatio") postCopy.error = undefined;

  if (
    !hasVideoSizeError(postCopy.uploadedFiles, postCopy.asset.platform) &&
    post.error === "videoSize"
  )
    postCopy.error = undefined;

  if (
    !hasImageSizeError(postCopy.uploadedFiles, postCopy.asset.platform) &&
    post.error === "imageSize"
  )
    postCopy.error = undefined;

  if (
    !hasFileTypeError(postCopy.uploadedFiles, postCopy.asset.platform) &&
    post.error === "fileType"
  )
    postCopy.error = undefined;

  if (
    !hasDocumentSizeError(postCopy.uploadedFiles, postCopy.asset.platform) &&
    post.error === "documentSize"
  )
    postCopy.error = undefined;

  return postCopy;
};

// The check is only for videoDuration because it's an async validation
export const revalidateFilesAfterAssetSync = (posts: IPostItem[], newlyAddedPost: IPostItem) => {
  const postCopy = { ...newlyAddedPost };

  posts.forEach(post => {
    if (post.asset.platform === postCopy.asset.platform && post.error === "videoDuration") {
      postCopy.error = "videoDuration";
    }
  });

  return postCopy;
};

import {
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
  KeyboardEvent,
  ClipboardEvent,
} from "react";
import {
  isChipValid,
  sanitizeChip,
  isChipPhrase,
  handleKeyDown,
  handleOnChange,
  ADD_COMMAND_KEYS,
  NESTING_COMMAND_KEYS,
  isChipPhraseEmpty,
  hasOpenPhrase,
  _IInputChipProps,
  shouldBeClearChipValue,
} from "./InputChipConfig";
import { InputSizeType } from "@bbdevcrew/bb_ui_kit_fe";

export type KeywordQueryType = Array<string | string[] | keyof typeof InputChipQueryOperatorsEnum>;

export enum InputChipQueryOperatorsEnum {
  AND = "__AND__",
  OR = "__OR__",
  NOT = "__NOT__",
  AND_NOT = "__AND__NOT__",
  OR_NOT = "__OR__NOT__",
}

export enum InputChipQueryLabelsEnum {
  __AND__ = "AND",
  __OR__ = "OR",
  __NOT__ = "NOT",
  __AND__NOT__ = "AND_NOT",
  __OR__NOT__ = "OR_NOT",
}

// Triggered by value
export const MENU_COMMAND_KEYS = ["/"];

const OPERATOR_REGEX = /^__\w+__$/;

export interface IContextMenu {
  chipValue: string;
  chips: string[];
  _size?: InputSizeType;
  onChipAdd: (value: string) => void;
}
/**
 * Parse `Operators` into UI-friendly labels.
 */
export function transformOperatorToChip(chip: string) {
  if (chip.match(OPERATOR_REGEX)) {
    return InputChipQueryLabelsEnum[chip as keyof typeof InputChipQueryLabelsEnum];
  }

  return chip;
}

// Format nesting ["asd", ["asd"]] -> ["asd", "(", "asd", ")"]
export function flattenKeywords(value: KeywordQueryType): string[] {
  let res: string[] = [];

  value?.forEach(token => {
    if (typeof token === "string") {
      res.push(token);
    } else {
      const flattened = `( ${token.join(" ")} )`.split(" ");

      res = res.concat(flattened);
    }
  });

  return res;
}

export function unflattenKeywords(value: string[]): KeywordQueryType {
  const formatted: KeywordQueryType = [];
  const currValue = [...value];

  currValue?.forEach((token, idx) => {
    if (token === "(") {
      const closingBraceIdx = currValue.indexOf(")", idx + 1);
      const subArr = currValue.slice(idx + 1, closingBraceIdx === -1 ? undefined : closingBraceIdx);
      currValue.splice(idx + 1, subArr.length + 1);

      if (subArr.length) {
        formatted.push(subArr);
      }
    } else {
      formatted.push(token);
    }
  });

  return formatted;
}

export function pickKeywords(value: string[]) {
  return value?.filter(
    word =>
      !NESTING_COMMAND_KEYS.includes(word) && !Object.keys(InputChipQueryLabelsEnum).includes(word),
  );
}

export function sanitizeUnderscores(value: string[]) {
  return value?.map(word => {
    return InputChipQueryLabelsEnum[word as keyof typeof InputChipQueryLabelsEnum] ?? word;
  });
}

export function sanitizeNestedUnderscores(value: string[]) {
  return unflattenKeywords(sanitizeUnderscores(flattenKeywords(value)));
}

// Checks if number of open matches number of closed parentheses
// Revisit if we need to allow multiple nesting levels
export function hasOpenNesting(allChips: string[]) {
  const openCount = allChips.filter(chip => chip === "(").length;
  const closedCount = allChips.filter(chip => chip === ")").length;

  return openCount !== closedCount;
}

// If number of quotes is odd, it's an open phrase
export function hasDanglingQuotes(phrase: string) {
  const quotes = phrase.match(/\"/g);

  return (quotes?.length || 0) % 2 !== 0;
}

function validateChipPhrase(eventCode: string, chipValue: string) {
  // If ADD_COMMAND other than Space, allow add for non-empty phrases
  if (
    ADD_COMMAND_KEYS.filter(command => command !== "Space").includes(eventCode) &&
    !isChipPhraseEmpty(chipValue) &&
    !hasDanglingQuotes(sanitizeChip(chipValue))
  ) {
    return true;
  }

  return false;
}

export function getCommandMenuVisiblity(chipValue: string, menuOffsetX: number): boolean {
  if (MENU_COMMAND_KEYS.includes(chipValue.charAt(0)) && menuOffsetX !== -1) {
    return true;
  }

  return false;
}

export function useInputChipQuery({
  value, // all chips
  onKeyUp,
  onChipAdd,
  onChipRemove,
  onChange,
}: Pick<_IInputChipProps, "onKeyUp" | "onChipRemove" | "onChipAdd" | "onChange" | "value">) {
  const [chipValue, setChipValue] = useState("");
  const [clipBoardText, setClipBoardText] = useState("");

  const allowChipAdd = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      // Prevent double nesting "(", "("
      if (chipValue.match(/^\(/) && hasOpenNesting(value)) {
        return false;
      }

      // It's an opening or closing nesting character, allow add
      if (
        // Automatically add `open` nesting character
        (event.key === "(" && chipValue.length === 1) ||
        // Automatically add `close` nesting character if there's previously open nesting
        (event.key === ")" && chipValue.length === 1 && hasOpenNesting(value))
      ) {
        return true;
      }

      if (isChipPhrase(sanitizeChip(chipValue))) {
        return validateChipPhrase(event.code, chipValue);
      }

      if (
        // Allow chipAdd if: It's ADD_COMMAND_KEY and it's not a phrase (phrases can contain spaces in them)
        ADD_COMMAND_KEYS.includes(event.code) &&
        !hasOpenPhrase(sanitizeChip(chipValue)) &&
        isChipValid(sanitizeChip(chipValue))
      ) {
        return true;
      }

      return false;
    },
    [chipValue, value],
  );

  const _onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (allowChipAdd(event)) {
      onChipAdd(sanitizeChip(chipValue));
      setChipValue("");
    }

    onKeyUp && onKeyUp(event);
  };

  const onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    // eslint-disable-next-line
    // @ts-ignore
    const clipboardData = event.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData("Text") || "";
    setClipBoardText(pastedData);

    /**
     * Allow pasting data into empty quotes.
     * See: https://brandbastion.atlassian.net/browse/DEV-7177?focusedCommentId=22883
     */
    if (chipValue === '""') {
      pastedData = `"${pastedData}"`;
    }

    pastedData
      /**
       * Match commas (,) that are not surrounded by quotes.
       * Phrases can also have commas in them, but we don't want to split it apart.
       * E.g. "some phrase, thing", and this here, "and another phrase"
       * parsed as '"some phrase, thing"__ and this here__ "and another phrase"'.
       * We use `__` to easily split string into array and perform validation/adding.
       */
      .replace(/\,(?=([^"]*"[^"]*")*[^"]*$)/g, "__")
      .split("__")
      .forEach(token => {
        if (isChipPhrase(token) && !isChipPhraseEmpty(token)) {
          onChipAdd(token);
        } else {
          token
            .split(",")
            .filter(chip => !isChipPhrase(chip))
            .map(chip => chip.split(/\s/))
            .flat()
            .forEach(pastedChip => {
              if (isChipValid(pastedChip)) {
                onChipAdd(pastedChip);
              }
            });
        }
      });
  };

  useEffect(() => {
    setChipValue("");
  }, [value.length]);

  useEffect(() => {
    if (clipBoardText || shouldBeClearChipValue(clipBoardText || chipValue)) {
      setClipBoardText("");
      setChipValue("");
    }
    // eslint-disable-next-line
  }, [clipBoardText]);

  return {
    onPaste,
    chipValue,
    onKeyUp: _onKeyUp,
    onChange: (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      }
      handleOnChange(event, setChipValue);
    },
    onKeyDown: (event: KeyboardEvent<HTMLInputElement>) =>
      handleKeyDown(event, chipValue, value, onChipRemove),
  };
}

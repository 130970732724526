import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ScrollView } from "@bbdevcrew/bb_ui_kit_fe";

import ItemCard from "@components/_common/Reply/common/itemCard/ItemCard";
import ModalHeaderActions from "@components/_common/ModalHeaderActions";

import { useSavedFiltersList } from "@utils/useSavedFiltersList";

import s from "./SavedFiltersList.module.less";

import { ISavedFiltersListProps } from "./SavedFiltersList.types";

export const SavedFiltersList: React.FC<ISavedFiltersListProps> = ({
  onAddClick,
  onEditClick,
  onDeleteClick,
}) => {
  const { t } = useTranslation();
  const savedFiltersList = useSavedFiltersList();
  const [query, setQuery] = useState<string>("");

  const filteredFilters = useMemo(() => {
    return savedFiltersList.filter(({ name }) =>
      name.trim().toLowerCase().includes(query.trim().toLowerCase()),
    );
  }, [savedFiltersList, query]);

  return (
    <div className={s.bbSavedFiltersList}>
      <ModalHeaderActions
        title={t("pages:dashboard:modals:savesSearch:filterSelector:title")}
        onAddClick={onAddClick}
        onSearch={value => setQuery(value)}
        placeholder="Search"
        className={s.bbSavedFiltersListHeader}
      />
      <ScrollView className={s.bbSavedFiltersListScrollView}>
        <>
          <ItemCard
            name={t("pages:dashboard:modals:savesSearch:filterSelector:allConversations")}
            color="all"
          />
          <hr className={s.bbSavedFiltersListRule} />
          {filteredFilters.map(filterItem => {
            const { id, name, color } = filterItem;

            return (
              <ItemCard
                key={id}
                name={name}
                color={color}
                actions={[
                  {
                    key: "edit",
                    label: "generic:edit",
                    onActionClick: () => onEditClick(filterItem),
                  },
                  {
                    key: "delete",
                    label: "generic:delete",
                    onActionClick: () => onDeleteClick(filterItem),
                  },
                ]}
              />
            );
          })}
        </>
      </ScrollView>
    </div>
  );
};

export default SavedFiltersList;

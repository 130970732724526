import {
  GET_PUBLISHINGS_LIST,
  GET_PUBLISHINGS_LIST_SUCCESS,
  GET_PUBLISHINGS_LIST_FAILURE,
  CREATE_POST_PUBLISHING,
  CREATE_POST_PUBLISHING_SUCCESS,
  CREATE_POST_PUBLISHING_FAILURE,
  UPDATE_POST_PUBLISHING,
  UPDATE_POST_PUBLISHING_SUCCESS,
  UPDATE_POST_PUBLISHING_FAILURE,
  DELETE_POST_PUBLISHING,
  DELETE_POST_PUBLISHING_SUCCESS,
  DELETE_POST_PUBLISHING_FAILURE,
  GET_PUBLISHINGS_URL_PREVIEWS,
  GET_PUBLISHINGS_URL_PREVIEWS_SUCCESS,
  GET_PUBLISHINGS_URL_PREVIEWS_FAILURE,
  CLEAR_PUBLISHINGS_URL_PREVIEWS,
  GET_PUBLISHINGS_TT_CREATOR_INFO,
  GET_PUBLISHINGS_TT_CREATOR_INFO_SUCCESS,
  GET_PUBLISHINGS_TT_CREATOR_INFO_FAILURE,
  GET_INSTAGRAM_ASSET_INFO,
  GET_INSTAGRAM_ASSET_INFO_SUCCESS,
  GET_INSTAGRAM_ASSET_INFO_FAILURE,
  GET_INSTAGRAM_PRODUCTS,
  GET_INSTAGRAM_PRODUCTS_SUCCESS,
  GET_INSTAGRAM_PRODUCTS_FAILURE,
  GET_YOUTUBE_CATEGORIES,
  GET_YOUTUBE_CATEGORIES_SUCCESS,
  GET_YOUTUBE_CATEGORIES_FAILURE,
  GET_YOUTUBE_PLAYLISTS,
  GET_YOUTUBE_PLAYLISTS_SUCCESS,
  GET_YOUTUBE_PLAYLISTS_FAILURE,
} from "./actionTypes";
import { createAction, createCustomAction } from "typesafe-actions";
import {
  ILinkMetaData,
  IPublishingListResponse,
  IPublishingsListPayload,
  ICreatePublishingPayload,
  IEditPublishingPostPayload,
  ITTCreatorInfo,
  ITTCreatorInfoErrorData,
  IInstagramAssetInfo,
  IInstagramProduct,
  IPlaylist,
} from "./types";
import { IYouTubeCategory } from "@components/publish/postCreation/YouTubeOptions";

export const getPublishingsListAction =
  createAction(GET_PUBLISHINGS_LIST)<IPublishingsListPayload>();
export const getPublishingsListSuccessAction = createAction(
  GET_PUBLISHINGS_LIST_SUCCESS,
)<IPublishingListResponse>();
export const getPublishingsListFailureAction = createAction(GET_PUBLISHINGS_LIST_FAILURE)<void>();

export const createPostPublishingAction =
  createAction(CREATE_POST_PUBLISHING)<ICreatePublishingPayload>();
export const createPostPublishingSuccessAction = createCustomAction(
  CREATE_POST_PUBLISHING_SUCCESS,
  (scheduled: boolean, is_draft: boolean) => ({
    payload: { scheduled, is_draft },
  }),
);
export const createPostPublishingFailureAction = createAction(
  CREATE_POST_PUBLISHING_FAILURE,
)<string>();

export const updatePostPublishingAction =
  createAction(UPDATE_POST_PUBLISHING)<IEditPublishingPostPayload>();
export const updatePostPublishingSuccessAction = createCustomAction(
  UPDATE_POST_PUBLISHING_SUCCESS,
  (scheduled: boolean, is_draft: boolean) => ({
    payload: { scheduled, is_draft },
  }),
);
export const updatePostPublishingFailureAction = createAction(
  UPDATE_POST_PUBLISHING_FAILURE,
)<string>();

export const deletePostPublishingAction = createAction(DELETE_POST_PUBLISHING)<string>();
export const deletePostPublishingSuccessAction = createAction(
  DELETE_POST_PUBLISHING_SUCCESS,
)<string>();
export const deletePostPublishingFailureAction = createAction(
  DELETE_POST_PUBLISHING_FAILURE,
)<string>();

export const getUrlPreviewsAction = createAction(GET_PUBLISHINGS_URL_PREVIEWS)<string[]>();
export const getUrlPreviewsSuccessAction = createAction(GET_PUBLISHINGS_URL_PREVIEWS_SUCCESS)<
  ILinkMetaData[]
>();
export const getUrlPreviewsFailureAction = createAction(
  GET_PUBLISHINGS_URL_PREVIEWS_FAILURE,
)<void>();
export const clearUrlPreviewsAction = createCustomAction(
  CLEAR_PUBLISHINGS_URL_PREVIEWS,
  (isUrlPreviewRemoved?: boolean) => ({
    payload: isUrlPreviewRemoved,
  }),
);

export const getTiktokCreatorInfoAction = createAction(GET_PUBLISHINGS_TT_CREATOR_INFO)<string>();
export const getTiktokCreatorInfoSuccessAction = createAction(
  GET_PUBLISHINGS_TT_CREATOR_INFO_SUCCESS,
)<ITTCreatorInfo>();
export const getTiktokCreatorInfoFailureAction = createAction(
  GET_PUBLISHINGS_TT_CREATOR_INFO_FAILURE,
)<ITTCreatorInfoErrorData>();

export const getInstagramAssetInfoAction = createAction(GET_INSTAGRAM_ASSET_INFO)<string>();
export const getInstagramAssetInfoSuccessAction = createCustomAction(
  GET_INSTAGRAM_ASSET_INFO_SUCCESS,
  (data: IInstagramAssetInfo, asset_id: string) => ({
    payload: { data, asset_id },
  }),
);
export const getInstagramAssetInfoFailureAction = createAction(
  GET_INSTAGRAM_ASSET_INFO_FAILURE,
)<string>();

export const getInstagramProductsAction = createAction(GET_INSTAGRAM_PRODUCTS)<string>();
export const getInstagramProductsSuccessAction = createAction(GET_INSTAGRAM_PRODUCTS_SUCCESS)<
  IInstagramProduct[]
>();
export const getInstagramProductsFailureAction = createAction(
  GET_INSTAGRAM_PRODUCTS_FAILURE,
)<string>();

export const getYouTubeCategoriesAction = createCustomAction(
  GET_YOUTUBE_CATEGORIES,
  (assetId: string, country: string) => ({
    payload: { assetId, country },
  }),
);
export const getYouTubeCategoriesSuccessAction = createCustomAction(
  GET_YOUTUBE_CATEGORIES_SUCCESS,
  (assetId: string, categories: IYouTubeCategory[]) => ({
    payload: { categories, assetId },
  }),
);
export const getYouTubeCategoriesFailureAction = createAction(
  GET_YOUTUBE_CATEGORIES_FAILURE,
)<string>();

export const getYouTubePlaylistsAction = createAction(GET_YOUTUBE_PLAYLISTS)<string>();
export const getYouTubePlaylistsActionSuccess = createCustomAction(
  GET_YOUTUBE_PLAYLISTS_SUCCESS,
  (assetId: string, playlists: IPlaylist[]) => ({
    payload: { playlists, assetId },
  }),
);
export const getYouTubePlaylistsActionFailure = createAction(
  GET_YOUTUBE_PLAYLISTS_FAILURE,
)<string>();

import React from "react";

import { Input } from "@bbdevcrew/bb_ui_kit_fe";
import { useInputChip, _IInputChipProps } from "./InputChipConfig";
import { ChipListAction } from "@components/_common/ChipList/ChipList";

function InputChip({
  value,
  _size,
  onChipAdd,
  onChipRemove,
  wrapperClassName,
  ...props
}: _IInputChipProps) {
  const { chipValue, onKeyUp, onKeyDown, onChange, onPaste } = useInputChip({
    value,
    onChipAdd,
    onChipRemove,
  });

  const isValueLengthZero = value.length === 0;

  return (
    <div className="relative flex flex-row items-center">
      <ChipListAction
        _size="xs"
        value={value}
        onActionClick={onChipRemove}
        renderAddChip={() => (
          <Input
            {...props}
            _size={_size}
            value={chipValue}
            onKeyUp={onKeyUp}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onPaste={onPaste}
            wrapperClassName={` ${wrapperClassName} ${
              !isValueLengthZero ? "w-auto" : ""
            } border-none  inline-flex min-w-[100px]`}
          />
        )}
      />
    </div>
  );
}

export default InputChip;

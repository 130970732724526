import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import sentimentStyles from "../../CommentCard/commentActions/CommentActions.module.less";

import { IUseCommentActionItemsProps } from "./types";
import { isTiktokPlatform, isYoutubePlatform } from "@utils/platform";
import { SENTIMENT_ACTIONS } from "../../CommentCard/commentActions/helper";
import { IActionDropdownItem } from "../../ActionsDropdown/ActionsDropdown.type";
import { PostPlacementEnum } from "@components/_common/Social/Post/Post.type";

import {
  AdIcon,
  PostIcon,
  UserIcon,
  ExportIcon,
  SmileyIcon,
  WarningOffIcon,
  ParentCommentIcon,
} from "@assets/index";

export const useCommentExternalActionDropdownItems = ({
  actionFunctions,
  comment,
  showCommentLink,
}: IUseCommentActionItemsProps) => {
  const { t } = useTranslation();
  const isStoryComment = comment?.post_placement === PostPlacementEnum.STORY;
  const isDmComment = comment?.post_placement === PostPlacementEnum.DM;

  if (!comment) return [];

  const {
    onParentClick,
    onOpenClickReply,
    onOpenAdsClick,
    onOpenPostClick,
    filterAuthorsId,
    onDismissErrorClick,
    updateCommentSentiment,
  } = actionFunctions;
  const items: IActionDropdownItem[] = [];
  const isYoutubeComment = isYoutubePlatform(comment.platform.asset_platform || "");
  const isTiktokComment = isTiktokPlatform(comment.platform.asset_platform || "");

  const onClickReply = () => onOpenClickReply && onOpenClickReply(comment.id);
  const onClickParent = () => onParentClick && onParentClick(comment.id);
  const onClickAds = () => onOpenAdsClick && onOpenAdsClick(comment.post_id);
  const onClickPost = () => onOpenPostClick && onOpenPostClick(comment.post_id);
  const onClickFilterByAuthorId = () =>
    comment.author_id && filterAuthorsId && filterAuthorsId(comment.author_id);
  const onChangeSentiment = (sentiment: string) =>
    updateCommentSentiment && updateCommentSentiment(comment.id, sentiment);
  const onClickDismissError = () => onDismissErrorClick && onDismissErrorClick(comment.id);
  const onClickLink = () => window.open(comment.permalink, "_blank");

  if (comment.reply.is_sent && !isStoryComment && onOpenClickReply) {
    items.push({
      id: "thread",
      onClick: onClickReply,
      label: t("components:comments:actions:showThread"),
      icon: <ParentCommentIcon />,
    });
  }

  if (!!comment.parent && onParentClick) {
    items.push({
      id: "parent",
      onClick: onClickParent,
      label: t("components:comments:actions:showParent"),
      icon: <ParentCommentIcon />,
    });
  }

  if (comment.has_ads && onOpenAdsClick) {
    items.push({
      id: "ad",
      onClick: onClickAds,
      label: t("components:comments:actions:showAd"),
      icon: <AdIcon />,
    });
  }

  if (!!comment.post_id && onOpenPostClick && !isDmComment) {
    items.push({
      id: "ad",
      onClick: onClickPost,
      label: t("components:comments:actions:showPost"),
      icon: <PostIcon />,
    });
  }

  if (!isTiktokComment) {
    items.push({
      id: "sentiment",
      label: t("components:comments:actions:changeSentiment"),
      icon: <SmileyIcon />,
      disabled:
        isYoutubeComment && comment.actions.sentiment_change_capability?.capability === "disabled",
      tooltipText: comment.actions.sentiment_change_capability?.tooltip,
      iconClassName: sentimentStyles.bbSentimentIconClass,
      subItems: SENTIMENT_ACTIONS.map(sentiment => ({
        id: sentiment,
        onClick: () => onChangeSentiment(sentiment),
        label: t(`components:comments:actions:sentiments:${sentiment}`),
        iconClassName: classNames(
          sentimentStyles.bbActionCircleClass,
          sentimentStyles[`bbActionCircleClass-${sentiment}`],
        ),
      })),
    });
  }

  if (showCommentLink && comment.permalink && !isStoryComment && !isDmComment) {
    const isFBMention =
      comment.platform.name?.includes("facebook") &&
      (comment.post_type === "MENTION" || comment.post_type === "CAPTION_MENTION");
    const commentLinkLabel = isFBMention
      ? t("components:comments:actions:goToMention")
      : t("components:comments:actions:goToComment");

    items.push({
      id: "link",
      onClick: onClickLink,
      label: commentLinkLabel,
      icon: <ExportIcon />,
    });
  }

  if (comment.action_error?.user_message || comment.action_error?.platform_message) {
    items.push({
      id: "dismiss_errors",
      onClick: onClickDismissError,
      label: t("components:comments:actions:dismissError"),
      icon: <WarningOffIcon />,
    });
  }

  if (comment.author_id && filterAuthorsId) {
    items.push({
      id: "filter_id",
      onClick: onClickFilterByAuthorId,
      label: t("components:comments:actions:viewAllConversationsFromUser"),
      icon: <UserIcon />,
    });
  }

  return items;
};

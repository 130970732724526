import { formatDateEnUs } from "@components/listen/agentPerformance/helpers";
import { calculateDateRange } from "@components/listen/overview/helpers";
import { IFilters } from "@store/filters/types";

export const REPORT_READY_SELECTOR = "content-loaded";

export const getDateRange = (filters?: IFilters) => {
  let from, to;

  if (filters?.start_time && filters?.end_time) {
    from = formatDateEnUs(filters.start_time);
    to = formatDateEnUs(filters.end_time);
  } else if (filters?.data_range_option) {
    const { fromDate, toDate } = calculateDateRange(filters?.data_range_option);
    from = fromDate;
    to = toDate;
  }

  if (!from || !to) {
    return "";
  }

  return `${from} - ${to}`;
};

import React, { FC } from "react";
import classNames from "classnames/bind";

import s from "./ReplyDateTime.module.less";

import { getReplyDate } from "./PrivateMessageCard.helpers";

import { EyeOffIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IReplyDateTimeProps {
  date?: string;
  author: JSX.Element;
  isHidden?: boolean;
  className?: string;
}

export const ReplyDateTime: FC<IReplyDateTimeProps> = ({ date, author, isHidden, className }) => (
  <div className={classNames(s.bbReplyDateTime, className)}>
    <span className={s.bbReplyDateTimeAuthor} data-testid="author">
      {author}
    </span>
    {!!date && (
      <span className={s.bbReplyDateTimeDate} data-testid="date">
        {getReplyDate(date)}
      </span>
    )}
    {isHidden && (
      <span className={s.bbReplyDateTimeHidden}>
        <EyeOffIcon />
      </span>
    )}
  </div>
);

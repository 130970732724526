export const GET_DOCUMENT = "DOCUMENT/GET_DOCUMENT";
export const GET_DOCUMENT_SUCCESS = "DOCUMENT/GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_FAILURE = "DOCUMENT/GET_DOCUMENT_FAILURE";

export const GET_DOCUMENT_URL = "DOCUMENT/GET_DOCUMENT_URL";
export const GET_DOCUMENT_URL_SUCCESS = "DOCUMENT/GET_DOCUMENT_URL_SUCCESS";
export const GET_DOCUMENT_URL_FAILURE = "DOCUMENT/GET_DOCUMENT_URL_FAILURE";

export const GENERATE_PDF_DOCUMENT = "DOCUMENT/GENERATE_PDF_DOCUMENT";
export const GENERATE_PDF_DOCUMENT_SUCCESS = "DOCUMENT/GENERATE_PDF_DOCUMENT_SUCCESS";
export const GENERATE_PDF_DOCUMENT_FAILURE = "DOCUMENT/GENERATE_PDF_DOCUMENT_FAILURE";

import React from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "antd";
import { Card, SortableTable } from "@bbdevcrew/bb_ui_kit_fe";
import MessageTypeDetailTableRow from "./MessageTypeDetailTableRow";

import { IOverviewMessageTypeDetailItem } from "@store/dashboard/types";
import { getColumns } from "./helpers";

interface IMessageTypeDetailProps {
  loading: boolean;
  data: IOverviewMessageTypeDetailItem[];
}

const MessageTypeDetailTable = ({ loading, data }: IMessageTypeDetailProps) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  return (
    <Card
      headerTitle={t("components:messageTypeDetailTable:headerTitle")}
      headerSubTitle={t("components:messageTypeDetailTable:headerSubTitle")}
    >
      <SortableTable<IOverviewMessageTypeDetailItem>
        data={data}
        loading={loading}
        tableRow={MessageTypeDetailTableRow}
        columns={getColumns(t, screens)}
        emptyTableText={t("generic:emptyTable")}
        pageOutOfText={[
          t("components:sortableTable:pageOutOfPart1"),
          t("components:sortableTable:pageOutOfPart1"),
        ]}
      />
    </Card>
  );
};

export default MessageTypeDetailTable;

import {
  useState,
  Dispatch,
  useEffect,
  useCallback,
  ChangeEvent,
  KeyboardEvent,
  ClipboardEvent,
  SetStateAction,
} from "react";
import { IInputProps } from "@bbdevcrew/bb_ui_kit_fe";

export interface _IInputChipProps extends IInputProps {
  value: string[];
  onChipAdd: (value: string) => void;
  onChipRemove: (value: string, idx: number) => void;
  onClearAll: (values: string[]) => void;
}

// Triggered by key code
export const REMOVE_COMMAND_KEYS = ["Backspace"];
export const ADD_COMMAND_KEYS = ["Comma", ",", "Space", "Enter"];
export const NESTING_COMMAND_KEYS = ["(", ")"];

//Allowed chars for apply chip to add
const ALLOWED_PASTE_CHARS = [",", " "];

export function sanitizeChip(chip: string) {
  return chip.replace(/,/g, "").trim();
}

/**
 * Valid chip:
 * 1. Does not start with "/",
 * 2. Does not have "(",
 * 3. Does not have ")",
 * 4. Does not have spaces
 * 5. Does not have quotes
 */
export function isChipValid(chip: string) {
  return !chip.trim().match(/^\/|\(|\)|\s|\"/) && chip.trim().length > 0;
}

export function isChipPhrase(chip: string) {
  return !!chip.trim().match(/^\"(.+?)?\"$/) && chip.trim().length > 0;
}
export function isChipPhraseEmpty(chip: string) {
  return sanitizeChip(chip).replace(/\"/g, "").length === 0;
}

export const shouldBeClearChipValue = (text: string) => {
  return (
    !![...text].find(char => ALLOWED_PASTE_CHARS.includes(char)) || text.split(",").length === 1
  );
};

// Open but not closed
export function hasOpenPhrase(chip: string) {
  return !!chip.trim().match(/^\"/) && !chip.trim().match(/\"$/);
}

// Handle `onChipRemove` if "Backspace" detected
export function handleKeyDown(
  event: KeyboardEvent<HTMLInputElement>,
  chipValue: string,
  value: string[],
  removeCb: (value: string, idx: number) => void,
) {
  if (REMOVE_COMMAND_KEYS.includes(event.code) && chipValue === "") {
    const lastChip = value[value.length - 1];

    if (lastChip) {
      removeCb(lastChip, value.length - 1);
    }
  }

  if (event.code === "Enter") {
    event.preventDefault();
    return false;
  }
}

export function handleOnChange(
  event: ChangeEvent<HTMLInputElement>,
  setChipValue: Dispatch<SetStateAction<string>>,
) {
  // Ignore ADD_COMMAND_KEYS
  if (ADD_COMMAND_KEYS.includes(event.target.value)) {
    event.stopPropagation();
    return;
  }

  if (hasOpenPhrase(sanitizeChip(event.target.value))) {
    setChipValue(event.target.value);
  } else {
    setChipValue(event.target.value.trim());
  }
}

export function useInputChip({
  value, // all chips
  onKeyUp,
  onChipAdd,
  onChipRemove,
}: Pick<_IInputChipProps, "onKeyUp" | "onChipRemove" | "onChipAdd" | "value">) {
  const [chipValue, setChipValue] = useState("");
  const [clipBoardText, setClipBoardText] = useState("");

  const allowChipAdd = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      // It's a complete phrase, allow add
      if (ADD_COMMAND_KEYS.includes(event.code) && isChipPhrase(sanitizeChip(chipValue))) {
        return true;
      }

      if (
        // Allow chipAdd if: It's ADD_COMMAND_KEY and it's not a phrase (phrases can contain spaces in them)
        ADD_COMMAND_KEYS.includes(event.code) &&
        !hasOpenPhrase(sanitizeChip(chipValue)) &&
        chipValue.trim().length > 0
      ) {
        return true;
      }

      return false;
    },
    // eslint-disable-next-line
    [chipValue, value],
  );

  const _onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (allowChipAdd(event)) {
      onChipAdd(sanitizeChip(chipValue));
      setChipValue("");
    }

    onKeyUp && onKeyUp(event);
  };

  const onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    // eslint-disable-next-line
    // @ts-ignore
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text") || "";
    setClipBoardText(pastedData);
    setChipValue(pastedData);

    /**
     * Example cases
     *   "word,word2,word3" = ["word,word2,word3"]
     *   word1,word2,word3 = ["word","word2","word3"]
     *   "word 1" = ["word 1"]
     *   word1 word2 word3 = ["word1","word2","word3"]
     */
    const regexPattern = /(?:\"([^\"]+)\"|([^,\s]+))/g;
    const matched = pastedData.match(regexPattern) || [];

    matched.forEach(phrase => onChipAdd(phrase.trim()));

    setChipValue("");
  };

  useEffect(() => {
    setChipValue("");
  }, [value.length]);

  // Handles to clear chipValue when onPaste event triggered
  useEffect(() => {
    if (clipBoardText || shouldBeClearChipValue(clipBoardText || chipValue)) {
      setClipBoardText("");
      setChipValue("");
    }
    // eslint-disable-next-line
  }, [clipBoardText]);

  return {
    onPaste,
    chipValue,
    onKeyUp: _onKeyUp,
    onChange: (event: ChangeEvent<HTMLInputElement>) => handleOnChange(event, setChipValue),
    onKeyDown: (event: KeyboardEvent<HTMLInputElement>) =>
      handleKeyDown(event, chipValue, value, onChipRemove),
  };
}

import React, { FC, useContext } from "react";
import classNames from "classnames";

import AppFilters from "@components/_common/AppFilter/AppFilters";
import { FilterContext } from "@components/_common/AppLayout/FilterContext";

import s from "@components/_common/AppLayout/AppLayout.module.less";

export const AppFiltersContainer: FC = () => {
  const filterContext = useContext(FilterContext);

  if (!filterContext || !filterContext.filters) {
    return null;
  }

  const {
    FiltersRef,
    clientData,
    filters,
    hasNewSidenav,
    stickyFilterValue,
    onFilterInternal,
    onResetFilters,
    customFilters,
    onSavedStickyChange,
    onSavedFilterChange,
    setStickyFilterValue,
    isBatchModeActive,
  } = filterContext;

  return (
    <AppFilters
      FiltersRef={FiltersRef}
      clientData={clientData}
      initialFilterData={filters}
      hasNewSidenav={hasNewSidenav}
      stickyFilterValue={stickyFilterValue}
      onFilter={onFilterInternal}
      onResetFilters={onResetFilters}
      customFilters={customFilters}
      onSavedStickyChange={onSavedStickyChange}
      onSavedFilterChange={onSavedFilterChange}
      setStickyFilterValue={setStickyFilterValue}
      className={classNames({
        [s.bbAppFiltersContainerDisabled]: isBatchModeActive,
      })}
    />
  );
};

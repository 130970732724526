import { createReducer, ActionType } from "typesafe-actions";
import produce from "immer";

import * as actions from "./actions";

import { IAvailableSectionsType, IModulesState } from "./types";

const initialState: IModulesState = {
  modules: {},

  fetchingModuleSectionsList: false,
  fetchedModuleSectionsList: false,
  fetchedModuleSectionsListFail: false,
};

export const modulesReducer = createReducer<IModulesState, ActionType<typeof actions>>(initialState)
  .handleAction(actions.getModuleSectionsListAction, state =>
    produce(state, draft => {
      draft.fetchingModuleSectionsList = true;
      draft.fetchedModuleSectionsList = false;
      draft.fetchedModuleSectionsListFail = false;
    }),
  )
  .handleAction(actions.getModuleSectionsListSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.modules[action.payload.id as IAvailableSectionsType] = action.payload.items;
      draft.fetchingModuleSectionsList = false;
      draft.fetchedModuleSectionsList = true;
      draft.fetchedModuleSectionsListFail = false;
    }),
  )
  .handleAction(actions.getModuleSectionsListFailureAction, (state, action) =>
    produce(state, draft => {
      draft.modules[action.payload.id as IAvailableSectionsType] = [];
      draft.fetchingModuleSectionsList = false;
      draft.fetchedModuleSectionsList = false;
      draft.fetchedModuleSectionsListFail = true;
    }),
  );

import React from "react";
import { useTranslation } from "react-i18next";

import { InformationIcon, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import PerformanceCard from "../../../_common/performanceCard";

import { IEngagementsProps } from "./EngagementCard.type";

import s from "./EngagementCard.module.less";

const EngagementTrends: React.FC<IEngagementsProps> = props => {
  const { t } = useTranslation();
  const { engagement, type, icon, isLoading, onClick } = props;

  const getEngagementTitle = () => {
    return (
      <React.Fragment>
        {t(`components:engagementsTrends:${type}:title`)}
        <Tooltip title={t(`components:engagementsTrends:${type}:tooltip`)}>
          <span className={s.bbEngagementCardTooltipIcon}>
            <InformationIcon />
          </span>
        </Tooltip>
      </React.Fragment>
    );
  };

  return (
    <div className={s.bbEngagementTrendWrapper}>
      <PerformanceCard
        size="sm"
        isLoading={isLoading}
        onClick={onClick}
        type={type}
        title={getEngagementTitle()}
        icon={icon}
        data={engagement}
      />
    </div>
  );
};

export default EngagementTrends;

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useEffect, useState, useCallback } from "react";

import { Input, SelectStyled } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Assets.module.less";

import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import { useSingleSelectState } from "../common";
import { getTransformedAssetOptionsSingle } from "./helpers";
import { ISingleAssetFilterProps, IAutocompleteAsset } from "./Assets.type";
import { debounce, useSearch, IObjectOption } from "@bbdevcrew/bb_ui_kit_fe";
import { IGetAutocompleteOptionsPayload } from "@store/autocomplete/types";

const AssetsFilterSingle: FC<ISingleAssetFilterProps> = ({
  value,
  onChange,
  disabled,
  placeholder,
  customprops,
  fetchFilters,
  dropdownPlacement,
  getAutoCompleteOptions,
  dropdownMatchSelectWidth,
  showDefaultPlaceholder,
  InputVariation = Input,
  TriggerVariation,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [options, setOptions] = useState<IObjectOption[]>([]);

  const {
    options: searchedOptions,
    SearchInput,
    searchTerm,
    setSearchTerm,
  } = useSearch(options, t("generic:search"));
  const { selectValue, _onChange } = useSingleSelectState(customprops, value, onChange);

  const autocompleteOptions = useSelector(autocompleteOptionsSelector);

  // eslint-disable-next-line
  const getAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(getAutocompleteOptionsAction(payload));
    }, 300),
    [dispatch],
  );

  const fetchValues = (term: string) => {
    getAutocompleteOptions({
      field: "asset",
      query: term,
      filter: fetchFilters,
    });
  };

  useEffect(() => {
    fetchValues(searchTerm);
    // eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    const transformedOptions = getTransformedAssetOptionsSingle(
      autocompleteOptions.asset,
      props?.additionalLabelFields,
      selectValue,
    );

    setOptions(transformedOptions as IObjectOption[]);
    getAutoCompleteOptions?.(autocompleteOptions.asset as IAutocompleteAsset[]);
    // eslint-disable-next-line
  }, [autocompleteOptions.asset, selectValue]);

  const onInternalChange = (changedValue: string) => {
    _onChange(changedValue);
  };

  return (
    <div data-cy="app-filter-asset_ids" data-stonly-target="app-global__filters--asset_ids">
      <SelectStyled
        _size="sm"
        id="asset_ids"
        disabled={disabled}
        value={selectValue}
        options={searchedOptions}
        placeholder={placeholder}
        highlightTerm={searchTerm}
        onChange={onInternalChange}
        DropdownHeader={SearchInput}
        InputVariation={InputVariation}
        TriggerVariation={TriggerVariation}
        dropdownPlacement={dropdownPlacement}
        onDropdownClose={() => setSearchTerm("")}
        showDefaultPlaceholder={showDefaultPlaceholder}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        data-cy={props["data-cy" as keyof typeof props]}
        className={s.bbAssetsSingleSelect}
      />
    </div>
  );
};

export default AssetsFilterSingle;

import React, { HTMLAttributes, useState } from "react";
import classNames from "classnames";

import { SimpleDropdown } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./DropdownMenu.module.less";

import { IDropdownMenuProps } from "./DropdownMenu.types";

export const DropdownMenu: React.FC<IDropdownMenuProps> = ({
  trigger: TriggerComponent,
  className,
  children,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <SimpleDropdown open={open} setOpen={setOpen} trigger={<TriggerComponent open={open} />}>
      <div className={classNames(s.bbDropdownMenu, className)}>
        {children(() => setOpen(false))}
      </div>
    </SimpleDropdown>
  );
};

export const DropdownMenuItem: React.FC<HTMLAttributes<HTMLButtonElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <button className={classNames(s.bbDropdownMenuItem, className)} {...props}>
      {children}
    </button>
  );
};

export const DropdownMenuDivider = () => {
  return <hr className={s.bbDropdownMenuDivider} />;
};

import cn from "classnames/bind";
import React, { FC } from "react";

import CommentCard from "../CommentCard/CommentCard";
import CommentPostPreview from "./CommentPostPreview";
import BatchModeCommentCard from "../CommentCard/batchModeCommentCard/BatchModeCommentCard";

import s from "./CommentsGrid.module.less";

import { ICommentsGridItemProps } from "./types";
import { getCommentGridSize } from "@utils/commentCard";
import { IModerationStreamComment } from "@store/streams/types";
import { useBatchMode } from "@containers/BatchMode/BatchMode";
import { PostPlacementEnum } from "../Social/Post/Post.type";

const cx = cn.bind(s);

export const COMMENT_MAX_LENGTH = 550;

const CommentsGridItem: FC<ICommentsGridItemProps> = React.memo(
  ({ comment, commentIndex, withPostPreview, withFooter, onReplyClick, onTranslateClick }) => {
    const { isBatchModeActive } = useBatchMode();
    const showPostPreview = withPostPreview && comment.post_placement !== PostPlacementEnum.REVIEW;

    return (
      <div
        key={`comment-grid-item-${comment.id}`}
        className={cx({
          [s.bbCommentsGridItem]: !isBatchModeActive,
          [getCommentGridSize(comment)]: !isBatchModeActive,
          "h-auto": isBatchModeActive,
          [s.bbCommentsGridItemWithPostPreview]: showPostPreview,
        })}
      >
        {isBatchModeActive ? (
          <BatchModeCommentCard
            comment={comment}
            commentIndex={commentIndex}
            maxLengthComment={COMMENT_MAX_LENGTH}
            withFooter={withFooter}
            onReplyClick={onReplyClick}
            onTranslateClick={onTranslateClick}
          />
        ) : (
          <>
            <CommentCard
              comment={comment}
              commentIndex={commentIndex}
              maxLengthComment={COMMENT_MAX_LENGTH}
              withFooter={withFooter}
              onReplyClick={onReplyClick}
              onTranslateClick={onTranslateClick}
            />
            {!!showPostPreview && (
              <CommentPostPreview comment={comment as IModerationStreamComment} />
            )}
          </>
        )}
      </div>
    );
  },
);

CommentsGridItem.displayName = "CommentsGridItem";

export default CommentsGridItem;

import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  handleMultiselectState,
  Input,
  SelectStyledMulti,
  useSearch,
} from "@bbdevcrew/bb_ui_kit_fe";
import ClearableTrigger from "@components/_common/SelectTrigger/ClearableTrigger";

import {
  getPredefinedFiltersFailedSelector,
  getPredefinedFiltersPendingSelector,
  getPredefinedFiltersSuccessfulSelector,
  predefinedFiltersSelector,
} from "@store/savedFilters/selectors";

import { getPredefinedFiltersAction } from "@store/savedFilters/actions";

import s from "./Assets.module.less";

import { IMultiAssetsFilterProps } from "./Assets.type";
import { IClearableTriggerProps } from "@components/_common/SelectTrigger/ClearableTrigger.types";

const AssetsGroups: FC<IMultiAssetsFilterProps> = ({
  value = [],
  placeholder,
  dropdownPlacement,
  onChange,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const predefinedFilters = useSelector(predefinedFiltersSelector);
  const predefinedFiltersFetched = useSelector(getPredefinedFiltersSuccessfulSelector);
  const fetchingPredefinedFilters = useSelector(getPredefinedFiltersPendingSelector);
  const predefinedFiltersFetchFailed = useSelector(getPredefinedFiltersFailedSelector);

  const options =
    predefinedFilters
      ?.find(item => item.id === "stickyFilters")
      ?.items?.map(filterItem => ({
        id: filterItem.id?.toString(),
        label: filterItem.name,
      })) || [];

  const { options: searchedOptions, SearchInput } = useSearch(options, t("generic:search"));

  const getPredefinedFilters = useCallback(
    () => dispatch(getPredefinedFiltersAction()),
    [dispatch],
  );

  useEffect(() => {
    if (
      !predefinedFilters.length &&
      !predefinedFiltersFetched &&
      !fetchingPredefinedFilters &&
      !predefinedFiltersFetchFailed
    )
      getPredefinedFilters();
    // eslint-disable-next-line
  }, []);

  const onFormFieldChange = (values: string[]) => {
    onChange?.(values);
  };

  const onInternalChange = (changedValue: string) => {
    const newValue = handleMultiselectState(searchedOptions, value, changedValue);

    onFormFieldChange(newValue);
  };

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    if (!value.length) {
      return ClearableTrigger(tooltipProps);
    }
    const tooltipElement = options
      .filter(opt => value.includes(opt.id))
      .map(group => (
        <span className={s.bbDisplayBlock} key={group.id}>
          {group.label}
        </span>
      ));

    return ClearableTrigger({
      tooltip: tooltipElement,
      onClear: () => onFormFieldChange([]),
      ...tooltipProps,
    });
  };

  return (
    <div data-cy="asset-groups-filter-select">
      <SelectStyledMulti
        _size="sm"
        id="asset_group_ids"
        value={value}
        options={searchedOptions}
        placeholder={placeholder}
        onChange={onInternalChange}
        DropdownHeader={SearchInput}
        InputVariation={Input}
        TriggerVariation={TriggerWithTooltip}
        dropdownPlacement={dropdownPlacement}
        dropdownMatchSelectWidth={true}
      />
    </div>
  );
};

export default AssetsGroups;

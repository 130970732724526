import React from "react";
import classNames from "classnames";

import s from "./ColorDot.module.less";

import { IColorDotProps } from "./ColorDot.types";

export const ColorDot: React.FC<IColorDotProps> = ({ color }) => {
  return (
    <i
      className={classNames(s.bbColorDot, { [s.bbColorDotAll]: color === "all" })}
      style={{ backgroundColor: color !== "all" ? color : "currentColor" }}
    ></i>
  );
};

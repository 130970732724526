import { RootState } from "..";

export const fromFilters = (state: RootState) => state.filters;

export const filtersOpenSelector = (state: RootState) => fromFilters(state).filtersOpen;
export const filtersPanelWidthSelector = (state: RootState) => fromFilters(state).filtersPanelWidth;

export const isFilteringWithoutUrlSelector = (state: RootState) =>
  fromFilters(state).isFilteringWithoutUrl;
export const filterDataWithoutUrlSelector = (state: RootState) =>
  fromFilters(state).filterDataWithoutUrl;

export const filterDataSelector = (state: RootState) => fromFilters(state).filterData;

export const savedFilterValueSelector = (state: RootState) => fromFilters(state).savedFilterValue;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { EyeOffIcon } from "@bbdevcrew/bb_ui_kit_fe";

import SectionDivider from "@components/insights/overview/OverviewSectionDivider";
import { CommentsByTagCard } from "@components/insights/overview/externalAppsOverview/commentsByTagChart/CommentsByTagChart"; // eslint-disable-line max-len
import CommentsByPlatformWidget from "@components/insights/overview/externalAppsOverview/commentsByPlatformWidget"; // eslint-disable-line max-len

import { meSelector } from "@store/me/selectors";

import { normalizeSafetyTagsStatsData } from "@components/insights/overview/externalAppsOverview/helpers"; // eslint-disable-line max-len

import reportStyles from "./../PDFReport.module.less";
import s from "./BBProtect.module.less";

import { SafetyIcon } from "@assets/index";

import { IOverviewPerformanceTag } from "@store/dashboard/types";
import { IBBProtectProps } from "./BBProtect.types";

export const BBProtect: React.FC<IBBProtectProps> = ({ data, isFetching }) => {
  const { t } = useTranslation();

  const [tagsStatsDataProtect, setTagsStatsDataProtect] = useState<IOverviewPerformanceTag[]>([]);

  const me = useSelector(meSelector);

  useEffect(() => {
    if (data) {
      setTagsStatsDataProtect(normalizeSafetyTagsStatsData(data.tags_hidden_stats.items));
    }
  }, [data]);

  return (
    <div className={reportStyles.bbPDFReportSection}>
      <div className={s.BBProtect}>
        <div className={s.bbProtectHeader}>
          <SectionDivider
            icon={<SafetyIcon />}
            title={t("components:managed:protect:title")}
            description={t("components:managed:protect:description", { name: me?.client?.name })}
          />
        </div>
        <div className={s.bbProtectContent}>
          <div className={s.bbProtectContentByPlatform}>
            <CommentsByPlatformWidget
              data={data?.safety_hidden_stats.items || []}
              loading={isFetching}
              headerIcon={<EyeOffIcon />}
              headerIconType="danger"
              title={t("components:overviewSafety:commentsHidden:title")}
              subTitle={t("components:overviewSafety:commentsHidden:subTitle")}
            />
          </div>
          <div>
            <CommentsByTagCard
              title={t("components:overviewSafety:hiddenByTag:title")}
              subTitle={t("components:overviewSafety:hiddenByTag:subTitle", {
                name: me?.client?.name,
              })}
              loading={isFetching}
              data={tagsStatsDataProtect}
              legendItems={[]}
              width={735}
              showCalculatedPercentage={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

import { useMemo } from "react";
import { useSelector } from "react-redux";

import { predefinedFiltersSelector } from "@store/savedFilters/selectors";

export const useSavedFiltersList = () => {
  const predefinedFilters = useSelector(predefinedFiltersSelector);

  return useMemo(() => {
    return predefinedFilters.filter(({ id }) => id === "savedFilters")[0]?.items || [];
  }, [predefinedFilters]);
};

type ColorType = [number, number, number];

export const defaultInitialValues = { color: "#ABC8FA", access_scope: "everyone" };

export const filterAccessOptions = ["everyone", "only_me"];

export const defaultPresets: ColorType[] = [
  [74, 144, 226],
  [80, 227, 194],
  [184, 233, 134],
  [248, 231, 28],
  [245, 166, 35],
  [255, 109, 106],
  [189, 16, 224],
  [144, 19, 254],
  [216, 216, 216],
  [0, 0, 0],
];

import { switchMap, mergeMap, map, filter, catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isActionOf } from "typesafe-actions";
import { StateObservable } from "redux-observable";

import * as actions from "./actions";
import { RootState } from "..";
import {
  ListenProjectsActions,
  IListenProjectsResponse,
  IListenProjectCommentsResponse,
} from "./types";

import { listen } from "@utils/paths";

import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";
import { postListenProjectCommentsSuccessAction } from "@store/comments/actions";

// TODO: deprecated, remove after a while
export const getListenProjects = (
  action$: Observable<ListenProjectsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getListenProjectsAction)),
    switchMap(() => {
      return ajax
        .get<IListenProjectsResponse>(
          `${listen}/projects`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.getListenProjectsSuccessAction(data)),
          catchError(e => handleError(e, actions.getListenProjectsFailureAction)),
        );
    }),
  );

export const getListenProjectComments = (
  action$: Observable<ListenProjectsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getListenProjectCommentsAction)),
    mergeMap(a => {
      return ajax
        .post<IListenProjectCommentsResponse>(
          `${listen}/projects/${a.payload.id}/sections/${a.payload.section_id}/search`,
          { filters: a.payload.filters },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data =>
            postListenProjectCommentsSuccessAction(
              data.items,
              data.cursor,
              data.navigation_id,
              data.info,
            ),
          ),
          catchError(e => handleError(e, actions.getListenProjectCommentsFailureAction)),
        );
    }),
  );

import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { StateObservable } from "redux-observable";
import { catchError, filter, map, retry, switchMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";

import { RootState } from "../";
import { DocumentActions, IDocumentPayload, IDocumentUrlPayload, IPDFReportState } from "./types";
import { document, documentUrl, generatePdf } from "@utils/paths";
import * as actions from "./actions";
import { handleError } from "@utils/apiErrorHandler";
import { getHeaders } from "@utils/headers";

const PDF_STATUS_CHECK_INTERVAL = 5000;
const PDF_STATUS_CHECK_RETRIES = 10;

export const getDocument = (
  action$: Observable<DocumentActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getDocumentsAction)),
    switchMap(() =>
      ajax
        .get<IDocumentPayload["items"]>(
          document,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.getDocumentsSuccess(data)),
          catchError(err => handleError(err, actions.getDocumentsFailure)),
        ),
    ),
  );

export const getDocumentUrl = (
  action$: Observable<DocumentActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getDocumentsUrlAction)),
    switchMap(action =>
      ajax
        .get<IDocumentUrlPayload>(
          documentUrl(action.payload),
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.getDocumentsUrlSuccess(data)),
          catchError(err => handleError(err, actions.getDocumentsUrlFailure)),
        ),
    ),
  );

export const generatePDFDocument = (
  action$: Observable<DocumentActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.generatePDFDocumentAction)),
    switchMap(action =>
      ajax
        .post<{ id: string }>(
          generatePdf,
          action.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          switchMap(response => {
            const checkStatusUrl = documentUrl(response.response.id);
            return ajax
              .get<IPDFReportState>(
                checkStatusUrl,
                getHeaders({
                  Authorization: state$.value.auth.session.accessToken.jwtToken,
                }),
              )
              .pipe(
                retry({
                  count: PDF_STATUS_CHECK_RETRIES,
                  delay: PDF_STATUS_CHECK_INTERVAL,
                  resetOnSuccess: true,
                }),
                map(e => e.response),
                map(data =>
                  actions.generatePDFDocumentSuccess({
                    ...data,
                    search_id: action.payload.search_id,
                  }),
                ),
              );
          }),
          catchError(err =>
            handleError(err, () => actions.generatePDFDocumentFailure(action.payload.search_id)),
          ),
        ),
    ),
  );

import produce from "immer";
import { createReducer, ActionType } from "typesafe-actions";

import { IAutocompleteOptionsState } from "./types";
import {
  GET_AUTOCOMPLETE_OPTIONS,
  GET_AUTOCOMPLETE_OPTIONS_SUCCESS,
  CLEAR_AUTOCOMPLETE_OPTIONS,
  UPDATE_AUTOCOMPLETE_OPTIONS,
} from "./actionTypes";
import { clearAutocompleteOptionsAction, getAutocompleteOptionsSuccessAction } from "./actions";

const initialState = {
  options: {
    ads: [],
    post: [],
    asset: [],
    adset: [],
    author: [],
    language: [],
    campaign: [],
    ads_targeting: [],
    post_tags: [],
    facebook_page: [],
    listen_source: [],
    assets_for_replier_asset: [],
  },

  fetchingAutocompleteOptions: false,
  fetchedAutocompleteOptions: false,
  fetchedAutocompleteOptionsFail: false,
};

const setFetchingAutocompleteOptions = produce((draft: IAutocompleteOptionsState) => {
  draft.fetchingAutocompleteOptions = true;
  draft.fetchedAutocompleteOptions = false;
  draft.fetchedAutocompleteOptionsFail = false;
});

export const autocompleteOptionsReducer = createReducer<IAutocompleteOptionsState>(initialState, {
  [GET_AUTOCOMPLETE_OPTIONS]: setFetchingAutocompleteOptions,
  [UPDATE_AUTOCOMPLETE_OPTIONS]: setFetchingAutocompleteOptions,
  [GET_AUTOCOMPLETE_OPTIONS_SUCCESS]: produce(
    (draft, action: ActionType<typeof getAutocompleteOptionsSuccessAction>) => {
      const autoCompleteField = action.payload.key;
      draft.options[autoCompleteField] = action.payload.data;
      draft.fetchingAutocompleteOptions = false;
      draft.fetchedAutocompleteOptions = true;
      draft.fetchedAutocompleteOptionsFail = false;
    },
  ),
  [CLEAR_AUTOCOMPLETE_OPTIONS]: produce(
    (draft, action: ActionType<typeof clearAutocompleteOptionsAction>) => {
      const autoCompleteField = action.payload;
      draft.options[autoCompleteField] = [];
      draft.fetchingAutocompleteOptions = false;
      draft.fetchedAutocompleteOptions = false;
      draft.fetchedAutocompleteOptionsFail = false;
    },
  ),
});

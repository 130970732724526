import { useTranslation } from "react-i18next";
import React, { FC } from "react";

import { Typography } from "antd";
import { Modal } from "@bbdevcrew/bb_ui_kit_fe";

import { IDeleteFilterModalProps } from "./types";

const DeleteFilterModal: FC<IDeleteFilterModalProps> = ({
  deleteFilterItem,
  onDeleteFilter,
  onDeleteFilterCancel,
}) => {
  const { t } = useTranslation();

  const onDeleteSavedFilterConfirm = () => {
    onDeleteFilter(deleteFilterItem?.id?.toString() || "");
  };

  return (
    <Modal
      open
      theme="light"
      centered
      onCancel={onDeleteFilterCancel}
      onOk={onDeleteSavedFilterConfirm}
      okButtonProps={{ _type: "danger" }}
      cancelButtonProps={{ _type: "danger-subtle" }}
      title={t("components:savedMonitorsPanel:modals:deleteSavedMonitor:title")}
    >
      <Typography.Paragraph>
        {t("components:savedMonitorsPanel:modals:deleteSavedMonitor:text", {
          deleteName: deleteFilterItem?.name || "",
        })}
      </Typography.Paragraph>
    </Modal>
  );
};

export default DeleteFilterModal;

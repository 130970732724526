import { RootState } from "..";

export const fromModules = (state: RootState) => state.modules;

export const getAllModulesSectionsSelector = (state: RootState) => fromModules(state).modules;
export const getListenModuleSectionsSelector = (state: RootState) =>
  fromModules(state).modules.listen;

export const getModuleSectionsPendingSelector = (state: RootState) =>
  fromModules(state).fetchingModuleSectionsList;
export const getModuleSectionsSelectorSuccessful = (state: RootState) =>
  fromModules(state).fetchedModuleSectionsList;
export const getModuleSectionsSelectorFailure = (state: RootState) =>
  fromModules(state).fetchedModuleSectionsListFail;

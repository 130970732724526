import React, { FC } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";

import { Empty } from "antd";
import { MessageListPrivate } from "./MessageListPrivate";
import { MessageListPublic } from "./MessageListPublic";

import s from "./MessageList.module.less";

import { IMessageListMainProps } from "./MessageList.type";
import { isPrivateConversation } from "@utils/comments";
import { IComment } from "@store/comments/types";
import { IReplyAttachment } from "@store/replies/types";

export const MessageList: FC<IMessageListMainProps> = ({
  filters,
  onReply,
  isLoading,
  replyData,
  onBottomReached,
  isReplyBtnLoading,
  onClose,
  comments,
}) => {
  const { t } = useTranslation();
  const parent = replyData?.parent;

  const isPrivateMessage = !!replyData && isPrivateConversation(replyData.post_placement);

  const onInternalReply = (
    userTag: string,
    message: string,
    replyingCommentId: string,
    comment?: IComment,
    attachment?: IReplyAttachment,
    replyingAssetId?: string,
    isPrivateReply?: boolean,
  ) => {
    if (!!onReply) {
      onReply(
        replyingCommentId,
        userTag,
        message,
        !!comment?.reply.is_ignored,
        attachment,
        replyingAssetId,
        isPrivateReply,
      );
    }
  };

  const isReplyingComment = (commentId: string) =>
    !!(replyData && commentId === replyData.replying_comment_id);

  return (
    <div
      className={classNames(s.bbMessageList, {
        [s.bbMessageListPrivateContainer]: isPrivateMessage,
        [s.bbMessageListPublicContainer]: !isPrivateMessage,
      })}
      data-testid="message-list"
    >
      {!replyData && !isLoading && (
        <Empty
          data-testId="empty-message-list"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>{t("components:comments:tableEmpty")}</span>}
        />
      )}

      {!!replyData && (
        <>
          {!isPrivateMessage ? (
            <MessageListPublic
              filters={filters}
              replyData={replyData}
              onInternalReply={onInternalReply}
              onBottomReached={onBottomReached}
              isReplyingComment={isReplyingComment}
              isReplyBtnLoading={isReplyBtnLoading}
              onClose={onClose}
              comments={comments}
            />
          ) : parent ? (
            <MessageListPrivate
              parent={parent}
              replyData={replyData}
              onInternalReply={onInternalReply}
              isReplyBtnLoading={isReplyBtnLoading}
              comments={comments}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

import { createAction } from "typesafe-actions";

import {
  ENGAGED_COMMENTS,
  ENGAGED_COMMENTS_FAILURE,
  ENGAGED_COMMENTS_SUCCESS,
  PROTECT_COMMENTS,
  PROTECT_COMMENTS_FAILURE,
  PROTECT_COMMENTS_SUCCESS,
} from "./actionTypes";
import { IGetManagedCommentsPayload } from "./types";
import { IComment } from "@store/comments/types";

export const proctectCommentsAction = createAction(PROTECT_COMMENTS)<IGetManagedCommentsPayload>();
export const proctectCommentsSuccessAction = createAction(PROTECT_COMMENTS_SUCCESS)<IComment[]>();
export const proctectCommentsFailureAction = createAction(PROTECT_COMMENTS_FAILURE)<void>();

export const engagedCommentsAction = createAction(ENGAGED_COMMENTS)<IGetManagedCommentsPayload>();
export const engagedCommentsSuccessAction = createAction(ENGAGED_COMMENTS_SUCCESS)<IComment[]>();
export const engagedCommentsFailureAction = createAction(ENGAGED_COMMENTS_FAILURE)<void>();

export interface IVideoMetadata {
  duration: number;
  width: number;
  height: number;
}

const cache = new Map<string, IVideoMetadata>();

const loadVideoMetadata = (url: string): Promise<IVideoMetadata> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.preload = "metadata";

    /* eslint-disable @typescript-eslint/no-use-before-define */
    const onLoadedMetadata = () => {
      const metadata: IVideoMetadata = {
        duration: video.duration,
        width: video.videoWidth,
        height: video.videoHeight,
      };
      resolve(metadata);
      cleanUp();
    };

    const onError = () => {
      reject(new Error(`Failed to load video metadata for URL: ${url}`));
      cleanUp();
    };

    /* eslint-enable @typescript-eslint/no-use-before-define */
    const cleanUp = () => {
      video.removeEventListener("loadedmetadata", onLoadedMetadata);
      video.removeEventListener("error", onError);
      video.src = "";
      video.remove();
    };

    video.addEventListener("loadedmetadata", onLoadedMetadata);
    video.addEventListener("error", onError);

    video.src = url;
  });
};

export const fetchVideoMetadata = async (url: string): Promise<IVideoMetadata> => {
  const cachedMetadata = cache.get(url);
  if (cachedMetadata) {
    return cachedMetadata;
  }

  const metadata = await loadVideoMetadata(url);
  cache.set(url, metadata);
  return metadata;
};

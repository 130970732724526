import React from "react";

import { Avatar } from "antd";

import { IExtraMenuItem } from "./AppHeader.type";

import s from "./ExtraMenu.module.less";

import {
  UserIcon,
  AssetsIcon,
  LogOutIcon,
  CompanyIcon,
  BookOpenIcon,
  WorkflowIcon,
} from "@assets/index";
import { UserGroupIcon } from "@bbdevcrew/bb_ui_kit_fe";

/**
 * Current implementation of UIKit does not allow dynamic inline svg imports.
 * This makes MenuIcon context-aware, which is not good.
 * TODO: Remove mapping once svg-icon system is in place.
 */
const MenuIcon = {
  profile: <UserIcon />,
  signout: <LogOutIcon />,
  company: <CompanyIcon />,
  workflows: <WorkflowIcon />,
  "knowledge-base": <BookOpenIcon />,
  "asset-management": <AssetsIcon />,
  "user-management": <UserGroupIcon />,
};

export const Divider = ({ id }: { id: string }) => (
  <li key={`${id}-divider`} className={s.bbExtraMenuItemDivider} />
);

const ExtraMenuItem = ({ item }: IExtraMenuItem) => {
  return (
    <>
      <div className={s.bbMenuItemIcon}>
        {item.iconUrl ? (
          <Avatar size={32} src={item.iconUrl} className={s.bbUserAvatar} />
        ) : (
          MenuIcon[item.id as keyof typeof MenuIcon]
        )}
      </div>
      <div className={s.bbMenuLabelWrapper}>
        <div className={s.bbMenuLabel}>{item.label}</div>
        {item.subLabel ? <a className={s.bbSubLink}>{item.subLabel}</a> : null}
      </div>
    </>
  );
};

export default ExtraMenuItem;

import React from "react";
import { useTranslation } from "react-i18next";

import SentimentsTrend from "@components/insights/overview/sentimentsCards/sentimentCard/SentimentsCard"; // eslint-disable-line max-len
import SentimentPieChart from "@components/insights/overview/sentimentPieChart/SentimentPieChart";
import PlatformsPieChart from "@components/insights/overview/platformsPieChart/PlatformsPieChart";
// import MostCommentedPosts from "@components/insights/overview/mostCommentedPosts/MostCommentedPosts"; // eslint-disable-line max-len

import reportStyles from "./../PDFReport.module.less";
import s from "./SentimentPreview.module.less";

import {
  IPerformanceUnit,
  PerformanceCardType,
} from "@components/insights/_common/performanceCard/types";
import { ISentimentPreviewProps } from "./SentimentPreview.types";

export const SentimentPreview: React.FC<ISentimentPreviewProps> = ({
  overviewSentiments,
  overviewPlatforms,
  isFetching,
  filters,
}) => {
  const { t } = useTranslation();

  const getSentiment = (type: string) =>
    overviewSentiments.find(({ id }) => id === type) as IPerformanceUnit;

  const sentimentTypes: PerformanceCardType[] = ["total", "positive", "negative", "neutral"];

  return (
    <div className={reportStyles.bbPDFReportSection}>
      <h2 className={reportStyles.bbPDFReportSectionTitle}>
        {t(`components:managed:shareReportButton:sections:sentiment_preview`)}
      </h2>
      <div className={s.bbSentimentPreviewTrends}>
        {sentimentTypes.map(type => (
          <div key={type}>
            <SentimentsTrend
              type={type}
              isLoading={isFetching}
              data={getSentiment(type)}
              fullWidth
              className={s.bbSentimentPreviewTrendsItem}
            />
          </div>
        ))}
      </div>
      {/** <div className={s.bbSentimentPreviewMostCommented}>
        <MostCommentedPosts filters={filters} containerWidth={1200} />
      </div> */}
      <div className={s.bbSentimentPreviewBreakdown}>
        <SentimentPieChart
          loading={isFetching}
          data={overviewSentiments}
          filterData={filters}
          className={s.bbSentimentPreviewBreakdownItem}
        />
        <PlatformsPieChart
          loading={isFetching}
          data={overviewPlatforms}
          filterData={filters}
          className={s.bbSentimentPreviewBreakdownItem}
        />
      </div>
    </div>
  );
};

import i18next from "i18next";
import { interceptors } from "./interceptor";
import { addToast } from "@bbdevcrew/bb_ui_kit_fe";

export interface IFetchError {
  error: string;
  message: string;
  statusCode: number;
}

export const fetchData = <T>(
  path: string,
  method: "GET" | "POST" | "DELETE",
  authToken: string,
  data?: unknown,
): Promise<T> => {
  return fetch(`${process.env.BASE_URL_API}/${path}`, {
    method,
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  })
    .then(response => {
      // API does not return anything on `DELETE` success
      if (method === "DELETE") {
        return Promise.resolve({}) as Promise<T>;
      }

      return response.json() as Promise<T>;
    })
    .then(errorData => {
      // eslint-disable-next-line
      // @ts-ignore
      if ("statusCode" in errorData) {
        throw errorData;
      }

      return errorData;
    })
    .catch((apiError: IFetchError) => {
      const { message, statusCode } = apiError;

      // Do not show "overwrite" errors in `Notification` component
      if (statusCode !== 409) {
        interceptors(statusCode);
        addToast({
          type: "danger_accent",
          title: i18next.t("generic:error"),
          message: message,
        });
      }

      // Rethrow for consumers
      throw apiError;
    });
};

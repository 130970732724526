import React, { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import SentimentPreview from "./SentimentPreview";
import ActionSummary from "./ActionSummary";
import BBProtect from "./BBProtect";
import BBEngage from "./BBEngage";

import {
  getDashboardHiddenCommentsSelector,
  getDashboardManagedSummarySelector,
  overviewPlatformsSelector,
  overviewSentimentSelector,
} from "@store/dashboard/selectors";
import { meSelector } from "@store/me/selectors";
import { getShortenedSearchesAction } from "@store/shortenedSearches/actions";
import {
  getShortenedSearchesResponseSelector,
  getShortenedSearchesSuccessfulSelector,
} from "@store/shortenedSearches/selectors";

import { useDashboardData } from "@utils/useDashboardData";
import { getDateRange, REPORT_READY_SELECTOR } from "./PDFReport.helpers";

import s from "./PDFReport.module.less";

import { OverviewComponentType } from "@store/dashboard/types";

export const PDFReport: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filters = useSelector(getShortenedSearchesResponseSelector);
  const overviewSentiments = useSelector(overviewSentimentSelector);
  const overviewPlatforms = useSelector(overviewPlatformsSelector);
  const dashboardSummaryData = useSelector(getDashboardManagedSummarySelector);
  const hiddenComments = useSelector(getDashboardHiddenCommentsSelector);
  const me = useSelector(meSelector);
  const fetchedShortenedSearches = useSelector(getShortenedSearchesSuccessfulSelector);

  const components = useMemo<OverviewComponentType[]>(
    () => [
      "explorer_overview_sentiment",
      "explorer_overview_platform",
      "action_summary_stats",
      "hidden_comments",
      "hidden_breakdown",
      "explorer_overview_care",
      "hidden_timeline",
    ],
    [],
  );
  const { data, isFetching } = useDashboardData(components, filters);

  useEffect(() => {
    document.body.classList.add(s.bbPDFReportBody);
    if (!isFetching && data && fetchedShortenedSearches) {
      setTimeout(() => {
        document.body.classList.add(REPORT_READY_SELECTOR);
      }, 1000);
    }
  }, [isFetching, data, fetchedShortenedSearches]);

  const name = searchParams.get("name");
  const sections = (searchParams.get("sections") || "")
    .split(",")
    .map(section => section.toLowerCase().trim());

  const id = searchParams.get("id");

  useEffect(() => {
    if (id) {
      dispatch(getShortenedSearchesAction(id));
    }
  }, [id, dispatch]);

  const sectionEnabled = (sectionId: string) => sections.includes(sectionId);

  const dateRange = getDateRange(filters);

  return (
    <div className={s.bbPDFReport}>
      <div className={s.bbPDFReportHeader}>
        <h2 className={s.bbPDFReportHeaderTitle}>
          {t("components:managed:report:title", { name })}
        </h2>
        <div className={s.bbPDFReportHeaderDateRange}>{dateRange}</div>
        <div className={s.bbPDFReportHeaderLogo}>
          <img src={me?.client?.logo} />
        </div>
      </div>
      {sectionEnabled("sentiment_preview") && (
        <SentimentPreview
          overviewSentiments={overviewSentiments || []}
          overviewPlatforms={overviewPlatforms || []}
          isFetching={isFetching}
          filters={filters || {}}
        />
      )}
      {sectionEnabled("action_summary") && (
        <ActionSummary data={dashboardSummaryData || []} isFetching={isFetching} />
      )}
      {sectionEnabled("bb_protect") && <BBProtect data={hiddenComments} isFetching={isFetching} />}
      {sectionEnabled("bb_engage") && <BBEngage data={data} isFetching={isFetching} />}
    </div>
  );
};

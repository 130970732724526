import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { postDashboardAction } from "@store/dashboard/actions";

import { getDashboardSelectorPending, getDashboardSelector } from "@store/dashboard/selectors";

import { IDashboardPayload, OverviewComponentType } from "@store/dashboard/types";
import { IFilters } from "@store/filters/types";

export const useDashboardData = (components: OverviewComponentType[], filters: IFilters = {}) => {
  const dispatch = useDispatch();
  const data = useSelector(getDashboardSelector);
  const isFetching = useSelector(getDashboardSelectorPending);

  const getDashboardData = useCallback(
    (payload: IDashboardPayload) => dispatch(postDashboardAction(payload)),
    [dispatch],
  );

  useEffect(() => {
    getDashboardData({ filters, components });
  }, [filters, getDashboardData, components]);

  return { data, isFetching };
};

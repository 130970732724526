import { useDispatch } from "react-redux";
import React, { FC, useCallback } from "react";

import { SelectStyled, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import { updateCommentSentimentAction } from "@store/comments/actions";

import { sentimentOptions } from "./helpers";
import { isTiktokPlatform, isYoutubePlatform } from "@utils/platform";
import { IComment } from "@store/comments/types";

import s from "./ChangeSentiment.module.less";

interface IChangeSentimentProps {
  comment?: IComment;
}

const ChangeSentiment: FC<IChangeSentimentProps> = ({ comment }) => {
  const dispatch = useDispatch();

  const sentimentValue = comment?.sentiment || "negative";
  const isDropdownDisabled =
    isTiktokPlatform(comment?.platform_type?.id || "") ||
    (isYoutubePlatform(comment?.platform_type?.id || "") &&
      comment?.actions.sentiment_change_capability?.capability === "disabled");

  const onChangeCommentSentiment = useCallback(
    sentiment => comment?.id && dispatch(updateCommentSentimentAction(comment.id, { sentiment })),
    [dispatch, comment?.id],
  );

  return (
    <div className={s.bbChangeSentiment}>
      <Tooltip title={comment?.actions.sentiment_change_capability?.tooltip}>
        <SelectStyled
          id="sentiment_change"
          value={sentimentValue}
          disabled={isDropdownDisabled}
          prefixEl={
            sentimentOptions.find(sentimentOption => sentimentOption.value === comment?.sentiment)
              ?.icon
          }
          options={sentimentOptions}
          onChange={onChangeCommentSentiment}
        />
      </Tooltip>
    </div>
  );
};

export default ChangeSentiment;

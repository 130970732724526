import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isActionOf } from "typesafe-actions";
import { switchMap, map, filter, catchError } from "rxjs/operators";
import { StateObservable } from "redux-observable";

import { ManagedActionsType } from "./types";
import { RootState } from "..";
import { ICommentsResponse } from "@store/comments/types";

import { commentsList, commentsSearch } from "@utils/paths";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";
import * as actions from "./actions";

export const getProtectComments = (
  action$: Observable<ManagedActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.proctectCommentsAction)),
    switchMap(a => {
      return ajax
        .post<ICommentsResponse>(
          commentsSearch,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response.items),
          map(actions.proctectCommentsSuccessAction),
          catchError(e => handleError(e, actions.proctectCommentsFailureAction)),
        );
    }),
  );

export const getEngagedComments = (
  action$: Observable<ManagedActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.engagedCommentsAction)),
    switchMap(a => {
      const { isManaged, ...payload } = a.payload;
      return ajax
        .post<ICommentsResponse>(
          commentsList(isManaged ? "inbox" : "smart-inbox"),
          payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response.items),
          map(actions.engagedCommentsSuccessAction),
          catchError(e => handleError(e, actions.engagedCommentsFailureAction)),
        );
    }),
  );

import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "@bbdevcrew/bb_ui_kit_fe";
import SubscriptionPricingTable from "./SubscriptionPricingTable";

import s from "./SubscriptionModal.module.less";

import { ISubscriptionModalProps } from "./types";

const SubscriptionModal = ({ isVisible, onClose }: ISubscriptionModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      responsive
      width={1400}
      theme="light"
      open={isVisible}
      onCancel={onClose}
      hideFooter
      centered
      wrapClassName={s.bbSubscriptionModalWrapper}
      titleSufix={
        <div className="text-xl font-bold">{t("components:subscriptionModal:title")}</div>
      }
    >
      <SubscriptionPricingTable isVisible={isVisible} />
    </Modal>
  );
};

export default SubscriptionModal;

import { FormProps } from "rc-field-form";
import { useTranslation } from "react-i18next";
import React from "react";

import { Grid, FormInstance } from "antd";
import { Input, SelectStyledMulti } from "@bbdevcrew/bb_ui_kit_fe";
import ClearableTrigger from "@components/_common/SelectTrigger/ClearableTrigger";

import { InputSizeType, IObjectOption, DropdownMenuPlacementType } from "@bbdevcrew/bb_ui_kit_fe";
import { IClearableTriggerProps } from "@components/_common/SelectTrigger/ClearableTrigger.types";

interface ISelectStyledWrapperProps extends Omit<FormProps, "onChange"> {
  placeholder?: string;
  options: IObjectOption[];
  _size?: InputSizeType;
  InputVariation?: typeof Input;
  dropdownMenuClassName?: string;
  dropdownPlacement?: DropdownMenuPlacementType;
  customprops?: { form?: FormInstance; name: string };
  fieldName: string;
  value?: string;
  onChange?: (values?: string) => void;
}

export function SelectStyledWrapper({
  _size,
  options,
  customprops,
  InputVariation = Input,
  dropdownPlacement,
  dropdownMenuClassName,
  onChange,
  value,
  ...props
}: ISelectStyledWrapperProps) {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  const onInternalChange = (clickedValue: string) => {
    if (clickedValue === value) {
      onChange?.(undefined);
      return;
    }
    onChange?.(clickedValue);
  };

  const _onClear = () => {
    onChange?.(undefined);
  };

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    if (!value?.length) {
      return ClearableTrigger(tooltipProps);
    }
    const tooltipElement = <span style={{ display: "block" }}>{value}</span>;

    return ClearableTrigger({
      tooltip: tooltipElement,
      onClear: _onClear,
      ...tooltipProps,
    });
  };

  return (
    <div data-cy={`app-filter-${customprops?.name}`}>
      <SelectStyledMulti
        _size={_size}
        id={props.id}
        options={options}
        value={value?.length ? [value] : []}
        onChange={onInternalChange}
        InputVariation={InputVariation}
        TriggerVariation={TriggerWithTooltip}
        dropdownPlacement={dropdownPlacement}
        dropdownMatchSelectWidth={!screens.md}
        placeholder={t("generic:all")}
        excludePlaceholder={t("generic:exclude")}
        excludedPlaceholder={t("generic:excluded")}
        dropdownMenuClassName={dropdownMenuClassName}
      />
    </div>
  );
}

import classNames from "classnames";
import React, { FC, useMemo, useState, useEffect } from "react";

import { Icon, Input, Dropdown } from "@bbdevcrew/bb_ui_kit_fe";
import SelectedExcludedIndicator from "@components/_common/SelectTrigger/SelectedExcludedIndicator";
import KeywordsDropdownBasic from "../Keywords/KeywordsDropdownBasic";

import s from "../Keywords/Keywords.module.less";

import { isChipValid, isChipPhrase, isChipPhraseEmpty } from "../../inputChip/InputChipConfig";
import {
  pickKeywords,
  flattenKeywords,
  unflattenKeywords,
  KeywordQueryType,
} from "../../inputChip/InputChipQueryConfig";
import { generateKeywordsInputValueState } from "@components/_common/SelectTrigger/helpers";
import { IInjectedCustomProps } from "../../Filters.type";

import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IAllAuthorsProps extends IInjectedCustomProps {
  placeholder?: string;
  dropdownMatchSelectWidth?: boolean;
  onChange?: (value: KeywordQueryType | string) => void;
}

export const AllAuthors: FC<IAllAuthorsProps> = ({
  placeholder,
  customprops: { form, name },
  dropdownMatchSelectWidth,
  onChange,
}) => {
  const { selected } = useMemo(() => {
    return generateKeywordsInputValueState(pickKeywords(flattenKeywords(form.getFieldValue(name))));
    // eslint-disable-next-line
  }, [form.getFieldValue(name)]);

  const [inputChipValue, setInputChipValue] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (!isDropdownOpen) {
      const value: string[] = form.getFieldValue(name) || [];

      if (inputChipValue && !value.includes(inputChipValue.trim())) {
        if (
          isChipValid(inputChipValue) &&
          !isChipPhraseEmpty(inputChipValue) &&
          !isChipPhrase(inputChipValue)
        ) {
          onChange?.(inputChipValue);
        }
      }

      onChange?.(unflattenKeywords(value.map(keyword => keyword.trim())));
      setInputChipValue("");
    }
    // eslint-disable-next-line
  }, [isDropdownOpen]);

  const setDropdownOpenState = (open: boolean) => {
    setIsDropdownOpen(open);
  };

  const onDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onClear = () => {
    onChange?.([]);
  };

  return (
    <Dropdown
      open={isDropdownOpen}
      placement="bottomRight"
      setOpen={setDropdownOpenState}
      menuClassName={!dropdownMatchSelectWidth ? s.bbKeywordsMenu : ""}
      matchTriggerWidth={dropdownMatchSelectWidth}
      trigger={
        <div
          id="author_names-trigger"
          data-cy="author_names-trigger"
          onClick={onDropdownClick}
          className={s.bbKeywordsTrigger}
        >
          <Input
            _size="sm"
            id={"author_names-trigger"}
            value={placeholder}
            data-testid="author_names-input"
            placeholder={placeholder}
            wrapperClassName={classNames(s.bbKeywordsTriggerWrapper, {
              [s.bbKeywordsTriggerWrapperFocused]: isDropdownOpen,
            })}
            suffixEl={
              <>
                <Icon
                  _size="sm"
                  icon={CloseIcon}
                  onClick={onClear}
                  className={s.bbKeywordsTriggerIconClose}
                />
                <Icon
                  _size="sm"
                  className={s.bbKeywordsTriggerIconChevron}
                  icon={isDropdownOpen ? ChevronUpIcon : ChevronDownIcon}
                />
              </>
            }
          />

          {!!selected && <SelectedExcludedIndicator selected={selected} />}
        </div>
      }
    >
      <KeywordsDropdownBasic name={name} form={form} />
    </Dropdown>
  );
};

import { isUserTag } from "../TagsModal/TagsModal.helpers";

import { AnyTagType, IUserTag, ICollaboratorTag } from "../TagsModal/TagsModal.types";

export const mapIgTagsToBackend = (items: AnyTagType[]) => {
  const collaborators: string[] = [];
  const result = [];

  items.forEach(item => {
    if (isUserTag(item) && (item as IUserTag).collaborator) {
      collaborators.push((item as IUserTag).username);
    } else {
      result.push(item);
    }
  });

  if (collaborators.length > 0) {
    result.unshift({ collaborators: collaborators });
  }

  return result;
};

export const mapIgTagsToFrontend = (payload: AnyTagType[]): AnyTagType[] => {
  const result: AnyTagType[] = [];

  payload.forEach(item => {
    if ((item as ICollaboratorTag)?.collaborators) {
      (item as ICollaboratorTag).collaborators.forEach(collaborator => {
        result.push({ collaborator: true, username: collaborator, x: 0, y: 0 });
      });
    } else {
      result.push(item as AnyTagType);
    }
  });

  return result;
};

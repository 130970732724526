import classNames from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";

import { Col, Row } from "antd";
import EmptyState from "./emptyState/EmptyState";
import { InboxMenu } from "@containers/InboxMenu/InboxMenu";
import { InboxCommentsHeader } from "./inboxCommentsHeader/InboxCommentsHeader";
import QuickAutomationMenu from "../_common/quickAutomation/QuickAutomationMenu";
import { CommentsGridContainer } from "@containers/Comments/Comments";
import { AlertDanger } from "@bbdevcrew/bb_ui_kit_fe";

import { filterDataSelector } from "@store/filters/selectors";
import { getInboxRepliesAction } from "@store/comments/actions";
import { inboxStatsSelector } from "@store/inboxStats/selectors";
import { shortenedSearchesIdSelector } from "@store/shortenedSearches/selectors";

import s from "./Inbox.module.less";

import {
  IPostCommentBody,
  ReplyPlatformType,
  IGetInboxRepliesPayload,
} from "@store/comments/types";
import { useInbox } from "@utils/useInbox";
import { useAppFilters } from "@utils/appFilters";
import { IInboxStats } from "@store/inboxStats/types";
import { useBatchMode } from "@containers/BatchMode/BatchMode";
import { getDefaultReplyPlatformTypeOptions } from "@utils/comments";
import { getInboxParentTabValue, useErrorInboxOptions, useInboxOptions } from "./config";

import { AlertTriangleFilledIcon } from "@assets/index";

const Inbox = () => {
  const { t } = useTranslation();
  const { isBatchModeActive } = useBatchMode();
  const [searchParams, setSearchParams] = useSearchParams();
  const { updateSearchDataAndUrl } = useAppFilters();

  const queryParamsTab = searchParams.get("tab");

  const [sortValue, setSortValue] = useState<string>("desc");
  const { statTabValue, setStatTabValue, onTabChange, assignToMeHandler } = useInbox(
    "care_highlights",
    sortValue,
  );
  const [isTabToggled, setIsTabToggled] = useState(false);

  const [replyPlatformType, setReplyPlatformType] = useState<ReplyPlatformType>(
    getDefaultReplyPlatformTypeOptions()[0].value,
  );
  const [showErrorDisclaimer, setShowErrorDisclaimer] = useState(false);

  const filters = useSelector(filterDataSelector);
  const inboxStats = useSelector(inboxStatsSelector);
  const shortenedSearchesId = useSelector(shortenedSearchesIdSelector);

  const fetchReplies = useCallback(
    (body: IPostCommentBody | IGetInboxRepliesPayload) => {
      const sortVals = body.sort?.split("|");
      return getInboxRepliesAction({
        ...body,
        sort: sortVals && sortVals[0],
        sort_field: sortVals && sortVals[1],
        list_type: body.isReplyModal ? "all" : statTabValue,
      });
    },
    [statTabValue],
  );

  useEffect(() => {
    setShowErrorDisclaimer(!!inboxStats?.stats.errors);
  }, [inboxStats]);

  useEffect(() => {
    setStatTabValue((queryParamsTab || "care_highlights") as keyof IInboxStats);
  }, [queryParamsTab, setStatTabValue]);

  useEffect(() => {
    if (shortenedSearchesId) {
      setSearchParams({ tab: statTabValue, id: shortenedSearchesId });
    }
  }, [shortenedSearchesId, setSearchParams, statTabValue]);

  const onFilterAuthorId = (authorIds: string[]) =>
    updateSearchDataAndUrl({ author_ids: authorIds }, "id");

  const onCloseErrorDisclaimer = () => setShowErrorDisclaimer(false);

  const handleTabChange = (tab: keyof IInboxStats) => {
    setIsTabToggled(prev => !prev);
    onTabChange(tab);
  };

  return filters ? (
    <div className={s.bbInbox} data-cy="inbox-container">
      <Row gutter={30} className={s.bbInboxContentRow}>
        <Col
          xs={24}
          xl={6}
          xxl={6}
          className={classNames(s.bbInboxContentCol, {
            [s.bbInboxBatchMode]: isBatchModeActive,
          })}
        >
          <InboxMenu
            filters={filters}
            value={statTabValue}
            onTabChange={handleTabChange}
            useInboxOptions={useInboxOptions}
          />
          {!!inboxStats?.stats.errors && (
            <InboxMenu
              filters={filters}
              value={statTabValue}
              onTabChange={handleTabChange}
              useInboxOptions={useErrorInboxOptions}
            />
          )}
          <QuickAutomationMenu />
        </Col>

        <div className={s.bbInboxContentWrapper}>
          <InboxCommentsHeader
            sortValue={sortValue}
            statTabValue={statTabValue}
            onClickAssignToMe={assignToMeHandler}
            onSortChange={val => setSortValue(val)}
            replyPlatformTypeValue={replyPlatformType}
            statTabParent={getInboxParentTabValue(useInboxOptions, statTabValue)}
            onReplyPlatformChange={val => setReplyPlatformType(val as ReplyPlatformType)}
          />
          {showErrorDisclaimer && statTabValue === "errors" && (
            <div data-stonly-target={"app-global__inbox-errors-tab--error-disclaimer-box"}>
              <AlertDanger
                size="large"
                align="center"
                className={s.bbErrorDisclaimer}
                onClose={onCloseErrorDisclaimer}
                message={
                  <div className={s.bbErrorDisclaimerMessage}>
                    {t("components:errorsTabDisclaimer1")}
                    <span className={s.bbErrorDisclaimerBox}>
                      <AlertTriangleFilledIcon />
                    </span>
                    {t("components:errorsTabDisclaimer2")}
                  </div>
                }
              />
            </div>
          )}
          <div className={s.bbInboxCommentsGrid}>
            <CommentsGridContainer
              showStarAction
              showHideAction
              showDeleteAction
              showLikeAction
              showDetailsAction
              showLinkedInReactionAction
              showArchiveAction
              withPostPreview
              filters={filters}
              sortValue={sortValue}
              providedFetchAction={fetchReplies}
              key={`comment-grid-${statTabValue}-${isTabToggled}`}
              EmptyState={<EmptyState tab={statTabValue} onAssignClick={assignToMeHandler} />}
              replyPlatformType={statTabValue === "replied" ? replyPlatformType : undefined}
              providedFilterAuthorIdAction={(authorIds: string[]) => onFilterAuthorId(authorIds)}
            />
          </div>
        </div>
      </Row>
    </div>
  ) : null;
};

export default Inbox;

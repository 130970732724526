import React, { ReactElement, MouseEvent } from "react";

import { Chip, ChipIcon, ChipAction } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ChipList.module.less";

import { ChipSize, ChipType } from "@bbdevcrew/bb_ui_kit_fe";
import { pickKeywords } from "../AppFilter/inputChip/InputChipQueryConfig";

interface _IChipListProps {
  value: string[];
  _size: keyof typeof ChipSize;
  _type?: keyof typeof ChipType;
  children: ReactElement<typeof Chip | typeof ChipIcon | typeof ChipAction>[] | JSX.Element;
}

// eslint-disable-next-line
export interface IChipListProps extends Omit<_IChipListProps, "children"> {}

export interface IChipListActionProps extends Omit<_IChipListProps, "children"> {
  onActionClick: (chip: string, idx: number) => void;
  renderAddChip?: () => JSX.Element;
}

export function _ChipList({ children }: _IChipListProps) {
  return <div className="flex flex-row">{children}</div>;
}

function ChipList({ _size, _type, value, ...props }: IChipListProps) {
  const cleanValue = pickKeywords(value);

  return (
    <_ChipList {...props} _type={_type} _size={_size} value={cleanValue}>
      {cleanValue.map((chip, idx) => (
        <Chip key={idx} text={chip} _type={_type} _size={_size} className="mr-1" />
      ))}
    </_ChipList>
  );
}

export function ChipListAction({
  _size,
  value,
  onActionClick,
  renderAddChip,
}: IChipListActionProps) {
  const cleanValue = pickKeywords(value);

  return (
    <div className={s.bbChipListContainer}>
      {cleanValue.map((chip, idx) => (
        <ChipAction
          key={idx}
          text={chip}
          _size={_size}
          wrapperClassName={s.bbChipListAction}
          onActionClick={(event: MouseEvent<HTMLButtonElement>) => {
            // Could be too implicit. Remove if event needs to be bubbled up.
            event.preventDefault();
            return onActionClick(chip, idx);
          }}
        />
      ))}
      {renderAddChip && renderAddChip()}
    </div>
  );
}

export default ChipList;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Card } from "@bbdevcrew/bb_ui_kit_fe";

import SectionDivider from "@components/insights/overview/OverviewSectionDivider";
import CommentsByTagChart from "@components/insights/overview/externalAppsOverview/commentsByTagChart/CommentsByTagChart"; // eslint-disable-line max-len
import CommentsByPlatformWidget from "@components/insights/overview/externalAppsOverview/commentsByPlatformWidget"; // eslint-disable-line max-len
import TaggedCommentsWidget from "@components/insights/overview/externalAppsOverview/taggedCommentsWidget/TaggedCommentsWidget"; // eslint-disable-line max-len
import PieChartWidget from "@components/insights/_common/pieChartWidget/PieChartWidget";

import { overviewCareSelector } from "@store/dashboard/selectors";
import { meSelector } from "@store/me/selectors";

import {
  normalizeCareTagsStatsData,
  normalizeRepliedStatsData,
  ReplySelectOptions,
} from "@components/insights/overview/externalAppsOverview/helpers";

import s from "./BBEngage.module.less";
import reportStyles from "./../PDFReport.module.less";

import { IOverviewPerformanceItem, IOverviewPerformanceTag } from "@store/dashboard/types";
import { IPieChartUnit } from "@components/insights/search/Search.type";
import { IBBEngageProps } from "./BBEngage.types";

import { CommentIcon, InboxIcon, ReplyIcon, TagIcon } from "@assets/index";

export const BBEngage: React.FC<IBBEngageProps> = ({ isFetching }) => {
  const { t } = useTranslation();

  const [tagsStatsDataEngage, setTagsStatsDataEngage] = useState<IOverviewPerformanceTag[]>([]);
  const [repliedStatsData, setRepliedStatsData] = useState<IOverviewPerformanceItem[]>([]);
  const [tagsRepliedStatsData, setTagsRepliedStatsData] = useState<IPieChartUnit[]>([]);

  const me = useSelector(meSelector);
  const overviewCareData = useSelector(overviewCareSelector);

  const clientPlan = me?.client?.client_plan?.id;
  const isPrime = clientPlan === "PRIME";
  const isGrowthSemi = clientPlan === "GROWTH_SEMI";
  const isFeatureLocked = !isPrime && !isGrowthSemi;
  const replySelectValue = ReplySelectOptions.REPLIED_BY_BB;

  useEffect(() => {
    if (!isFetching) {
      let repliedData: IOverviewPerformanceItem[] = [];
      let tagsRepliedStats: IOverviewPerformanceItem[] = [];

      if (replySelectValue === ReplySelectOptions.REPLIED_BY_BB) {
        repliedData = overviewCareData?.care_bb_replied_stats.items || [];
        tagsRepliedStats = overviewCareData?.tags_bb_replied_stats.items || [];
      } else if (replySelectValue === ReplySelectOptions.REPLIED_IN_PLATFORM) {
        repliedData = overviewCareData?.care_platform_replied_stats.items || [];
        tagsRepliedStats = overviewCareData?.tags_platform_replied_stats.items || [];
      } else if (replySelectValue === ReplySelectOptions.REPLIED_BY_ALL) {
        repliedData = overviewCareData?.care_replied_stats.items || [];
        tagsRepliedStats = overviewCareData?.tags_replied_stats.items || [];
      }

      setRepliedStatsData(repliedData);
      setTagsRepliedStatsData(normalizeRepliedStatsData(tagsRepliedStats));
      setTagsStatsDataEngage(
        normalizeCareTagsStatsData(overviewCareData?.engagement_tags_stats?.items || []),
      );
    }
  }, [isFetching, replySelectValue, overviewCareData]);

  const getDefaultValue = () => {
    if (tagsRepliedStatsData?.length) {
      const tagsRepliedStatsValues = tagsRepliedStatsData?.map(item => item.value);
      const tagsRepliedStatsTotal = tagsRepliedStatsValues.length
        ? tagsRepliedStatsValues.reduce((k, v) => k + v)
        : 0;

      return {
        name: "Total",
        value: tagsRepliedStatsTotal,
        color: "#000",
        rawName: t("general:senitment:total"),
        percentage_of_total: 100,
      };
    } else return undefined;
  };

  return (
    <div className={reportStyles.bbPDFReportSection}>
      <div className={s.bbEngage}>
        <div className={s.bbEngageHeader}>
          <SectionDivider
            icon={isGrowthSemi ? <InboxIcon /> : <CommentIcon />}
            title={
              isGrowthSemi
                ? t("components:managed:engage:semi:title")
                : t("components:managed:engage:title")
            }
            description={
              isGrowthSemi
                ? t("components:managed:engage:semi:description")
                : t("components:managed:engage:description", { name: me?.client?.name })
            }
          />
        </div>
        <div className={s.bbEngageContent}>
          {!isFeatureLocked && (
            <>
              <div className={s.bbEngageContentRow}>
                <div className={s.bbEngageContentLeftCol}>
                  <CommentsByPlatformWidget
                    data={repliedStatsData}
                    loading={isFetching}
                    headerIcon={<ReplyIcon />}
                    headerIconType="neutral"
                    title={t("components:overviewCare:repliesIssued:title")}
                    subTitle={t("components:overviewCare:repliesIssued:subTitle")}
                    showCalculatedPercentage={false}
                  />
                </div>
                <div className={s.bbEngageContentRightCol}>
                  <Card
                    headerTitle={t("components:overviewCare:responded:title")}
                    headerSubTitle={t("components:overviewCare:responded:subTitle")}
                  >
                    <PieChartWidget
                      sizes={[260, 260]}
                      radiuses={[95, 120]}
                      position="center"
                      loading={isFetching}
                      chartData={tagsRepliedStatsData}
                      defaultValue={getDefaultValue()}
                    />
                  </Card>
                </div>
              </div>
              <div className={s.bbEngageContentRow}>
                <div className={s.bbEngageContentLeftCol}>
                  <TaggedCommentsWidget
                    data={overviewCareData?.engagement_total_stats.items || []}
                    loading={isFetching}
                    headerIcon={<TagIcon />}
                    headerIconType="neutral"
                    title={t("components:overviewCare:engageable:title")}
                    subTitle={t("components:overviewCare:engageable:subTitle", {
                      name: me?.client?.name,
                    })}
                    showCalculatedPercentage={false}
                  />
                </div>
                <div className={s.bbEngageContentRightCol}>
                  <CommentsByTagChart
                    data={tagsStatsDataEngage}
                    loading={isFetching}
                    title={t("components:overviewCare:respondedByTag:title")}
                    subTitle={t("components:overviewCare:respondedByTag:subTitle", {
                      name: me?.client?.name,
                    })}
                    showCalculatedPercentage={false}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isActionOf } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError, mergeMap } from "rxjs/operators";

import * as actions from "./actions";

import { RootState } from "..";
import { sources } from "@utils/paths";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";
import { ISourcesListResponse, Source, SourcesActions } from "./types";

const getHeadersFunction = (state$: StateObservable<RootState>) =>
  getHeaders({
    Authorization: state$.value.auth.session.accessToken.jwtToken,
  });

export const getSourcesList = (
  action$: Observable<SourcesActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getSourcesListAction)),
    switchMap(() =>
      ajax.get<ISourcesListResponse>(sources, getHeadersFunction(state$)).pipe(
        map(({ response }) => response),
        map(data => actions.getSourcesListSuccessAction(data)),
        catchError(e => handleError(e, actions.getSourcesListFailureAction)),
      ),
    ),
  );

export const createSource = (
  action$: Observable<SourcesActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.createSourceAction)),
    mergeMap(({ payload }) =>
      ajax.post(sources, payload, getHeadersFunction(state$)).pipe(
        map(({ response }) => response),
        map(() => actions.createSourceSuccessAction()),
        catchError(({ response }) => [actions.createSourceFailureAction(response.message)]),
      ),
    ),
  );

export const updateSource = (
  action$: Observable<SourcesActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.updateSourceAction)),
    mergeMap(a => {
      const { id, ...payload } = a.payload;

      return ajax.put<Source>(`${sources}/${id}`, payload, getHeadersFunction(state$)).pipe(
        map(({ response }) => response),
        map(() => actions.updateSourceSuccessAction()),
        catchError(({ response }) => [actions.updateSourceFailureAction(response.message)]),
      );
    }),
  );

export const deleteSource = (
  action$: Observable<SourcesActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.deleteSourceAction)),
    switchMap(({ payload: id }) =>
      ajax.delete(`${sources}/${id}`, getHeadersFunction(state$)).pipe(
        map(({ response }) => response),
        map(() => actions.deleteSourceSuccessAction()),
        catchError(({ response }) => [actions.deleteSourceFailureAction(response.message)]),
      ),
    ),
  );

import Form from "antd/lib/form";
import { useTranslation } from "react-i18next";
import React, { useMemo, useState } from "react";

import { KeywordSearch } from "./KeywordSearch";
import InputChip from "../../inputChip/InputChip";
import { KeywordsTranslation } from "./KeywordsTranslation";

import s from "./Keywords.module.less";

import { IKeywordsDropdownProps } from "./KeywordsDropdown";
import { flattenKeywords, InputChipQueryLabelsEnum } from "../../inputChip/InputChipQueryConfig";

export const KeywordsDropdownBasic = ({
  name,
  form,
  showTranslationSwitch,
}: IKeywordsDropdownProps) => {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState("");

  const flattenedVal = useMemo(
    () => flattenKeywords(form.getFieldValue(name) || []),
    // eslint-disable-next-line
    [form.getFieldValue(name)],
  );

  const onChipAdd = (chip: string) => {
    const val = flattenedVal;

    val.push(chip);
    form.setFieldsValue({ [name]: val });
  };

  const onChipRemove = (chip: string, idx: number) => {
    const val = flattenedVal;
    const nextChip = val[idx + 1];

    if (!!InputChipQueryLabelsEnum[nextChip as keyof typeof InputChipQueryLabelsEnum]) {
      val.splice(idx + 1, 1);
    }

    // filter chip from val
    const filteredVal = val.filter(keyword => keyword !== chip);

    // Remove clicked chip
    form.setFieldsValue({ [name]: filteredVal });
  };

  const filteredKeywords = useMemo(() => {
    if (!searchTerm) {
      return flattenedVal;
    }

    return flattenedVal.filter(keyword => keyword.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [flattenedVal, searchTerm]);

  return (
    <div
      id="keywords-dropdown"
      data-testid="keywords-dropdown"
      className={s.bbKeywordsDropdownBasic}
    >
      <KeywordSearch
        name={name}
        form={form}
        setSearchValue={setSearchTerm}
        chipCount={flattenedVal.length}
      />
      <InputChip
        autoFocus
        _size="sm"
        value={filteredKeywords}
        onChipAdd={onChipAdd}
        onChipRemove={onChipRemove}
        id="keywords-dropdown-input"
        disabled={!!searchTerm.length}
        className={s.bbKeywordsDropdownBasicInput}
        wrapperClassName={s.bbKeywordsDropdownBasicInputWrapper}
        onClearAll={() => form.setFieldsValue({ [name]: [] })}
        placeholder={
          form.getFieldValue(name)?.length ? "" : t(`components:filters:keywords:sub_placeholder`)
        }
      />
      {showTranslationSwitch && (
        <Form size="small" colon={false} form={form}>
          <KeywordsTranslation name={name} />
        </Form>
      )}
    </div>
  );
};

export default KeywordsDropdownBasic;

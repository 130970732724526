import classNames from "classnames";
import React, { FC, useCallback, useState } from "react";

import { Menu } from "antd";
import { Button, SimpleDropdown, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import { IActionsDropdownProps } from "./ActionsDropdown.type";
import { renderIcon, getMenuItemClassName } from "./helpers";

import s from "./ActionsDropdown.module.less";

import { MoreActionsIcon } from "@assets/index";
import { CheckIcon } from "@bbdevcrew/bb_ui_kit_fe";

const ActionsDropdown: FC<IActionsDropdownProps> = ({
  items,
  triggerLabel,
  triggerClass = "",
  triggerMode = "hover",
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const menu = useCallback(
    ({ closeDropdown }: { closeDropdown?: () => void } = {}) => (
      <Menu>
        {items.map(item => {
          const { id, label, selected, disabled, tooltipText, onClick, subItems } = item;

          if (!disabled && subItems?.length) {
            return (
              <Menu.SubMenu
                key={id}
                title={label}
                icon={renderIcon(item)}
                disabled={disabled}
                popupClassName={s.bbDropdownMenuSubmenu}
                className={classNames(s.bbDropdownMenuItemm, s.bbDropdownMenuItemChildren)}
              >
                {subItems.map(subItem => (
                  <Menu.Item
                    key={subItem.id}
                    onClick={() => {
                      if (subItem.onClick) {
                        subItem.onClick();
                      }
                      closeDropdown?.();
                    }}
                    icon={renderIcon(subItem)}
                    className={s.bbDropdownMenuItemm}
                  >
                    {subItem.label}
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            );
          }

          return (
            <Menu.Item
              key={id}
              onClick={() => {
                onClick?.();
                closeDropdown?.();
              }}
              disabled={disabled}
              icon={renderIcon(item)}
              className={getMenuItemClassName(selected, disabled)}
            >
              <Tooltip title={tooltipText} overlayClassName={s.bbDropdownMenuItemTooltip}>
                {label}
              </Tooltip>
              {selected && (
                <span className={s.bbSvgClassChecked}>
                  <CheckIcon />
                </span>
              )}
            </Menu.Item>
          );
        })}
      </Menu>
    ),
    [items],
  );

  return (
    <SimpleDropdown
      open={isDropdownOpen}
      setOpen={setIsDropdownOpen}
      trigger={
        <span>
          <Button _size="md" className={classNames(triggerClass, s.bbDropdownTrigger)}>
            <MoreActionsIcon />
            {triggerLabel && <span>{triggerLabel}</span>}
          </Button>
        </span>
      }
      triggerMode={triggerMode}
      placement="bottom-end"
      className={s.bbDropdownMenu}
    >
      {triggerMode === "click" ? closeDropdown => menu({ closeDropdown }) : menu()}
    </SimpleDropdown>
  );
};

export default ActionsDropdown;

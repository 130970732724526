import { useTranslation } from "react-i18next";
import React, { FC, useRef, useMemo, useState, useEffect, KeyboardEvent, ChangeEvent } from "react";

import { Input, DropdownMenu } from "@bbdevcrew/bb_ui_kit_fe";
import { ChipListQuery } from "@components/_common/ChipList/ChipListQuery";

import {
  hasOpenNesting,
  IContextMenu,
  useInputChipQuery,
  getCommandMenuVisiblity,
} from "./InputChipQueryConfig";
import { _IInputChipProps } from "./InputChipConfig";

interface IInputChipQueryProps extends _IInputChipProps {
  ContextMenu: FC<IContextMenu>;
}

export function InputChipQuery({
  _size,
  value,
  onChipAdd,
  onChipRemove,
  ContextMenu,
  onChange: onChangeInputValue,
  wrapperClassName,
  ...props
}: IInputChipQueryProps) {
  const { t } = useTranslation();
  const [menuOffsetX, setMenuOffsetX] = useState(-1);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  function _onKeyUp(event: KeyboardEvent<HTMLInputElement>) {
    const offset = event.currentTarget.parentElement?.offsetLeft;

    setMenuOffsetX(offset !== undefined ? offset : -1);
  }

  const { chipValue, onKeyUp, onKeyDown, onChange, onPaste } = useInputChipQuery({
    value,
    onChipAdd,
    onChipRemove,
    onKeyUp: _onKeyUp,
  });

  const hasValidationErrors = useMemo(
    () => !!chipValue.match(/^\(/) && hasOpenNesting(value),
    [chipValue, value],
  );

  // Hide ctx menu on value change
  useEffect(() => {
    if (isMenuOpen) setIsMenuOpen(false);
    // eslint-disable-next-line
  }, [value.length]);

  useEffect(() => {
    const visible = getCommandMenuVisiblity(chipValue, menuOffsetX);

    if (!visible && !hasValidationErrors) {
      setMenuOffsetX(-1);
    }

    setIsMenuOpen(visible);
  }, [chipValue, menuOffsetX, hasValidationErrors]);

  function _onChipAdd(addedValue: string) {
    inputRef.current?.focus();

    onChipAdd(addedValue);
  }

  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event);

    onChangeInputValue && onChangeInputValue(event);
  };

  const isValueLengthZero = value.length === 0;

  return (
    <div className="relative flex flex-row items-center">
      <ChipListQuery
        _size="xs"
        value={value}
        onActionClick={onChipRemove}
        renderAddChip={() => (
          <Input
            {...props}
            _size={_size}
            ref={inputRef}
            value={chipValue}
            onPaste={onPaste}
            onKeyUp={onKeyUp}
            onChange={_onChange}
            onKeyDown={onKeyDown}
            wrapperClassName={`${wrapperClassName} ${
              !isValueLengthZero ? "w-auto" : ""
            } focus:shadow-none border-none  inline-flex min-w-[100px]`}
          />
        )}
      />

      <DropdownMenu
        {...props}
        open={isMenuOpen}
        placement="bottom"
        style={{ transform: `translateX(${menuOffsetX}px)` }}
      >
        <ContextMenu chips={value} _size={_size} chipValue={chipValue} onChipAdd={_onChipAdd} />
      </DropdownMenu>
      <DropdownMenu
        {...props}
        placement="bottom"
        open={hasValidationErrors && menuOffsetX != -1}
        style={{ transform: `translateX(${menuOffsetX}px)` }}
      >
        <span className="block py-2 px-5 text-sm font-normal text-grey-700">
          {t("components:filters:keywords:nestingValidationError")}
        </span>
      </DropdownMenu>
    </div>
  );
}

export default InputChipQuery;

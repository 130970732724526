import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import React, { FC, useState, useMemo } from "react";

import PrivateRoute from "./PrivateRoute";
import { DemoMode } from "@containers/DemoMode/DemoMode";
import AppLayout from "@components/_common/AppLayout/AppLayout";
import NotFoundView from "@containers/NotFoundView/NotFoundView";
import ModerationDashboard from "@components/safety/dashboard/Dashboard";
import MissingAssetsDisclaimer from "@components/_common/Disclaimers/MissingAssetsDisclaimer";
import ReconnectAssetsDisclaimer from "@components/_common/Disclaimers/ReconnectAssetsDisclaimer";
import CommentLimitDisclaimer from "@components/_common/Disclaimers/CommentLimitDisclaimer";
import PaymentFailedDisclaimer from "@components/_common/Disclaimers/PaymentFailedDisclaimer";
import PendingAssetsDisclaimer from "@components/_common/Disclaimers/PendingAssetsDisclaimer";

import { meSelector, moderationAppFeature } from "@store/me/selectors";

import { IRoutesProps } from "./Routes.type";
import { isGracePeriod } from "@utils/grace";
import { useRoutes } from "@utils/useRoutes";
import { ModerationSubFeatureComponents } from "./helpers";
import { IMenuItem } from "@components/_common/AppHeader/AppHeader.type";

const ModerationRoutes: FC<IRoutesProps> = ({ menu, path, hasMenu, pendingAssets }) => {
  const { redirectCallback, extraMenuItems } = useRoutes(path);

  const [showPendingAssetsAlert, setShowPendingAssetsAlert] = useState(true);
  const [showPaymentDisclaimer, setShowPaymentDisclaimer] = useState(true);

  const me = useSelector(meSelector);
  const feature = useSelector(moderationAppFeature);
  const subfeatures = useMemo(() => feature?.subfeatures || [], [feature]);

  return (
    <AppLayout
      basename="moderation"
      avatarUrl={me?.client?.logo}
      hasHeader={path !== "/unauthorized"}
      hasFooter={path !== "/unauthorized"}
      menu={menu as unknown as IMenuItem[]}
      extra={extraMenuItems}
      selectedKey={(path && path.replace(/\//g, "")) || "dashboard"}
      defaultSelectedKey={subfeatures.length ? subfeatures[0].id : "dashboard"}
    >
      {{
        beforeContent: (
          <>
            {path !== "/unauthorized" ? (
              <div>
                {!hasMenu ? <MissingAssetsDisclaimer /> : null}
                {me?.asset_alert?.message ? <ReconnectAssetsDisclaimer /> : null}
                {pendingAssets.length && showPendingAssetsAlert ? (
                  <PendingAssetsDisclaimer
                    onCloseDisclaimer={() => setShowPendingAssetsAlert(false)}
                  />
                ) : null}
                {me && isGracePeriod(me) && showPaymentDisclaimer ? (
                  <PaymentFailedDisclaimer
                    onCloseDisclaimer={() => setShowPaymentDisclaimer(false)}
                  />
                ) : null}
                <CommentLimitDisclaimer />
                <DemoMode />
              </div>
            ) : null}
          </>
        ),
        content: (
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  replace
                  to={{
                    pathname: subfeatures.length ? subfeatures[0].id : "dashboard",
                    search: window.location.search,
                  }}
                />
              }
            />
            {subfeatures.length ? (
              subfeatures.map(({ id, is_disabled }) => {
                const RouteComponent =
                  ModerationSubFeatureComponents[
                    id as keyof typeof ModerationSubFeatureComponents
                  ] || null;

                return (
                  <Route
                    key={id}
                    path={id}
                    element={
                      <PrivateRoute isDisabled={is_disabled}>
                        <RouteComponent />
                      </PrivateRoute>
                    }
                  />
                );
              })
            ) : (
              <Route
                key="dashboard"
                path="dashboard"
                element={
                  <PrivateRoute isDisabled={false}>
                    <ModerationDashboard />
                  </PrivateRoute>
                }
              />
            )}

            <Route
              path="/unauthorized"
              element={<NotFoundView redirectCallback={redirectCallback} type="403" />}
            />
            <Route path="*" element={<NotFoundView redirectCallback={redirectCallback} />} />
          </Routes>
        ),
      }}
    </AppLayout>
  );
};

export default ModerationRoutes;

import { createReducer, ActionType } from "typesafe-actions";
import produce from "immer";

import * as actions from "./actions";

import { IPublishingsState } from "./types";

const initialState: IPublishingsState = {
  publishingsList: [],
  holidays: [],
  postsBeingRepublished: [],
  urlPreviews: [],
  ttCreatorInfo: [],
  isUrlPreviewRemoved: false,
  fetchingPublishingList: false,
  fetchedPublishingList: false,
  fetchedPublishingListFail: false,
  creatingPostPublishing: false,
  createdPostPublishing: false,
  creatingPostPublishingFailed: false,
  scheduledPostPublishing: false,
  createdDraftPostPublishing: false,
  updatingPostPublishing: false,
  updatedPostPublishing: false,
  editedPostPublishingDraft: false,
  updatingPostPublishingFailed: false,
  scheduledEditedPostPublishing: false,
  postPublishingFailedMessage: "",
  TTCreatorInfoErrorData: undefined,
  deletePostPending: false,
  deletePostFullfilled: false,
  deletePostFailedMessage: "",
  fetchingUrlPreviews: false,
  fetchedUrlPreviewsSuccess: false,
  fetchedUrlPreviewsFail: false,
  fetchingTTCreatorInfoAssets: [],
  fetchedTTCreatorInfoSuccess: [],
  fetchedTTCreatorInfoFail: false,
  fetchingInstagramAssetInfo: false,
  fetchedInstagramAssetInfoSuccess: false,
  fetchedInstagramAssetInfoFail: false,
  instagramAssetInfo: null,
  fetchingInstagramProducts: false,
  fetchedInstagramProductsSuccess: false,
  fetchedInstagramProductsFail: false,
  instagramProducts: [],
  fetchingYouTubeCategories: false,
  fetchedYouTubeCategoriesSuccess: false,
  fetchedYouTubeCategoriesFail: false,
  youtubeCategories: {},
  youtubePlaylists: {},
  fetchingYouTubePlaylists: false,
  fetchedYouTubePlaylistsSuccess: false,
  fetchedYouTubePlaylistsFail: false,
};

export const publishingsReducer = createReducer<IPublishingsState, ActionType<typeof actions>>(
  initialState,
)
  .handleAction(actions.getPublishingsListAction, state =>
    produce(state, draft => {
      draft.fetchingPublishingList = true;
      draft.fetchedPublishingList = false;
      draft.fetchedPublishingListFail = false;
    }),
  )
  .handleAction(actions.getPublishingsListSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.publishingsList = action.payload.items;
      draft.holidays = action.payload.holidays;
      draft.fetchingPublishingList = false;
      draft.fetchedPublishingList = true;
      draft.fetchedPublishingListFail = false;
    }),
  )
  .handleAction(actions.getPublishingsListFailureAction, state =>
    produce(state, draft => {
      draft.publishingsList = [];
      draft.holidays = [];
      draft.fetchingPublishingList = false;
      draft.fetchedPublishingList = false;
      draft.fetchedPublishingListFail = true;
    }),
  )
  .handleAction(actions.createPostPublishingAction, state =>
    produce(state, draft => {
      draft.creatingPostPublishing = true;
      draft.createdPostPublishing = false;
      draft.scheduledPostPublishing = false;
      draft.createdDraftPostPublishing = false;
      draft.creatingPostPublishingFailed = false;
      draft.postPublishingFailedMessage = "";
    }),
  )
  .handleAction(actions.createPostPublishingSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.creatingPostPublishing = false;
      draft.createdPostPublishing = !action.payload.scheduled;
      draft.scheduledPostPublishing = !!action.payload.scheduled;
      draft.createdDraftPostPublishing = !!action.payload.is_draft;
      draft.creatingPostPublishingFailed = false;
      draft.postPublishingFailedMessage = "";
    }),
  )
  .handleAction(actions.createPostPublishingFailureAction, (state, action) =>
    produce(state, draft => {
      draft.creatingPostPublishing = false;
      draft.createdPostPublishing = false;
      draft.scheduledPostPublishing = false;
      draft.createdDraftPostPublishing = false;
      draft.creatingPostPublishingFailed = true;
      draft.postPublishingFailedMessage = action.payload;
    }),
  )
  .handleAction(actions.updatePostPublishingAction, state =>
    produce(state, draft => {
      draft.updatingPostPublishing = true;
      draft.updatedPostPublishing = false;
      draft.scheduledEditedPostPublishing = false;
      draft.editedPostPublishingDraft = false;
      draft.updatingPostPublishingFailed = false;
      draft.postPublishingFailedMessage = "";
    }),
  )
  .handleAction(actions.updatePostPublishingSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.updatingPostPublishing = false;
      draft.updatedPostPublishing = !action.payload.scheduled;
      draft.scheduledEditedPostPublishing = !!action.payload.scheduled;
      draft.editedPostPublishingDraft = !!action.payload.is_draft;
      draft.updatingPostPublishingFailed = false;
      draft.postPublishingFailedMessage = "";
    }),
  )
  .handleAction(actions.updatePostPublishingFailureAction, (state, action) =>
    produce(state, draft => {
      draft.updatingPostPublishing = false;
      draft.updatedPostPublishing = false;
      draft.editedPostPublishingDraft = false;
      draft.updatingPostPublishingFailed = true;
      draft.scheduledEditedPostPublishing = false;
      draft.postPublishingFailedMessage = action.payload;
    }),
  )
  .handleAction(actions.deletePostPublishingAction, state =>
    produce(state, draft => {
      draft.deletePostPending = true;
      draft.deletePostFullfilled = false;
      draft.deletePostFailedMessage = "";
    }),
  )
  .handleAction(actions.deletePostPublishingSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.publishingsList = state.publishingsList.filter(post => post.id !== action.payload);
      draft.deletePostPending = false;
      draft.deletePostFullfilled = true;
      draft.deletePostFailedMessage = "";
    }),
  )
  .handleAction(actions.deletePostPublishingFailureAction, (state, action) =>
    produce(state, draft => {
      draft.deletePostPending = false;
      draft.deletePostFullfilled = false;
      draft.deletePostFailedMessage = action.payload;
    }),
  )
  .handleAction(actions.getUrlPreviewsAction, state =>
    produce(state, draft => {
      draft.urlPreviews = [];
      draft.fetchingUrlPreviews = true;
      draft.fetchedUrlPreviewsSuccess = false;
      draft.fetchedUrlPreviewsFail = false;
    }),
  )
  .handleAction(actions.getUrlPreviewsSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.urlPreviews = action.payload;
      draft.isUrlPreviewRemoved = false;
      draft.fetchingUrlPreviews = false;
      draft.fetchedUrlPreviewsSuccess = true;
      draft.fetchedUrlPreviewsFail = false;
    }),
  )
  .handleAction(actions.getUrlPreviewsFailureAction, state =>
    produce(state, draft => {
      draft.fetchingUrlPreviews = false;
      draft.fetchedUrlPreviewsSuccess = false;
      draft.fetchedUrlPreviewsFail = true;
    }),
  )
  .handleAction(actions.clearUrlPreviewsAction, (state, action) =>
    produce(state, draft => {
      draft.urlPreviews = [];
      draft.isUrlPreviewRemoved = !!action.payload;
      draft.fetchingUrlPreviews = false;
      draft.fetchedUrlPreviewsSuccess = false;
      draft.fetchedUrlPreviewsFail = false;
    }),
  )
  .handleAction(actions.getTiktokCreatorInfoAction, (state, action) =>
    produce(state, draft => {
      draft.fetchingTTCreatorInfoAssets = [...state.fetchingTTCreatorInfoAssets, action.payload];
      draft.fetchedTTCreatorInfoFail = false;
      draft.TTCreatorInfoErrorData = undefined;
    }),
  )
  .handleAction(actions.getTiktokCreatorInfoSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.ttCreatorInfo = [...state.ttCreatorInfo, action.payload];
      draft.fetchingTTCreatorInfoAssets = state.fetchingTTCreatorInfoAssets.filter(
        assetId => assetId !== action.payload.asset_id,
      );
      draft.fetchedTTCreatorInfoSuccess = [
        ...state.fetchedTTCreatorInfoSuccess,
        action.payload.asset_id || "",
      ];
      draft.fetchedTTCreatorInfoFail = false;
      draft.TTCreatorInfoErrorData = undefined;
    }),
  )
  .handleAction(actions.getTiktokCreatorInfoFailureAction, (state, action) =>
    produce(state, draft => {
      draft.fetchingTTCreatorInfoAssets = state.fetchingTTCreatorInfoAssets.filter(
        assetId => assetId !== action.payload.assetId,
      );
      draft.fetchedTTCreatorInfoFail = true;
      draft.TTCreatorInfoErrorData = action.payload;
    }),
  )
  .handleAction(actions.getInstagramAssetInfoAction, state =>
    produce(state, draft => {
      draft.fetchingInstagramAssetInfo = true;
      draft.fetchedInstagramAssetInfoSuccess = false;
      draft.fetchedInstagramAssetInfoFail = false;
      draft.instagramAssetInfo = null;
    }),
  )
  .handleAction(actions.getInstagramAssetInfoSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.fetchingInstagramAssetInfo = false;
      draft.fetchedInstagramAssetInfoSuccess = true;
      draft.fetchedInstagramAssetInfoFail = false;
      draft.instagramAssetInfo = action.payload.data;
      draft.fetchingInstagramProducts = true;
    }),
  )
  .handleAction(actions.getInstagramAssetInfoFailureAction, (state, action) =>
    produce(state, draft => {
      draft.fetchingInstagramAssetInfo = false;
      draft.fetchedInstagramAssetInfoSuccess = false;
      draft.fetchedInstagramAssetInfoFail = action.payload || false;
      draft.instagramAssetInfo = null;
    }),
  )
  .handleAction(actions.getInstagramProductsAction, state =>
    produce(state, draft => {
      draft.fetchingInstagramProducts = true;
      draft.fetchedInstagramProductsSuccess = false;
      draft.fetchedInstagramProductsFail = false;
      draft.instagramProducts = [];
    }),
  )
  .handleAction(actions.getInstagramProductsSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.fetchingInstagramProducts = false;
      draft.fetchedInstagramProductsSuccess = true;
      draft.fetchedInstagramProductsFail = false;
      draft.instagramProducts = action.payload;
    }),
  )
  .handleAction(actions.getInstagramProductsFailureAction, (state, action) =>
    produce(state, draft => {
      draft.fetchingInstagramProducts = false;
      draft.fetchedInstagramProductsSuccess = false;
      draft.fetchedInstagramProductsFail = action.payload || false;
      draft.instagramProducts = [];
    }),
  )
  .handleAction(actions.getYouTubeCategoriesAction, state =>
    produce(state, draft => {
      draft.fetchingYouTubeCategories = true;
      draft.fetchedYouTubeCategoriesSuccess = false;
      draft.fetchedYouTubeCategoriesFail = false;
    }),
  )
  .handleAction(actions.getYouTubeCategoriesSuccessAction, (state, action) =>
    produce(state, draft => {
      const { assetId, categories } = action.payload;
      draft.fetchingYouTubeCategories = false;
      draft.fetchedYouTubeCategoriesSuccess = true;
      draft.fetchedYouTubeCategoriesFail = false;
      draft.youtubeCategories[assetId] = categories;
    }),
  )
  .handleAction(actions.getYouTubeCategoriesFailureAction, state =>
    produce(state, draft => {
      draft.fetchingYouTubeCategories = false;
      draft.fetchedYouTubeCategoriesSuccess = false;
      draft.fetchedYouTubeCategoriesFail = true;
    }),
  )
  .handleAction(actions.getYouTubePlaylistsAction, state =>
    produce(state, draft => {
      draft.fetchingYouTubePlaylists = true;
      draft.fetchedYouTubePlaylistsSuccess = false;
      draft.fetchedYouTubePlaylistsFail = false;
    }),
  )
  .handleAction(actions.getYouTubePlaylistsActionSuccess, (state, action) =>
    produce(state, draft => {
      const { playlists, assetId } = action.payload;
      draft.fetchingYouTubePlaylists = false;
      draft.fetchedYouTubePlaylistsSuccess = true;
      draft.youtubePlaylists[assetId] = playlists;
    }),
  )
  .handleAction(actions.getYouTubePlaylistsActionFailure, state =>
    produce(state, draft => {
      draft.fetchingYouTubePlaylists = false;
      draft.fetchedYouTubePlaylistsSuccess = false;
      draft.fetchedYouTubePlaylistsFail = true;
    }),
  );

import { useTranslation } from "react-i18next";
import React, { FC } from "react";

import { Typography } from "antd";
import { Modal } from "@bbdevcrew/bb_ui_kit_fe";

import { IConfirmFilterOverrideModalProps } from "./types";

export const ConfirmFilterOverrideModal: FC<IConfirmFilterOverrideModalProps> = ({
  filterName,
  ...restProps
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t("components:savedMonitorsPanel:modals:confirmFilterOverride:title")}
      open
      centered
      {...restProps}
    >
      <Typography.Paragraph>
        {t("components:savedMonitorsPanel:modals:confirmFilterOverride:text", {
          filterName: filterName,
        })}
      </Typography.Paragraph>
    </Modal>
  );
};

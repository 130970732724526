import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import React, { FC, useState, useEffect, HTMLAttributes } from "react";
import classNames from "classnames";

import { Card } from "@bbdevcrew/bb_ui_kit_fe";
import PieChartWidget from "../../_common/pieChartWidget/PieChartWidget";

import s from "./../sentimentPieChart/SentimentPieChart.module.less";

import { minScreen1840 } from "@utils/screens";
import { IFilters } from "@store/filters/types";
import { IPieChartUnit } from "../../search/Search.type";
import { normalizePlatformData } from "../../search/helpers";
import { IOverviewPerformanceItem } from "@store/dashboard/types";

interface IPlatformsPieChartProps extends HTMLAttributes<HTMLDivElement> {
  loading: boolean;
  filterData: IFilters;
  data: IOverviewPerformanceItem[];
}

const PlatformsPieChart: FC<IPlatformsPieChartProps> = ({
  filterData,
  data,
  loading,
  className,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [commentPlatformsData, setCommentPlatformsData] = useState<IPieChartUnit[]>([]);
  const [total, setTotal] = useState<IPieChartUnit>({} as IPieChartUnit);

  useEffect(() => {
    const normalizeData = () => {
      const normalizedPlatformData = normalizePlatformData(data);

      const pieChartTotal = normalizedPlatformData.find(
        (unit: IPieChartUnit) => unit.rawName === "total",
      ) as IPieChartUnit;
      const pieChartData = normalizedPlatformData.filter(
        (unit: IPieChartUnit) => unit.rawName !== "total",
      ) as IPieChartUnit[];

      setTotal(pieChartTotal);
      setCommentPlatformsData(pieChartData);
    };

    normalizeData();
  }, [data]);

  const onChartClick = (event: { payload: { rawName: string } }) => {
    const filters = {
      ...filterData,
      platform_types: [event.payload.rawName],
    };

    navigate("/search", {
      state: { filters },
    });
  };

  return (
    <div className={classNames(s.bbPlatformsPieChartWrapper, className)}>
      <Card
        headerTitle={t("components:platformsPieChart:headerTitle")}
        headerSubTitle={t("components:platformsPieChart:headerSubTitle")}
      >
        <div className={s.bbPlatformsPieChart}>
          <PieChartWidget
            loading={loading}
            position="center"
            radiuses={[minScreen1840 ? 65 : 50, minScreen1840 ? 90 : 70]}
            sizes={[minScreen1840 ? 200 : 140, minScreen1840 ? 200 : 140]}
            defaultValue={total}
            onChartClick={onChartClick}
            chartData={commentPlatformsData.filter(unit => unit.value !== 0)}
          />
        </div>
      </Card>
    </div>
  );
};

export default PlatformsPieChart;

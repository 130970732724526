import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import React, { FC } from "react";

import { RatingReviewStars } from "./RatingReviewStars";
import { SelectStyledMulti } from "@bbdevcrew/bb_ui_kit_fe";
import ClearableTrigger from "@components/_common/SelectTrigger/ClearableTrigger";

import s from "./RatingReview.module.less";

import { handleMultiselectState } from "@bbdevcrew/bb_ui_kit_fe";
import { IRatingReviewFiltersProps } from "./RatingReview.types";
import { IClearableTriggerProps } from "@components/_common/SelectTrigger/ClearableTrigger.types";

import { ThumbsUpIcon } from "@assets/index";

const cx = cn.bind(s);

const RECOMMEND_OPTIONS = [
  { id: "POSITIVE", iconClass: s.bbThumbIcon },
  {
    id: "NEGATIVE",
    iconClass: cx(s.bbThumbIcon, s.bbNoRecommendIcon),
  },
];

const checkIfFacebookReviewOption = (id: string) => id === "NEGATIVE" || id === "POSITIVE";

export const RatingReview: FC<IRatingReviewFiltersProps> = ({
  customprops,
  options = [],
  value = [],
  onChange,
}) => {
  const { t } = useTranslation();

  const onInternalChange = (ratingValue: string) => {
    const newState = handleMultiselectState(options, value, ratingValue);

    if (customprops?.form) {
      onChange?.(newState || undefined);
    }
  };

  const generateOptions = () => {
    return options.map(option => {
      const isFacebookOption = checkIfFacebookReviewOption(option.id);
      return {
        id: option.id,
        label: isFacebookOption ? option.label : "",
        leftLabel: (
          <div className={s.bbOptionLabel}>
            {isFacebookOption ? (
              <div className={RECOMMEND_OPTIONS.find(item => item.id === option.id)?.iconClass}>
                <ThumbsUpIcon />
              </div>
            ) : (
              <RatingReviewStars rating={parseInt(option.label)} />
            )}
          </div>
        ),
      };
    });
  };

  const _onClear = () => {
    onChange?.([]);
  };

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    if (!value?.length) {
      return ClearableTrigger(tooltipProps);
    }
    const tooltipElement = value.map(val => {
      const isFacebookReviewOption = checkIfFacebookReviewOption(val);
      const label = generateOptions().find(option => option.id === val)?.[
        isFacebookReviewOption ? "label" : "leftLabel"
      ];
      return (
        <span key={val} className={s.bbReviewRatingTooltip}>
          {label}
        </span>
      );
    });

    return ClearableTrigger({
      tooltip: tooltipElement,
      onClear: _onClear,
      ...tooltipProps,
    });
  };

  return (
    <SelectStyledMulti
      _size="sm"
      id="review_types"
      className={s.bbRatingReviewItem}
      value={value}
      options={generateOptions()}
      TriggerVariation={TriggerWithTooltip}
      onChange={onInternalChange}
      placeholder={t("generic:all")}
      excludePlaceholder={t("generic:exclude")}
      excludedPlaceholder={t("generic:excluded")}
      dropdownPlacement="bottomRight"
    />
  );
};

import cn from "classnames/bind";
import React from "react";

import { CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ReplyImage.module.less";

import { IReplyImageProps } from "./ReplyImage.type";

const cx = cn.bind(s);

export const ReplyImage = ({
  isUploadingReplyImage,
  replyImageURL,
  clearReplyImage,
}: IReplyImageProps) => {
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);

  return (
    <div
      className={cx(s.bbReplyImageWrapper, {
        [s.bbReplyImageWrapperWithLoading]: isUploadingReplyImage,
      })}
    >
      <img src={replyImageURL} className={s.bbReplyImage} onLoad={() => setIsImageLoaded(true)} />
      {isImageLoaded && (
        <span className={s.bbRemoveReplyImage} onClick={clearReplyImage}>
          <CloseIcon />
        </span>
      )}
    </div>
  );
};

import { createAction, createCustomAction } from "typesafe-actions";
import {
  GET_AUTOCOMPLETE_OPTIONS,
  GET_AUTOCOMPLETE_OPTIONS_SUCCESS,
  GET_AUTOCOMPLETE_OPTIONS_FAILURE,
  CLEAR_AUTOCOMPLETE_OPTIONS,
  UPDATE_AUTOCOMPLETE_OPTIONS,
} from "./actionTypes";
import { IAutocompleteOption } from "./types";
import { AllowedAutocompleteFieldType, IGetAutocompleteOptionsPayload } from "./types";

export const getAutocompleteOptionsAction =
  createAction(GET_AUTOCOMPLETE_OPTIONS)<IGetAutocompleteOptionsPayload>();
export const getAutocompleteOptionsSearchAction =
  createAction(GET_AUTOCOMPLETE_OPTIONS)<IGetAutocompleteOptionsPayload>();

export const updateAutocompleteOptionsAction = createAction(
  UPDATE_AUTOCOMPLETE_OPTIONS,
)<IGetAutocompleteOptionsPayload>();

export const getAutocompleteOptionsSuccessAction = createCustomAction(
  GET_AUTOCOMPLETE_OPTIONS_SUCCESS,
  (data: IAutocompleteOption[], key: AllowedAutocompleteFieldType) => ({
    payload: { data, key },
  }),
);

export const getAutocompleteOptionsFailureAction = createAction(GET_AUTOCOMPLETE_OPTIONS_FAILURE)();

export const clearAutocompleteOptionsAction = createAction(
  CLEAR_AUTOCOMPLETE_OPTIONS,
)<AllowedAutocompleteFieldType>();

import { createReducer, ActionType } from "typesafe-actions";
import produce from "immer";

import * as actions from "./actions";

import { IManagedState } from "./types";

const initialState: IManagedState = {
  protectComments: [],
  protectCommentsLoading: false,
  protectCommentsError: false,

  engagedComments: [],
  engagedCommentsLoading: false,
  engagedCommentsError: false,
};

export const managedReducer = createReducer<IManagedState, ActionType<typeof actions>>(initialState)
  .handleAction(actions.proctectCommentsAction, state =>
    produce(state, draft => {
      draft.protectCommentsLoading = true;
      draft.protectCommentsError = false;
    }),
  )
  .handleAction(actions.proctectCommentsSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.protectComments = action.payload;
      draft.protectCommentsLoading = false;
      draft.protectCommentsError = false;
    }),
  )
  .handleAction(actions.proctectCommentsFailureAction, state =>
    produce(state, draft => {
      draft.protectComments = [];
      draft.protectCommentsLoading = false;
      draft.protectCommentsError = true;
    }),
  )
  .handleAction(actions.engagedCommentsAction, state =>
    produce(state, draft => {
      draft.engagedCommentsLoading = false;
      draft.engagedCommentsError = false;
    }),
  )
  .handleAction(actions.engagedCommentsSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.engagedComments = action.payload;
      draft.engagedCommentsLoading = false;
      draft.engagedCommentsError = false;
    }),
  )
  .handleAction(actions.engagedCommentsFailureAction, state =>
    produce(state, draft => {
      draft.engagedComments = [];
      draft.engagedCommentsLoading = false;
      draft.engagedCommentsError = true;
    }),
  );

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useEffect, useMemo, useState } from "react";

import HiddenCommentIcon from "./HiddenCommentIcon";
import CommentThreadCount from "./CommentThreadCount";
import CommentActionError from "./CommentActionError";
import { Tooltip, Checkbox } from "@bbdevcrew/bb_ui_kit_fe";
import CommentPhrase from "../../CommentPhrase/CommentPhrase";
import AssignUser from "@containers/AssignUser/AssignUser";

import { meSelector } from "@store/me/selectors";
import { assignCommentUserAction } from "@store/comments/actions";

import s from "./CommentCardHeader.module.less";

import { extractDate } from "@utils/dates";
import { tooltipDelay, formatTime } from "./helpers";
import { getSubFeatureName } from "@utils/feature";
import { ICommentCardHeaderProps } from "./CommentCardHeader.type";
import { useBatchMode } from "@containers/BatchMode/BatchMode";
import { getPlatformIconBasicColor, platformIconName } from "@utils/platform";
import { PostPlacementEnum } from "@components/_common/Social/Post/Post.type";

import { SendIcon, EmailIcon, FacebookMessengerColorIcon } from "@assets/index";

const Header: FC<ICommentCardHeaderProps> = ({ comment, onReplyClick }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const me = useSelector(meSelector);

  const [isSelectedCard, setIsSelectedCard] = useState<boolean | undefined>(false);

  const { isBatchModeActive, addSelectedComment, selectedComments } = useBatchMode();

  useEffect(() => {
    const selected = selectedComments?.some(selectedComment => selectedComment.id === comment.id);
    setIsSelectedCard(selected);
  }, [selectedComments, comment.id]);

  const onAssignUserChange = (value: string[]) => {
    dispatch(assignCommentUserAction(comment.id, { assigned_users: value }, me?.id));
  };

  const isCommentDm = useMemo(() => {
    return comment.post_placement === PostPlacementEnum.DM;
  }, [comment.post_placement]);

  const isCommentReview = useMemo(() => {
    return comment.post_placement === PostPlacementEnum.REVIEW;
  }, [comment.post_placement]);

  const dmCardOverlayIcon = () => {
    if (comment.post_placement !== PostPlacementEnum.DM) {
      return null;
    }

    const platformName = platformIconName(comment?.platform.name || "") as
      | "facebook"
      | "instagram"
      | "twitter";

    const overlayIcon = {
      facebook: <FacebookMessengerColorIcon />,
      instagram: <SendIcon />,
      twitter: <EmailIcon />,
    };

    return <span className={s.bbOverlayIcon}>{overlayIcon[platformName]}</span>;
  };

  return (
    <div className={s.bbCommentCardHeader}>
      <Tooltip placement="top" mouseEnterDelay={tooltipDelay} title={comment.platform_type.label}>
        <span className={s.bbPlatform}>
          <span className={s.bbCommentsPlatform}>
            {getPlatformIconBasicColor(platformIconName(comment.platform_type.id.toLowerCase()))}
          </span>
          {dmCardOverlayIcon()}
          {comment?.post_placement === PostPlacementEnum.STORY && (
            <span className={s.bbStoryOverlayIcon}>
              <SendIcon />
            </span>
          )}
        </span>
      </Tooltip>

      <div className={s.bbHeaderInfoContainer}>
        <Tooltip placement="top" mouseEnterDelay={tooltipDelay} title={comment.tooltips?.username}>
          <div className={s.bbCommentsUsername}>{comment.username}</div>
        </Tooltip>

        <Tooltip
          placement="topLeft"
          mouseEnterDelay={tooltipDelay}
          overlayInnerStyle={{ width: 480 }}
          title={t("components:comments:tooltips:creationDate", {
            interpolation: { escapeValue: false },
            tz: me?.timezone || "GMT",
          })}
        >
          <div className={s.bbCommentDateWrapper}>
            <span className={s.bbCommentsDate}>{extractDate(comment.created_time_pretty)}</span>
            <span>{t("generic:at")}</span> <span>{formatTime(comment.created_time_pretty)}</span>
          </div>
        </Tooltip>

        <div className={s.bbCommentSourceInfo}>
          <CommentPhrase
            post={comment.post}
            commentPhrase={comment.comment_phrase || ""}
            recommendationType={comment.recommendation_type}
            showPostViewTooltip={!!comment.comment_phrase && !isCommentDm && !isCommentReview}
            editedTooltip={comment.tooltips?.edit}
          />
        </div>
      </div>

      {isBatchModeActive ? (
        <Tooltip
          placement="top"
          mouseEnterDelay={tooltipDelay}
          title={t(
            isSelectedCard
              ? "containers:batchMode:card:unselect"
              : "containers:batchMode:card:select",
          )}
        >
          <span>
            <Checkbox
              id={comment.id}
              checked={isSelectedCard}
              onClick={() => addSelectedComment(comment)}
            />
          </span>
        </Tooltip>
      ) : (
        <div className={s.bbCommentCardHeaderRightSide}>
          <CommentActionError actionError={comment.action_error} />
          <HiddenCommentIcon comment={comment} />
          {getSubFeatureName() !== "dashboard" && (
            <AssignUser onChange={onAssignUserChange} value={comment.assigned_users} />
          )}
          <CommentThreadCount comment={comment} onReplyClick={onReplyClick} />
        </div>
      )}
    </div>
  );
};

export default Header;

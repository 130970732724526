import React from "react";
import { useTranslation } from "react-i18next";

import { Row, Col } from "antd";
import SectionDivider from "../OverviewSectionDivider";
import { ReviewsWidgetVolume } from "./ReviewsWidgetVolume";
import { ReviewsDistribution } from "./ReviewsDistribution";
import { PlatformComparison } from "./PlatformComparison";

import { IReviewsWidgetProps } from "./ReviewsWidget.types";
import { sortedRatingDistribution } from "./ReviewWidget.helpers";

import { StarIcon } from "@assets/index";

export const ReviewsWidget = ({ data, loading, hideHeader }: IReviewsWidgetProps) => {
  const { t } = useTranslation();

  return (
    <>
      {!hideHeader && (
        <Col xs={24}>
          <SectionDivider
            icon={<StarIcon />}
            title={t("components:reviewWidget:title")}
            description={t("components:reviewWidget:description")}
          />
        </Col>
      )}

      <Col xs={24} id="review-rating-section">
        <Row gutter={[28, 26]}>
          <ReviewsWidgetVolume data={data.volume} loading={loading} />
          <ReviewsDistribution
            data={sortedRatingDistribution(data.ratingDistribution || [])}
            loading={loading}
          />
          <PlatformComparison data={data.platformComparison} loading={loading} />
        </Row>
      </Col>
    </>
  );
};

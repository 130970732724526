import { createContext, useContext } from "react";
import { IProductTag, IUserTag, AnyTagType, ITagsContext } from "./TagsModal.types";

export const MAX_TAGS_PER_POST = 20;

export const TagsContext = createContext<ITagsContext>({} as ITagsContext);

export const useTagsContext = () => useContext<ITagsContext>(TagsContext);

export const isProductTag = (mediaTag: AnyTagType) => "product_name" in mediaTag;
export const isUserTag = (mediaTag: AnyTagType) => "username" in mediaTag;

export const findUserTag = (tag: IUserTag) => (localTag: AnyTagType) =>
  isUserTag(localTag) &&
  (localTag as IUserTag).username.trim().toLowerCase() === tag.username.trim().toLowerCase();

export const findProductTag = (tag: IProductTag) => (localTag: AnyTagType) =>
  isProductTag(localTag) && (localTag as IProductTag).product_id === tag.product_id;

export const collaboratorsFilter = (tag: AnyTagType) =>
  isProductTag(tag) || (isUserTag(tag) && !(tag as IUserTag).collaborator);

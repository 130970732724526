import {
  GET_MODULE_SECTIONS_LIST,
  GET_MODULE_SECTIONS_LIST_SUCCESS,
  GET_MODULE_SECTIONS_LIST_FAILURE,
} from "./actionTypes";
import { createAction } from "typesafe-actions";
import { Section } from "./types";

export const getModuleSectionsListAction = createAction(
  GET_MODULE_SECTIONS_LIST,
  (module_id: string) => ({
    id: module_id,
  }),
)();
export const getModuleSectionsListSuccessAction = createAction(
  GET_MODULE_SECTIONS_LIST_SUCCESS,
  (items: Section[], module_id: string) => ({
    items,
    id: module_id,
  }),
)();
export const getModuleSectionsListFailureAction = createAction(
  GET_MODULE_SECTIONS_LIST_FAILURE,
  (module_id: string) => ({
    id: module_id,
  }),
)();

import React, { ChangeEvent, useRef } from "react";

import { Button } from "antd";

import s from "./UploadImageButton.module.less";

import { IUploadImageButtonProps } from "./UploadImageButton.type";

import { PictureIcon } from "@assets/index";
import { FILE_SIZE_LIMIT } from "./helpers";

const UploadImageButton = ({
  fileSizeLimit = FILE_SIZE_LIMIT,
  onSelectFile,
  onFileSizeLimitError,
}: IUploadImageButtonProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onClick = () => inputRef.current?.click();
  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const image = event.target.files?.[0] as File;

    if (image.size > fileSizeLimit) return onFileSizeLimitError();

    onSelectFile(image);

    // clear input value to allow uploading the same image and trigger onChangeFile again
    event.target.value = "";
  };

  return (
    <div className={s.bbUploadImageWrapper}>
      <Button id="upload-image-btn" className={s.bbUploadImageButton} onClick={onClick}>
        <PictureIcon />
      </Button>

      <input
        hidden
        ref={inputRef}
        type="file"
        accept="image/png, image/jpeg"
        onChange={onChangeFile}
      />
    </div>
  );
};

export default UploadImageButton;

import moment from "moment";

import { i18nextInstance } from "../../../../App";
import { DATE_FORMAT, TIME_FORMAT } from "./PrivateMessageCard.type";

export const getStoryPreviewMessage = (type: string, owned: boolean) => {
  return i18nextInstance.t(`components:storyPreview:${type}_${owned ? "owned" : "not_owned"}`);
};

export const getReplyDate = (date: string) => {
  return `${moment(date).format(DATE_FORMAT)} ${i18nextInstance.t("generic:at")} ${moment(
    date,
  ).format(TIME_FORMAT)}`;
};

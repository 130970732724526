import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useCallback, FC, ChangeEvent } from "react";
import { Grid } from "antd";

import Ads from "@components/_common/Social/Ads/Ads";
import ReplyModal from "@containers/ReplyModal/ReplyModal";
import ReplyModalContextProvider from "../ReplyModal/ReplyModalContext";
import Parent from "@components/_common/CommentList/parent/Parent";
import PostModal from "@components/_common/Social/Post/PostModal";
import { Icon, Modal, InputText, Loading } from "@bbdevcrew/bb_ui_kit_fe";
import CommentsGrid from "@components/_common/CommentsGrid/CommentsGrid";
import CommentActions from "@components/_common/CommentCard/commentActions/CommentActions";
import AppFiltersContainer from "@containers/AppFiltersContainer";

import {
  batchActionsPerformedSelector,
  triggerNextBatchSelector,
  performingBatchActionsPendingSelector,
} from "@store/comments/selectors";
import {
  postSelector,
  postAdsSelector,
  getPostProcessingSelector,
  getPostAdsProcessingSelector,
} from "@store/posts/selectors";
import { meSelector } from "@store/me/selectors";

import {
  clearPostAction,
  clearPostAdsAction,
  getPostAction,
  getPostAdsAction,
} from "@store/posts/actions";
import { updateCommentSentimentAction } from "@store/comments/actions";
import { clearRepliesAction, getRepliesAction } from "@store/replies/actions";
import { postCommentsAction, translateCommentAction } from "@store/comments/actions";

import { ICommentsGridContainerProps } from "./types";
import { useBatchMode } from "../BatchMode/BatchMode";
import useCommentActions from "@utils/useCommentActions";
import { IAd } from "@components/_common/Social/Ads/Ads.type";
import { SearchIcon, addToast, debounce } from "@bbdevcrew/bb_ui_kit_fe";
import { getCommentsSelector, REPLY_MODAL_COMMENTS_FETCH_LIMIT } from "./helpers";
import { IComment, IPostCommentsSearchBody, ICommentLists } from "@store/comments/types";

import s from "./Comments.module.less";

export const CommentsGridContainer: FC<ICommentsGridContainerProps> = ({
  filters,
  sortValue,
  showLikeAction,
  showLinkedInReactionAction,
  showHideAction,
  showDeleteAction,
  showReviewAction,
  showFlagAction,
  showStarAction,
  replyPlatformType,
  showArchiveAction,
  showDetailsAction,
  withPostPreview,
  EmptyState,
  providedFetchAction,
  providedFilterAuthorIdAction,
  view = "all_comments",
  loadMoreOnScroll = true,
  data: propComments,
  isLoading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const screens = Grid.useBreakpoint();
  const isMobileView = !screens.md;

  const listenSection = searchParams.get("section") as keyof ICommentLists;
  const conversationListId = (
    listenSection === "listen-conversation_list" ? listenSection : undefined
  ) as keyof ICommentLists;
  const { isBatchModeActive, toggleBatchMode } = useBatchMode();

  // Post States
  const [isOpenedPost, setOpenPost] = useState(false);
  const [openParent, setOpenParent] = useState(false);
  const [parentCommentId, setParentCommentId] = useState("");

  // Ads States
  const [isOpenedAds, setOpenAds] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchedAds, setSearchedAds] = useState<IAd[]>([]);

  // Reply States
  const [isReplyModalOpen, setIsReplyModalOpen] = useState(false);

  // Selectors
  const me = useSelector(meSelector);

  // Comments
  const commentsSelectors = getCommentsSelector(view, conversationListId);
  const comments = useSelector(commentsSelectors.data);
  const commentsLoading = useSelector(commentsSelectors.loading);
  const commentsPageCursor = useSelector(commentsSelectors.cursor);
  const commentsNavigationId = useSelector(commentsSelectors.navigationId);
  const triggerNextBatch = useSelector(triggerNextBatchSelector);

  // Post
  const isPostLoading = useSelector(getPostProcessingSelector);
  const postData = useSelector(postSelector);
  const arePostAdsLoading = useSelector(getPostAdsProcessingSelector);
  const postAds = useSelector(postAdsSelector);

  // Batch mode
  const performedBatchActions = useSelector(batchActionsPerformedSelector);
  const performingBatchActions = useSelector(performingBatchActionsPendingSelector);

  const {
    archiveComment,
    starComment,
    flagComment,
    reviewComment,
    hideComment,
    deleteComment,
    likeComment,
    likeLinkedInComment,
    dismissCommentError,
    isHideActionAvailable,
    isLikeActionAvailable,
    isReplyActionAvailable,
    isDetailsActionAvailable,
    isLinkedInReactionActionAvailable,
    isDeleteActionAvailable,
  } = useCommentActions(filters || {});

  // Actions
  // eslint-disable-next-line
  const fetchComments = useCallback(
    debounce((body: IPostCommentsSearchBody) => {
      if (!commentsLoading) {
        const replyModalPayloadBody = {
          ...body,
          filters,
          isReplyModal: true,
          conversation_list_id: conversationListId,
          limit: REPLY_MODAL_COMMENTS_FETCH_LIMIT,
        };

        const defaultCommentsPayloadBody = {
          ...body,
          conversation_list_id: conversationListId,
        };

        const payload =
          view === "comment_details" ? replyModalPayloadBody : defaultCommentsPayloadBody;
        const action = providedFetchAction || postCommentsAction;

        dispatch(action(payload));
      }
    }, 300),
    [dispatch, commentsLoading, filters, providedFetchAction],
  );

  const translate = useCallback(
    (id: string, isParent: boolean) => dispatch(translateCommentAction(id, isParent)),
    [dispatch],
  );

  // Post
  const fetchPost = useCallback(id => dispatch(getPostAction(id)), [dispatch]);
  const clearPost = useCallback(() => dispatch(clearPostAction()), [dispatch]);

  // Ads
  const fetchAds = useCallback(id => dispatch(getPostAdsAction(id)), [dispatch]);
  const clearAds = useCallback(() => dispatch(clearPostAdsAction()), [dispatch]);

  // Replies
  const fetchReplies = useCallback(
    commentId => dispatch(getRepliesAction(commentId, commentsNavigationId)),
    [dispatch, commentsNavigationId],
  );
  const clearReplies = useCallback(() => dispatch(clearRepliesAction()), [dispatch]);

  const onChangeCommentSentiment = useCallback(
    (commentId, sentiment) => {
      if (commentId) {
        dispatch(updateCommentSentimentAction(commentId, { sentiment }));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (propComments) return;

    fetchComments({
      filters,
      sort: sortValue,
      reply_platform_type: replyPlatformType,
    });
    // eslint-disable-next-line
  }, [filters, sortValue, replyPlatformType, isBatchModeActive, providedFetchAction]);

  useEffect(() => {
    if (isBatchModeActive && !performingBatchActions && performedBatchActions) {
      if (commentsPageCursor && commentsNavigationId) {
        fetchComments({
          filters,
          sort: sortValue,
          cursor: commentsPageCursor,
          reply_platform_type: replyPlatformType,
          navigation_id: commentsNavigationId,
          replacePrevPage: true,
        });
      } else if (!commentsPageCursor || !commentsNavigationId) {
        toggleBatchMode();

        addToast({
          type: "success_accent",
          title: t("containers:batchMode:notifications:allDone"),
        });
      }
    }
    // eslint-disable-next-line
  }, [performingBatchActions, performedBatchActions]);

  const onClickOpenPost = (postId: string) => {
    fetchPost(postId);
    setOpenPost(true);
  };

  const onClickOpenAds = (postId: string) => {
    fetchAds(postId);
    setOpenAds(true);
  };

  const onClosePost = () => {
    clearPost();
    setOpenPost(false);
    return true;
  };

  const onCloseAds = () => {
    clearAds();
    setOpenAds(false);
  };

  const onClickParent = (id: string) => {
    setParentCommentId(id);
    setOpenParent(true);
  };

  const onClickReply = (postId: string) => {
    fetchReplies(postId);
    setIsReplyModalOpen(true);
    localStorage.removeItem("SelectedReplyModalState");
  };

  const onReplyModalClose = () => {
    setIsReplyModalOpen(false);
    clearReplies();
  };

  const loadNextCommentsBatch = useCallback(() => {
    if (!!commentsPageCursor) {
      fetchComments({
        filters,
        sort: sortValue,
        cursor: commentsPageCursor,
        reply_platform_type: replyPlatformType,
        navigation_id: commentsNavigationId,
      });
    }
  }, [
    fetchComments,
    filters,
    sortValue,
    commentsPageCursor,
    replyPlatformType,
    commentsNavigationId,
  ]);

  useEffect(() => {
    if (triggerNextBatch) {
      loadNextCommentsBatch();
    }
    // eslint-disable-next-line
  }, [triggerNextBatch]);

  const search = (value: string) => {
    if (postAds?.length && (value.length >= 2 || value.length === 0)) {
      const data = postAds.filter(o =>
        (Object.keys(o) as Array<keyof IAd>).some(k =>
          String(o[k]).toLowerCase().includes(value.toLowerCase()),
        ),
      );
      setSearchValue(value);
      setSearchedAds(value.length >= 2 ? data : postAds);
    }
  };

  return (
    <>
      {isMobileView && <AppFiltersContainer />}
      <div
        data-cy="comments-grid-wrapper"
        className={classNames({
          [s.bbCommentsGridWrapperBatchMode]: isBatchModeActive,
        })}
      >
        <Loading isLoading={isLoading || commentsLoading || performingBatchActions} type="bb">
          <CommentsGrid
            comments={propComments || comments || []}
            EmptyState={EmptyState}
            onReplyClick={onClickReply}
            onTranslateClick={translate}
            withPostPreview={withPostPreview}
            commentsPageCursor={commentsPageCursor}
            loading={isLoading || commentsLoading || performingBatchActions}
            onBottomReached={loadMoreOnScroll ? loadNextCommentsBatch : undefined}
            className={classNames({
              [s.bbCommentsGridContainerDetails]: view === "comment_details",
            })}
            withFooter={(comment: IComment) => (
              <CommentActions
                comment={comment}
                onStarClick={starComment}
                onFlagClick={flagComment}
                onHideClick={hideComment}
                onDeleteClick={deleteComment}
                onLikeClick={likeComment}
                onLinkedInReactionClick={likeLinkedInComment}
                onReplyClick={onClickReply}
                onReviewClick={reviewComment}
                onParentClick={onClickParent}
                onOpenAdsClick={onClickOpenAds}
                onArchiveClick={archiveComment}
                onOpenPostClick={onClickOpenPost}
                onDismissErrorClick={dismissCommentError}
                updateCommentSentiment={onChangeCommentSentiment}
                showFlagAction={showFlagAction}
                showStarAction={showStarAction}
                showReviewAction={showReviewAction}
                showArchiveAction={showArchiveAction}
                showCommentLink={!me?.is_demo_mode_active}
                showLikeAction={showLikeAction && isLikeActionAvailable(comment)}
                showDetailsAction={showDetailsAction && isDetailsActionAvailable(comment)}
                showReplyAction={me?.responding_enabled && isReplyActionAvailable(comment)}
                showLinkedInReactionAction={
                  showLinkedInReactionAction && isLinkedInReactionActionAvailable(comment)
                }
                showHideAction={showHideAction && isHideActionAvailable(comment)}
                showDeleteAction={showDeleteAction && isDeleteActionAvailable(comment)}
                filterAuthorsId={
                  providedFilterAuthorIdAction
                    ? (author_id: string) => providedFilterAuthorIdAction([author_id])
                    : undefined
                }
              />
            )}
          />
        </Loading>

        <PostModal
          isLoading={isPostLoading}
          showPostTags
          post={postData}
          isOpen={isOpenedPost}
          onClose={onClosePost}
        />

        <Modal
          centered
          hideFooter
          width={800}
          title="Ads"
          theme="light"
          destroyOnClose={true}
          open={isOpenedAds}
          onCancel={() => onCloseAds()}
          titleSufix={
            <InputText
              size="large"
              placeholder="Search..."
              style={{ maxWidth: "85%" }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => search(e.target.value)}
              prefix={<Icon _size="sm" icon={SearchIcon} />}
            />
          }
        >
          <Ads
            isLoading={arePostAdsLoading}
            ads={searchValue.length > 2 ? searchedAds : postAds}
            texts={{
              adLabel: t("components:comments:ads:table:adLabel"),
              adSetLabel: t("components:comments:ads:table:adSetLabel"),
              campaignLabel: t("components:comments:ads:table:campaignLabel"),
              spinText: t("pages:loading:spinText"),
            }}
          />
        </Modal>

        {!!parentCommentId && (
          <Modal
            centered
            hideFooter
            open={openParent}
            destroyOnClose={true}
            onCancel={() => setOpenParent(false)}
          >
            <Parent
              showHiddenIcon
              commentIndex={comments?.findIndex(({ id }) => id === parentCommentId)}
              functions={{ onClickTranslate: translate }}
              child={comments?.find(({ id }) => id === parentCommentId) as IComment}
              comment={comments?.find(({ id }) => id === parentCommentId)?.parent as IComment}
            />
          </Modal>
        )}

        <ReplyModalContextProvider>
          <ReplyModal
            filters={filters}
            comments={propComments || comments || []}
            isOpen={isReplyModalOpen}
            onClose={onReplyModalClose}
            showLikeAction={showLikeAction}
            showLinkedInReactionAction={showLinkedInReactionAction}
            showHideAction={showHideAction}
            showDeleteAction={showDeleteAction}
            showReviewAction={showReviewAction}
            showFlagAction={showFlagAction}
            showStarAction={showStarAction}
            showArchiveAction={showArchiveAction}
            showDetailsAction={showDetailsAction}
            providedFetchAction={providedFetchAction}
          />
        </ReplyModalContextProvider>
      </div>
    </>
  );
};

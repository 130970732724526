import { of } from "rxjs";
import { switchMap, mergeMap, map, filter, catchError, delay } from "rxjs/operators";
import { Observable } from "rxjs";
import { AjaxError, ajax } from "rxjs/ajax";
import { isActionOf } from "typesafe-actions";
import { StateObservable } from "redux-observable";

import * as actions from "./actions";
import { RootState } from "..";
import {
  PublishingActions,
  IEditPublishingPostResponse,
  ILinkMetaData,
  IPublishingListResponse,
  ITTCreatorInfo,
  IPlaylist,
} from "./types";

import {
  instagramAssetInfo,
  instagramProducts,
  publishings,
  youtubeCategories,
  youtubePlaylists,
} from "@utils/paths";

import { mockAssetId, mockAssetInfoResponse, mockProducts } from "./mockData";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";
import { IAPIError } from "@utils/getAPIErrorMessages";

export const getPosts = (
  action$: Observable<PublishingActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getPublishingsListAction)),
    switchMap(a => {
      return ajax
        .post<IPublishingListResponse>(
          `${publishings}/list`,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.getPublishingsListSuccessAction(data)),
          catchError(e => handleError(e, actions.getPublishingsListFailureAction)),
        );
    }),
  );

export const createPostPublishing = (
  action$: Observable<PublishingActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.createPostPublishingAction)),
    mergeMap(a => {
      return ajax
        .post(
          publishings,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => {
            return actions.createPostPublishingSuccessAction(
              !!a.payload.scheduled_at,
              !!a.payload.is_draft,
            );
          }),
          catchError(e => {
            return [actions.createPostPublishingFailureAction(e.response.message)];
          }),
        );
    }),
  );

export const editPostPublishing = (
  action$: Observable<PublishingActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.updatePostPublishingAction)),
    mergeMap(a => {
      const { id, ...payload } = a.payload;

      return ajax
        .put<IEditPublishingPostResponse>(
          `${publishings}/${id}`,
          payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() =>
            actions.updatePostPublishingSuccessAction(!!payload.scheduled_at, !!payload.is_draft),
          ),
          catchError(e => {
            return [actions.updatePostPublishingFailureAction(e.response.message)];
          }),
        );
    }),
  );

export const deletePublishingPost = (
  action$: Observable<PublishingActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.deletePostPublishingAction)),
    switchMap(a => {
      return ajax
        .delete(
          `${publishings}/${a.payload}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => actions.deletePostPublishingSuccessAction(a.payload)),
          catchError(e => {
            return [actions.deletePostPublishingFailureAction(e.response.message)];
          }),
        );
    }),
  );

export const fetchUrlPreviews = (
  action$: Observable<PublishingActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getUrlPreviewsAction)),
    switchMap(a => {
      return ajax
        .post<{ items: ILinkMetaData[] }>(
          `${publishings}/url-previews`,
          {
            urls: a.payload,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(({ items }) => actions.getUrlPreviewsSuccessAction(items)),
          catchError(e => handleError(e, actions.getUrlPreviewsFailureAction)),
        );
    }),
  );

export const getTiktokCreatorInfo = (
  action$: Observable<PublishingActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getTiktokCreatorInfoAction)),
    mergeMap(a => {
      return ajax
        .post<ITTCreatorInfo>(
          `${publishings}/tiktok/creator-info/${a.payload}`,
          {
            asset_id: a.payload,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.getTiktokCreatorInfoSuccessAction({ ...data, asset_id: a.payload })),
          catchError((e: AjaxError) => {
            if (e.response) {
              const response = e.response as IAPIError;

              if (response.statusCode === 400) {
                return [
                  actions.getTiktokCreatorInfoFailureAction({
                    assetId: a.payload,
                    errorMessage: response.message,
                  }),
                ];
              }
            }

            return handleError(e, actions.getTiktokCreatorInfoFailureAction);
          }),
        );
    }),
  );

export const getInstagramAssetInfo = (
  action$: Observable<PublishingActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getInstagramAssetInfoAction)),
    switchMap(a => {
      if (a.payload === mockAssetId) {
        return of(mockAssetInfoResponse).pipe(
          delay(500),
          map(data => actions.getInstagramAssetInfoSuccessAction(data, a.payload)),
        );
      } else {
        return ajax
          .get<{ shopping_product_tag_eligibility: boolean; id: string }>(
            instagramAssetInfo(a.payload),
            getHeaders({
              Authorization: state$.value.auth.session.accessToken.jwtToken,
            }),
          )
          .pipe(
            map(e => e.response),
            map(data => actions.getInstagramAssetInfoSuccessAction(data, a.payload)),
            catchError(e => of(actions.getInstagramAssetInfoFailureAction(e.response.message))),
          );
      }
    }),
  );

export const getInstagramProducts = (
  action$: Observable<PublishingActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getInstagramAssetInfoSuccessAction)),
    filter(action => action.payload.data.shopping_product_tag_eligibility),
    mergeMap(action => {
      if (action.payload.data.id === mockAssetId) {
        return of({ items: mockProducts }).pipe(
          delay(500),
          map(data => actions.getInstagramProductsSuccessAction(data.items)),
        );
      } else {
        return ajax
          .get<{ items: { product_id: string; product_name: string; image_url: string }[] }>(
            instagramProducts(action.payload.asset_id),
            getHeaders({
              Authorization: state$.value.auth.session.accessToken.jwtToken,
            }),
          )
          .pipe(
            map(e => e.response.items),
            map(products => actions.getInstagramProductsSuccessAction(products)),
            catchError(e => of(actions.getInstagramProductsFailureAction(e.response.message))),
          );
      }
    }),
  );

export const fetchYouTubeCategoriesEpic = (
  action$: Observable<PublishingActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getYouTubeCategoriesAction)),
    switchMap(action => {
      const { assetId, country } = action.payload;
      return ajax
        .get<{ items: { id: string; label: string }[] }>(
          youtubeCategories(assetId, country),
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response.items),
          map(categories => actions.getYouTubeCategoriesSuccessAction(assetId, categories)),
          catchError(error => of(actions.getYouTubeCategoriesFailureAction(error.message))),
        );
    }),
  );

export const fetchYouTubePlaylistsEpic = (
  action$: Observable<PublishingActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getYouTubePlaylistsAction)),
    switchMap(action => {
      return ajax
        .get<{ items: IPlaylist[] }>(
          youtubePlaylists(action.payload),
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response.items),
          map(playlists => actions.getYouTubePlaylistsActionSuccess(action.payload, playlists)),
          catchError(error => of(actions.getYouTubePlaylistsActionFailure(error.message))),
        );
    }),
  );
